import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { set, debounce } from "lodash";
import { Row, Col } from "react-bootstrap";

import request from "services/request";
import CustomTable from "components/customTable";
import DefaultModal from "views/commonViews/DefaultModal";
import useCurrencySymbol from "customHooks/useCurrencySymbol";
import { useUserData } from "contexts/AuthContextManagement";
import { useLoading } from "contexts/LoadingContextManagement";
import { cloneDeep, customToFixed, getExpiryTextColor } from "common/utils.ts";
import {
  DEFAULT_ERROR_MESSAGE,
  ITEMS_PER_PAGE,
  DEBOUNCE_DELAY,
  EXPIRY_OPTIONS,
} from "common/constants";
import SortFilter, { sortTableData } from "components/customTable/sortFilter";

import DonationService from "./service";
import { getRandomData } from "./utils";
import sampleDonationStocks from "../../data/donation_stocks.json";

function LeftSide() {
  const history = useHistory();
  const { t } = useTranslation();
  const { currencySymbol } = useCurrencySymbol();
  const { selectedRestaurantId, isRestaurantLoaded, hasRetaurants } =
    useUserData();
  const { setLoading, setError } = useLoading();

  const [filteredTableData, setFilteredTableData] = useState([]);
  const [fd, setfd] = useState({ orderBy: "desc", search: "" });
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [quantityModalShow, setQuantityModelShow] = useState(false);
  const [qtyNotAvailableProducts, setQtyNotAvailableProducts] = useState([]);

  const tableColumns = [
    {
      dataField: "name",
      caption: t("Products"),
      className: "fw-bold",
      style: { width: "110px" },
      headerStyle: { width: "110px" },
      type: "string",
    },
    {
      caption: t("Stock"),
      dataField: "stock.unit_stock",
      className: "text-center",
      headerClassName: "text-center",
      type: "customRender",
      render: (col, it) => (
        <span>
          {it.stock === null
            ? 0
            : it.stock.unit_stock !== null
            ? it.stock.unit_stock
            : 0}
        </span>
      ),
    },
    {
      dataField: "unit",
      caption: t("Unit"),
      className: "text-center",
      headerClassName: "text-center",
      type: "string",
    },
    {
      label: "Expiry",
      caption: t("Expiry"),
      dataField: "stock.expiry",
      type: "dropdown",
      active: true,
      options: EXPIRY_OPTIONS,
      elem: (text) => (
        <span className={`${getExpiryTextColor(text)}`}>{t(text)}</span>
      ),
    },
    {
      dataField: "unit_price",
      caption: `${t("Value")} (${currencySymbol})`,
      className: "text-center",
      headerClassName: "text-center",
      type: "string",
    },
    {
      caption: t("Qty to donate"),
      dataField: "qty_to_donate",
      type: "number",
      active: true,
      columnType: "inputNumber",
    },
    {
      dataField: "unit_price",
      caption: `${t("Donation value")} (${currencySymbol})`,
      className: "text-center",
      headerClassName: "text-center",
      type: "customRender",
      render: (col, it) => (
        <span>
          {customToFixed(
            parseFloat(it.qty_to_donate || 0) * parseFloat(it.unit_price || 0)
          )}
        </span>
      ),
    },
  ];

  useEffect(() => {
    fetchAndSetDonationStock();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchAndSetDonationStock();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fd.search, fd.sortby, fd.orderBy, selectedRestaurantId, isRestaurantLoaded]);

  const formatFilteredTableData = ({ ingredient_stock }) =>
    setFilteredTableData(
      (ingredient_stock ?? []).map((i) => ({
        ...i,
        name: i.name + (i.brand ? ` (${i.brand})` : ""),
      }))
    );

  const fetchAndSetDonationStock = async () => {
    let result = null;
    try {
      if (isRestaurantLoaded && !hasRetaurants) {
        result = getSampleDonationStock();
        result.ingredient_stock.forEach((product) => {
          product.qty_to_donate = 0;
        });
      }

      if (selectedRestaurantId && isRestaurantLoaded && hasRetaurants) {
        result = await getDonationStock();
        result.ingredient_stock.forEach((product) => {
          product.qty_to_donate = 0;
        });
      }

      if (result) {
        formatFilteredTableData(result);
        setPageCount(result.total_pages);
        setCurrentPage(result.page - 1);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setError(DEFAULT_ERROR_MESSAGE);
    }
  };

  const getDonationStock = (page = 1) => {
    const payload = {
      restaurant_id: selectedRestaurantId,
      limit: ITEMS_PER_PAGE,
      page,
      order_by: fd.orderBy.toUpperCase(),
      ...(fd.sortby && { sort_by: fd.sortby }),
      ...(fd.search && { search: fd.search }),
    };
    return request.get(`donations/stocks`, payload);
  };

  const getSampleDonationStock = (page = 1) => {
    const offset = (page * ITEMS_PER_PAGE) - ITEMS_PER_PAGE;
    return {
      ingredient_stock: sampleDonationStocks.slice(offset, ITEMS_PER_PAGE+offset),
      page: page,
      total_pages: Math.ceil(sampleDonationStocks.length/ITEMS_PER_PAGE)
    }
  }

  // Invoke when user click to request another page.
  const handlePageClick = async (event) => {
    try {
      let result = null;
      if (isRestaurantLoaded && !hasRetaurants) {
        result = getSampleDonationStock(event.selected + 1);
      } else {
        result = await getDonationStock(event.selected + 1);
      }
      
      formatFilteredTableData(result);
      setPageCount(result.total_pages);
      setCurrentPage(event.selected);
    } catch (error) {
      console.log(error);
      setError(DEFAULT_ERROR_MESSAGE);
    }
  };

  const deleteItem = (row) => (e) => {
    let newTableData = filteredTableData.filter(
      (f) => f.meal.toLowerCase() !== row.meal.toLowerCase()
    );
    setFilteredTableData(() => newTableData);
  };

  const tableInputChange =
    (it) =>
    ({ target: { name, value } }) => {
      const newTableData = cloneDeep(filteredTableData);
      const isExist = newTableData.find((f) => f.id === it.id);

      if (isExist) {
        set(isExist, name, value);
        isExist.state = "update";

        let data = isExist;
        const newSelectedRows = cloneDeep(qtyNotAvailableProducts);
        let stock = data.stock ? data.stock.unit_stock : 0;
        if (stock < data.qty_to_donate) {
          const isExist = newSelectedRows.find((f) => f === it.id);
          if (!isExist) {
            setQtyNotAvailableProducts(() => [...newSelectedRows, it.id]);
          }
        } else {
          setQtyNotAvailableProducts(() =>
            newSelectedRows.filter((p) => p !== it.id)
          );
        }

        setFilteredTableData(newTableData);
      }

      if (selectedProducts.length > 0) {
        const isProductExist = selectedProducts.find((f) => f.id === it.id);
        if (isProductExist) {
          set(
            isProductExist,
            name,
            typeof value === "string" ? parseFloat(value) : value
          );
          isProductExist.state = "update";
        }
      }
    };

  const onRowSelectChanged = (col, rowData) => (ev) => {
    const result = col.options?.find((o) => o.id === +ev);
    if (!result) {
      return;
    }
    const newFilteredData = cloneDeep(filteredTableData);
    const isExist = newFilteredData.find((f) => f.id === rowData.id);
    if (isExist) {
      set(isExist, col.dataField, result.value);
      isExist.state = "update";
      setFilteredTableData(newFilteredData);
      const { id, name, value } = {
        id: rowData.id,
        name: col.dataField,
        value: result.value,
      };
    }
  };

  const selectChange =
    (it) =>
    ({ target: { checked } }) => {
      const newSelectedRows = cloneDeep(selectedRows);
      const newSelectedProducts = cloneDeep(selectedProducts);
      if (checked) {
        setSelectedProducts(() => [...newSelectedProducts, it]);
        setSelectedRows(() => [...newSelectedRows, it.id]);
      } else {
        setSelectedProducts(() =>
          newSelectedProducts.filter((p) => p.id !== it.id)
        );
        setSelectedRows(() => newSelectedRows.filter((p) => p !== it.id));
      }
    };

  const selectAllRows = ({ target: { checked } }) => {
    setSelectedProducts(() => (checked ? [...filteredTableData] : []));
    setSelectedRows(() =>
      checked ? [...filteredTableData.map((i) => i.id)] : []
    );
  };

  const onSearchChange = debounce((ev) => {
    setfd({ ...fd, search: ev.target.value });
  }, DEBOUNCE_DELAY);

  const donateStock = () => {
    let isExist = false;

    for (let i = 0; i < qtyNotAvailableProducts.length; i++) {
      isExist = selectedRows.find((id) => {
        return id === qtyNotAvailableProducts[i];
      });
      if (isExist) {
        break;
      }
    }

    let isProductDonationValueAvailable = true;
    for (let i = 0; i < selectedProducts.length; i++) {
      let _selectedProducts = selectedProducts[i];
      if (
        _selectedProducts.qty_to_donate === null ||
        _selectedProducts.qty_to_donate === undefined ||
        _selectedProducts.qty_to_donate === 0
      ) {
        isProductDonationValueAvailable = false;
        break;
      }
    }

    if (isExist || !isProductDonationValueAvailable) {
      setQuantityModelShow(true);
    } else {
      DonationService.setSelectedItems(selectedProducts);
      history.push("/donation/review");
    }
  };

  const rows = sortTableData(fd, tableColumns, filteredTableData);

  return (
    <Row className="d-container pe-0">
      <Col className="card card-height">
        <Row className="me-2">
          <Col className="card-title-conatiner">
            <h2 className="card-title">{t("SoonToBeExpiredStock")}</h2>
          </Col>
          <Col className="sort-container d-flex justify-content-end mt-3 me-2">
            <input
              type="search"
              className="search-input me-3"
              placeholder={t("Search")}
              onChange={onSearchChange}
            />
            <SortFilter
              cols={tableColumns}
              fd={fd}
              setfd={setfd}
              rootClassName="sort-filter"
            />
          </Col>
        </Row>
        <Row className="card-body table-height pe-0">
          <CustomTable
            columns={tableColumns}
            data={rows}
            deleteRow={deleteItem}
            onRowSelectChanged={onRowSelectChanged}
            selectChange={selectChange}
            selectedProducts={selectedRows}
            selectAllProducts={selectAllRows}
            onInputChange={tableInputChange}
            lessQtyProducts={qtyNotAvailableProducts}
          />
        </Row>
        <Row>
          <div className="d-flex justify-content-end">
            <ReactPaginate
              nextLabel={`${t("Next")}   >`}
              onPageChange={handlePageClick}
              forcePage={currentPage}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
              pageCount={pageCount}
              previousLabel={`<   ${t("Back")}`}
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              containerClassName="pagination"
              activeClassName="active"
              renderOnZeroPageCount={null}
            />
          </div>

          {Boolean(selectedRows.length) && (
            <div className="d-flex justify-content-end mt-2 mb-2 selected-prod-div">
              {Boolean(selectedRows?.length) && (
                <div className="d-flex flex-column me-3">
                  <label>{t("Selected products")}</label>
                  <label className="d-flex  justify-content-end">
                    {selectedRows?.length}
                  </label>
                </div>
              )}
              <button
                className="float-end donate-btn mb-2"
                onClick={donateStock}
              >
                {t("Donate")}
              </button>
            </div>
          )}
        </Row>
      </Col>
      <DefaultModal
        show={quantityModalShow}
        onHide={() => setQuantityModelShow(false)}
        onPositiveClicked={() => {
          setQuantityModelShow(false);
        }}
        modalData={{
          title: t("Quantity not available"),
          description: t(
            "Some of the selected products are not available for donation. Please reduce its quantity."
          ),
          positiveBtnTitle: t("Ok"),
        }}
      />
    </Row>
  );
}

export default LeftSide;
