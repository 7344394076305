import { useState, useCallback, useRef } from "react";
import jsPDF from "jspdf";
import Form from "react-bootstrap/Form";
import moment from "moment";
import { Modal, Card, Row, Col } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";

import request from "services/request";
import storage from "services/storage";
import { useUserData } from "contexts/AuthContextManagement";
import { useLoading } from "contexts/LoadingContextManagement";
import { cloneDeep } from "common/utils.ts";
import { useOnDropAccepted } from 'common/utils.ts';

import JPEG_ICON from "assets/images/icon/jpeg.svg";
import CLOSE_ICON from "assets/images/icon/DELETE.svg";
import EYE_ICON from "assets/images/icon/eye.svg";
import FullSoonLogo from "assets/images/cible_l.png";

import "index.css";

function ReportDetailsModal(props) {
  const { t } = useTranslation();
  const { setLoading, setError} = useLoading();
  let pdfRef = useRef(null);
  const { orderDetails } = props;
  const location = useLocation();
  const { selectedRestaurantId } = useUserData();
  const currentUser = JSON.parse(storage.getItem("user"));
  const [files, setFiles] = useState([]);
  const [reportDetail, setReportDetail] = useState({});

  var doc = new jsPDF({ format: "letter", unit: "px" });
  doc.setFont("NunitoSans-Light-normal", "normal");
  
  const onDropAccepted = useOnDropAccepted(setFiles, setError, t);

  const problems = [
    {
      name: "order_incomplete",
      label: t("My order is incomplete (e.g. product missing)"),
    },
    {
      name: "order_damaged",
      label: t("My order is damaged (e.g out of date products)"),
    },
    {
      name: "order_not_in_time",
      label: t("My order didn't arrive in time"),
    },
    {
      name: "other",
      label: t("Other"),
    },
  ];

  const thumbsContainer = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 16,
  };

  const thumb = {
    display: "inline-flex",
    borderRadius: 2,
    border: "1px solid #eaeaea",
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: "border-box",
  };

  const thumbInner = {
    display: "flex",
    minWidth: 0,
    overflow: "hidden",
  };

  const img = {
    display: "block",
    width: "auto",
    height: "100%",
  };

  const onRemoveFile = (file, i) => () => {
    const newFiles = [...files];
    newFiles.splice(newFiles.indexOf(file), 1);
    setFiles(newFiles);
  };

  const thumbs = files.map((file) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img src={file.preview} style={img} alt="..." />
      </div>
    </div>
  ));

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/jpg, image/jpeg, image/png",
    maxFiles: 1,
    onDropAccepted
  });

  const detailComponent = (type, className, isPrint) => {
    const isProvider = type === "provider";
    return (
      <>
        <div className={`provider-details  ${className}`}>
          <div>
            <h5>
              {isProvider ? t("Provider") : t("Order")} {t("Details")}
            </h5>
          </div>

          <div className="mt-4 d-flex justify-content-between">
            <div>
              <label className="p-caption">
                {isProvider ? t("Provider") : t("Order number")}
              </label>
              <br />
              <label className="p-value">
                {isProvider && orderDetails.provider.name.toUpperCase()}
                {!isProvider && orderDetails.order_number}
              </label>
            </div>
            <div>
              <label className="p-caption">
                {isProvider ? t("ContactName") : t("Provider")}
              </label>
              <br />
              <label className="p-value">
                {!isProvider && orderDetails.provider.name.toUpperCase()}
              </label>
            </div>
          </div>
          {isProvider && (
            <div>
              <div>
                <label className="p-caption">{t("Email")}</label>
                <br />
                <label className="p-value">
                  {orderDetails.provider?.email}
                </label>
              </div>
              <div>
                <label className="p-caption">{t("PhoneNumber")}</label>
                <br />
                <label className="p-value">
                  {orderDetails.provider?.contact_number}
                </label>
              </div>
              <div>
                <label className="p-caption">{t("Address")}</label>
                <br />
                <label className="p-value mb-0">
                  {orderDetails.provider?.address}
                </label>
              </div>
            </div>
          )}

          {!isProvider && (
            <div>
              <div className="d-flex justify-content-between">
                <div>
                  <label className="p-caption">{t("Number of products")}</label>
                  <br />
                  <label className="p-value">
                    {orderDetails.purchase_items.length}
                  </label>
                </div>
                <div>
                  <label className="p-caption">{t("Total price TTC")}</label>
                  <br />
                  <label className="p-value">{orderDetails.total_price}</label>
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <div>
                  <label className="p-caption">{t("Order date")}</label>
                  <br />
                  <label className="p-value">
                    {moment(orderDetails.createdAt).format("YYYY-MM-DD")}
                  </label>
                </div>
                <div>
                  <label className="p-caption">{t("Delivery date")}</label>
                  <br />
                  <label className="p-value">
                    {orderDetails.estimated_delivery_date}
                    {isPrint && <>&nbsp; &nbsp; &nbsp;</>}
                  </label>
                </div>
              </div>
            </div>
          )}
        </div>
      </>
    );
  };

  const handleChange = (event) => {
    if (event.target.name === "problem_detail") {
      setReportDetail({
        ...reportDetail,
        [event.target.name]: event.target.value,
      });
      return;
    }
    if (event.target.checked) {
      setReportDetail({
        ...reportDetail,
        [event.target.name]: event.target.checked,
      });
      return;
    }

    const newReportDetail = cloneDeep(reportDetail);
    delete newReportDetail[event.target.name];

    setReportDetail({ ...newReportDetail });
  };

  const sendRequest = async (currentDoc) => {
    const options = [];
    for (const it of Object.keys(reportDetail)) {
      if (problems.map((r) => r.name).includes(it)) {
        options.push(problems.find((r) => r.name === it)?.label);
      }
    }

    const payload = {
      user_id: currentUser?.id,
      user_email: currentUser?.email,
      provider_id: orderDetails.provider?.id,
      provider_email: orderDetails.provider?.email_address,
      restaurant_id: selectedRestaurantId,
      explanation: reportDetail.problem_detail,
      options,
    };

    try {
      const result = await request.post(
        `/purchase-orders/report-a-problem/${props.orderDetails.id}`,
        payload,
        false
      );
      if (![200, 201].includes(result.status)) {
        throw new Error((await result.json())?.msg);
      }

      const resultBody = await result.json();

      let formData = new FormData();
      formData.append("file", currentDoc.output("blob"), "file.pdf");
      if (files[0]) {
        formData.append("image", files[0]);
      }
      const resultFile = await request.bodyFile(
        `/purchase-orders/report-a-problem/send-to-provider/${resultBody?.id}`,
        formData
      );

      if (![200, 201].includes(resultFile.status)) {
        throw new Error((await result.json())?.msg);
      }

      props.onHide();
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const submit = () => {
    const ele = document.getElementById("print");

    doc.html(ele, {
      html2canvas: { scale: 0.57 },

      callback: async (currentDoc) => {
        pdfRef.current = currentDoc;
        currentDoc.addImage(FullSoonLogo, "PNG", 400, 20);
        //pdfRef.current?.save(`order-details.pdf`);
        sendRequest(currentDoc);
      },
    });
  };

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        fullscreen={true}
        centered
        className="report-details"
      >
        <Modal.Body className="report-provider-container">
          <section className={`maincontent h-10 maincontent-collapsed`}>
            <Card className="p-5 ">
              <Row className="mb-2">
                <label className="back" onClick={() => props.onHide()}>
                  {"<"} {t("Back")}
                </label>
              </Row>
              <Row>
                <h3 className="heading-text">
                  {location?.state?.products?.provider?.name?.toUpperCase()}
                  {t("Report a problem")}
                </h3>
              </Row>
              <Row className="h-100 mt-3">
                <Col sm={4} className="details">
                  {detailComponent("order")}
                  {detailComponent("provider", "mt-5")}
                </Col>
                {
                  <Col sm={{ span: 6, offset: 1 }}>
                    <p className="o-d">
                      {t(
                        "Which of the following option best describe your problem"
                      )}
                      ?
                    </p>
                    <div className="c-t-order-provider">
                      {problems.map((problem, i) => {
                        return (
                          <Form.Group className="mb-1" key={i}>
                            <label className="checkbox">
                              <input
                                type="checkbox"
                                onChange={handleChange}
                                name={problem.name}
                              />
                              <span className="me-2" />
                              {problem.label}
                            </label>
                          </Form.Group>
                        );
                      })}
                    </div>
                    <p className="o-d">{t("Please explain your problem")}</p>
                    <textarea
                      className="p-2"
                      name="problem_detail"
                      onChange={handleChange}
                      rows={7}
                      cols={100}
                    />
                    <p className="o-d mt-2">
                      {t("Add a document to your message")}
                    </p>
                    <Col className="mt-1 photo-container">
                      <span className="report-input-title ps-0">
                        {t("Photo")}
                      </span>
                      <div className="">
                        <div {...getRootProps({ className: "dropzone" })}>
                          <input {...getInputProps()} />
                          <p>{t("Drag&Drop")}</p>
                        </div>
                        <aside>
                          <span className="report-input-title m-0">
                            {t("AcceptedFiles")}
                          </span>
                          <div className="files-list">
                            <ul>
                              {files.map((file, i) => (
                                <li
                                  key={i}
                                  className="d-flex justify-content-between"
                                >
                                  <div className="d-flex">
                                    <img src={JPEG_ICON} alt="..." />
                                    <p className="ps-3">
                                      <span>{file.path}</span>
                                      <span className="size">
                                        {parseInt(file.size * 0.001)} Kb
                                      </span>
                                    </p>
                                  </div>
                                  <div>
                                    <img
                                      src={EYE_ICON}
                                      alt="..."
                                      className="eye me-3"
                                    />
                                    <img
                                      src={CLOSE_ICON}
                                      alt="..."
                                      className="close"
                                      onClick={onRemoveFile(file, i)}
                                    />
                                  </div>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </aside>
                        <aside style={thumbsContainer}>{thumbs}</aside>
                      </div>
                    </Col>

                    <div className="d-flex justify-content-end mt-3 selected-prod-div">
                      <button
                        className="float-end place-order-btn"
                        onClick={submit}
                      >
                        {t("Submit")}
                      </button>
                    </div>
                  </Col>
                }
              </Row>

              <div className="d-none d-print-block ">
                <section
                  className={`maincontent h-100 maincontent-collapsed p-5 print-report-problem`}
                  id="print"
                >
                  <Row className="mb-4">
                    <h5 className=" fw-bold">{t("Problem reported")}</h5>
                  </Row>
                  <div className="d-flex flex-row">
                    {detailComponent("provider", "details-tab p-d col", true)}
                    {detailComponent("client", "details-tab c-d col", true)}
                  </div>
                  <Row className="mt-5">
                    <div>
                      <h5 className=" fw-bold">{t("OrderDetails")}</h5>
                    </div>

                    <Col sm={{ span: 12 }}>
                      <div className="order-summary mt-5">
                        <div className="d-flex justify-content-between d-date mb-3">
                          <h5 className=" fw-bold">
                            {t("Best options to describe the problem")}
                          </h5>
                        </div>
                        <div className="c-t-order-provider c-t-order-provider-print">
                          {problems.map((problem, i) => {
                            return (
                              <Form.Group className="mb-1" key={i}>
                                <label className="checkbox">
                                  <input
                                    type="checkbox"
                                    name={problem.name}
                                    checked={reportDetail[problem.name]}
                                  />
                                  <span className="me-2" />
                                  {problem.label}
                                </label>
                              </Form.Group>
                            );
                          })}
                        </div>
                      </div>
                    </Col>

                    <Col sm={{ span: 12 }}>
                      <div className="order-summary mt-5">
                        <div className="d-flex justify-content-between d-date mb-3">
                          <h5 className=" fw-bold">
                            {t("Problem explanation")}
                          </h5>
                        </div>
                        <div className="c-t-order-provider">
                          <p>{reportDetail.problem_detail}</p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </section>
              </div>
            </Card>
          </section>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ReportDetailsModal;
