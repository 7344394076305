import React from "react";
import { Modal, Button, Row, Col, Container } from "react-bootstrap";
import DeleteIcon from "../../assets/images/icon/DELETE.svg";
import "./index.scss";

function DefaultModal(props) {
  return (
    <Modal
      {...props}
      className="delete-modal"
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="modal-90w"
      centered
    >
      <Container>
        <Row>
          <Col className="icon-conatiner" lg={{ offset: 10, span: 2 }}>
            <img
              src={DeleteIcon}
              className="icon"
              alt=""
              onClick={() => {
                props.onHide();
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <p className="title">{props.modalData.title}</p>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <p className="description">{props.modalData.description}</p>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col lg={6}>
            <Button
              type="submit"
              className="positive-btn"
              onClick={() => {
                props.onPositiveClicked();
              }}
            >
              {props.modalData.positiveBtnTitle}
            </Button>
          </Col>
        </Row>
      </Container>
    </Modal>
  );
}

export default DefaultModal;
