import React, { useState, useEffect } from "react";
import { Row, Col, Dropdown, Button, Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import request from "services/request";
import storage from "services/storage";
import { useUserData } from "contexts/AuthContextManagement";
import { useLoading } from "contexts/LoadingContextManagement";
import { CURRENCIES_LIST, DEFAULT_ERROR_MESSAGE } from "common/constants";

const intialState = {
  username: "",
  email: "",
  current_password: "",
  new_password: "",
  confirm_password: "",
  currency: "",
};

function Account() {
  const history = useHistory();
  const { t } = useTranslation();

  const { setLoading, setError, setSuccessMessage } = useLoading();
  const { setUserData } = useUserData();
  const [state, setState] = useState(intialState);
  const currentUser = JSON.parse(storage.getItem("user"));

  useEffect(() => {
    setState({
      ...state,
      email: currentUser?.email ?? "",
      username: currentUser?.username ?? "",
      currency: currentUser?.currency ?? "",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelect = (eventKey, event) => {
    var currency = "";
    for (let c of CURRENCIES_LIST) {
      if (c.code === eventKey) {
        currency = c.name;
        break;
      }
    }
    setState({ ...state, currency: currency });
  };

  const updateProfile = async (profileData) => {
    setLoading(true);
    try {
      const result = await request.patch(`/users/update-profile`, profileData);
      const data = await result.clone().json();
      // check for error response
      if (result.ok) {
        currentUser.email = state.email;
        currentUser.username = state.username;
        currentUser.currency = state.currency;
        storage.setItem("user", JSON.stringify(currentUser));
        setUserData(currentUser);
        setState({
          ...state,
          current_password: "",
          new_password: "",
          confirm_password: "",
        });
        if (data && !data.is_email_verified) {
          logout();
        }
        setSuccessMessage(data.msg);
      } else {
        const errorMsg = (data && data.msg) || result.status;
        setError(`${errorMsg}`);
      }
    } catch (error) {
      setError(DEFAULT_ERROR_MESSAGE);
    }
    setLoading(false);
  };

  const logout = () => {
    storage.clear();
    history.push("/signin");
  };

  const onSaveBtnClick = async (event) => {
    // Prevent default behavior
    event.preventDefault();
    if (state.new_password !== state.confirm_password) {
      //TODO: show error
      alert("Passwords don't match");
      return;
    }
    const profileData = { ...state };
    delete profileData.confirm_password;
    updateProfile(profileData);
  };

  return (
    <div className="account">
      {/* Email and name block */}

      <Form className="" onSubmit={onSaveBtnClick}>
        <Form.Group className="">
          <Row>
            <Col lg={{ span: 4 }}>
              <p className="title-text">{t("Profile.heading")}</p>
              <p className="sub-title-text">{t("Profile.subHeading")}</p>
            </Col>
            <Col lg={{ span: 3 }}>
              <Row>
                <p className="input-title">{t("Profile.name")}</p>
                <Form.Control
                  type="text"
                  placeholder="Name"
                  aria-describedby="inputGroupPrepend"
                  required
                  className="form-control"
                  onChange={(event) =>
                    setState({ ...state, username: event.target.value })
                  }
                  value={state.username}
                />
              </Row>
              <Row>
                <p className="input-title mt-3">{t("Profile.emailAddress")}</p>
                <Form.Control
                  type="email"
                  placeholder="Email address"
                  aria-describedby="inputGroupPrepend"
                  required
                  className="form-control"
                  onChange={(event) =>
                    setState({ ...state, email: event.target.value })
                  }
                  value={state.email}
                />
              </Row>
              <Row>
                <p className="input-title mt-3">{t("Profile.plan")}</p>
                <Form.Control
                  type="text"
                  aria-describedby="inputGroupPrepend"
                  className="form-control"
                  disabled
                  style={{ textTransform: "capitalize"}}
                  value={currentUser?.subscription?.plan}
                />
              </Row>
            </Col>
          </Row>

          {/* divider */}
          <div className="divider"></div>

          {/* Password change */}
          <Row>
            <Col lg={{ span: 4 }}>
              <p className="title-text">{t("Password")}</p>
              <p className="sub-title-text">{t("ChangePassword")}</p>
            </Col>
            <Col lg={{ span: 3 }}>
              <Row>
                <p className="input-title">{t("CurrentPassword")}</p>
                <Form.Control
                  type="password"
                  placeholder=""
                  aria-describedby="inputGroupPrepend"
                  required={
                    state.new_password.length !== 0 &&
                    state.confirm_password.length !== 0
                  }
                  className="form-control"
                  onChange={(event) =>
                    setState({ ...state, current_password: event.target.value })
                  }
                  value={state.current_password}
                />
              </Row>
              <Row>
                <p className="input-title mt-3">{t("NewPassword")}</p>
                <Form.Control
                  type="password"
                  placeholder=""
                  aria-describedby="inputGroupPrepend"
                  required={
                    state.current_password.length !== 0 &&
                    state.confirm_password.length !== 0
                  }
                  className="form-control"
                  onChange={(event) =>
                    setState({ ...state, new_password: event.target.value })
                  }
                  value={state.new_password}
                />
              </Row>
              <Row>
                <p className="input-title mt-3">{t("ConfirmPassword")}</p>
                <Form.Control
                  type="password"
                  placeholder=""
                  aria-describedby="inputGroupPrepend"
                  required={
                    state.current_password.length !== 0 &&
                    state.new_password.length !== 0
                  }
                  className="form-control"
                  onChange={(event) =>
                    setState({ ...state, confirm_password: event.target.value })
                  }
                  value={state.confirm_password}
                />
              </Row>
            </Col>
          </Row>

          <div className="divider"></div>

          {/* Curency block */}

          <Row>
            <Col lg={{ span: 4 }}>
              <p className="title-text">{t("Currency")}</p>
              <p className="sub-title-text">{t("ChangeCurrency")}</p>
            </Col>
            <Col lg={{ span: 3 }}>
              <Row>
                <Dropdown className="ps-0 pe-0" onSelect={handleSelect}>
                  <Dropdown.Toggle
                    variant="button"
                    className="dropdown-toggle btn-icon currency-dropdown"
                  >
                    {state.currency.toUpperCase() || t("Currency")}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {CURRENCIES_LIST.map((c, i) => (
                      <Dropdown.Item key={i} eventKey={c.code}>
                        {c.name.toUpperCase()}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col lg={{ offset: 10, span: 2 }}>
              <Button type="submit" variant="primary save-button">
                {t("SaveChanges")}
              </Button>
            </Col>
          </Row>
        </Form.Group>
      </Form>
    </div>
  );
}

export default Account;
