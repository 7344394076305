import React, { useState } from "react";
import moment from "moment";

import LeftSide from "./leftSide";
import RightSide from "./rightSide";

import "./index.scss";

function Finance() {
  const [formData, setformData] = useState({
    start_date: moment().subtract(3, 'days').format('YYYY-MM-DD'),
    end_date: moment().subtract(2, 'days').format('YYYY-MM-DD'),
    meals: [],
  });

  const onApply = (params) => {
    setformData({ ...params });
  };

  return (
    <>
      <LeftSide formData={formData} />
      <RightSide onApply={onApply} formData={formData} />
    </>
  );
}

export default Finance;
