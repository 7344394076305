import moment from 'moment';

export const dummyIceHockeyData = [
    {
        "impact": null,
        "id": "567b412b-7b6e-4497-be40-dcb7d066068c",
        "name": "IceHockey - NHL - Dallas Stars vs Arizona Coyotes",
        "type": "sports",
        "address": "Canada",
        "country": "Canada",
        "city": "Canada",
        "start_date": "2024-03-06",
        "end_date": null,
        "start_time": "01:05:00",
        "end_time": null,
        "image_path": null,
        "createdAt": "2024-03-05T11:26:58.000Z",
        "updatedAt": "2024-03-05T11:26:58.000Z",
        "restaurants": [
            {
                "id": "1d885461-172e-4652-aea1-6768282ceb84",
                "name": "Los Pollos La Défense"
            }
        ]
    },
    {
        "impact": null,
        "id": "567b412b-7b6e-4497-be40-dcb7d066068c",
        "name": "IceHockey - QMJHL - Saint John Sea Dogs vs Moncton Wildcats",
        "type": "sports",
        "address": "Canada",
        "country": "Canada",
        "city": "Canada",
        "start_date": "2024-03-06",
        "end_date": null,
        "start_time": "01:05:00",
        "end_time": null,
        "image_path": null,
        "createdAt": "2024-03-05T11:26:58.000Z",
        "updatedAt": "2024-03-05T11:26:58.000Z",
        "restaurants": [
            {
                "id": "1d885461-172e-4652-aea1-6768282ceb84",
                "name": "Los Pollos La Défense"
            }
        ]
    }
];

// Function to update the start_date field to the current date for an array of objects
const updateStartDateToCurrentDate = (dataArray) => {
    return dataArray.map(item => {
        return {
            ...item,
            start_date: moment().format("YYYY-MM-DD")
        };
    });
};

// Update start_date for dummyIceHockeyData
export const updatedIceHockeyData = updateStartDateToCurrentDate(dummyIceHockeyData);

// Dummy football data array
export const dummyIceFootballData = [
    {
        "impact": null,
        "recurrence": null,
        "id": "567b412b-7b6e-4497-be40-dcb7d066068c",
        "name": "Football - Ligue 1 - Boston Celtics vs Sacramento Kings",
        "type": "sports",
        "address": "Canada",
        "country": "Canada",
        "city": "Canada",
        "start_date": "2024-03-06",
        "end_date": null,
        "start_time": "01:05:00",
        "end_time": null,
        "image_path": null,
        "createdAt": "2024-03-05T11:26:58.000Z",
        "updatedAt": "2024-03-05T11:26:58.000Z",
        "restaurants": [
            {
                "id": "1d885461-172e-4652-aea1-6768282ceb84",
                "name": "Los Pollos La Défense"
            }
        ]
    },
    {
        "impact": null,
        "recurrence": null,
        "id": "567b412b-7b6e-4497-be40-dcb7d066068c",
        "name": "Football - Ligue 1 - Nantes vs Lille",
        "type": "sports",
        "address": "Canada",
        "country": "Canada",
        "city": "Canada",
        "start_date": "2024-03-06",
        "end_date": null,
        "start_time": "01:05:00",
        "end_time": null,
        "image_path": null,
        "createdAt": "2024-03-05T11:26:58.000Z",
        "updatedAt": "2024-03-05T11:26:58.000Z",
        "restaurants": [
            {
                "id": "1d885461-172e-4652-aea1-6768282ceb84",
                "name": "Los Pollos La Défense"
            }
        ]
    }
];

// Update start_date for dummyIceFootballData
export const updatedIceFootballData = updateStartDateToCurrentDate(dummyIceFootballData);

// Dummy basketball data array
export const dummyBasketballData = [
    {
        "impact": null,
        "recurrence": null,
        "id": "567b412b-7b6e-4497-be40-dcb7d066068c",
        "name": "Basketball - NBA - Detroit Pistons vs Washington Wizards",
        "type": "sports",
        "address": "Canada",
        "country": "Canada",
        "city": "Canada",
        "start_date": "2024-03-06",
        "end_date": null,
        "start_time": "01:05:00",
        "end_time": null,
        "image_path": null,
        "createdAt": "2024-03-05T11:26:58.000Z",
        "updatedAt": "2024-03-05T11:26:58.000Z",
        "restaurants": [
            {
                "id": "1d885461-172e-4652-aea1-6768282ceb84",
                "name": "Los Pollos La Défense"
            }
        ]
    },
    {
        "impact": null,
        "recurrence": null,
        "id": "567b412b-7b6e-4497-be40-dcb7d066068c",
        "name": "NBA - Memphis Grizzlies vs Philadelphia 76ers",
        "type": "sports",
        "address": "Canada",
        "country": "Canada",
        "city": "Canada",
        "start_date": "2024-03-06",
        "end_date": null,
        "start_time": "01:05:00",
        "end_time": null,
        "image_path": null,
        "createdAt": "2024-03-05T11:26:58.000Z",
        "updatedAt": "2024-03-05T11:26:58.000Z",
        "restaurants": [
            {
                "id": "1d885461-172e-4652-aea1-6768282ceb84",
                "name": "Los Pollos La Défense"
            }
        ]
    }
];

// Update start_date for dummyBasketballData
export const updatedBasketballData = updateStartDateToCurrentDate(dummyBasketballData);
