import React, { useState } from "react";
import moment from "moment";

import LeftSide from "./leftSide";
import ProviderIngredientRightSide from "./provIngredRightSide";
import IngredientRightSide from "./ingredientRightSide";
import { useUserData } from "contexts/AuthContextManagement";

import "./index.scss";

function MyIngredients() {

  const { isProviderIngredient } = useUserData();

  const [formData, setFormData] = useState({
    start_date: moment().format("YYYY-MM-DD"),
    end_date: moment().format("YYYY-MM-DD")
  });

  const onApply = (params) => {
    setFormData({ ...params });
  };
  return (
    <div className="events">
      <LeftSide formData={formData} />

      {isProviderIngredient ?
        <ProviderIngredientRightSide onApply={onApply} formData={formData}/> : 
        <IngredientRightSide onApply={onApply} formData={formData}/> 
      }
    </div>
  );
}

export default MyIngredients;