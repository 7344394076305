import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";

import request from "services/request";
import { useUserData } from "contexts/AuthContextManagement";
import { useLoading } from "contexts/LoadingContextManagement";
import { DEFAULT_ERROR_MESSAGE, SAMPLE_TOP_FLOPS } from "common/constants";
import { timezoneFormat, getTimeRanges } from "common/utils";
import { getRandomNumber } from "views/occupancy/data";

import LeftSide from "./leftSide";
import RightSide from "./rightSide";
import MyResturants from "./myResturants";

import "./index.scss";

function MarketView() {
  const { t } = useTranslation();
  const { setError } = useLoading();
  const {
    selectedRestaurant,
    selectedRestaurantId,
    isRestaurantLoaded,
    hasRetaurants,
  } = useUserData();

  const [formData, setformData] = useState({
    start_date: moment().subtract(3, "days").format("YYYY-MM-DD"),
    end_date: moment().subtract(2, "days").format("YYYY-MM-DD"),
    competitors: [],
    myRestaurants: [],
    breakfast: false,
    lunch: false,
    afternoon: false,
    dinner: false,
  });
  const [isCompetitiveset, setIsCompetitiveset] = useState(true);
  const [topFlopLoading, setTopFlopLoading] = useState(true);
  const [keyFiguresLoading, setKeyFiguresLoading] = useState(true);
  const [competitiveKeyFigures, setCompetitiveKeyFigures] = useState([]);
  const [myRestaurantKeyFigures, setMyRestaurantKeyFigures] = useState([]);
  const [competitiveTopFlop, setCompetitiveTopFlop] = useState({
    top: [],
    flop: [],
  });
  const [myRestaurantTopFlop, setMyRestaurantTopFlop] = useState({
    top: [],
    flop: [],
  });
  const prevFromData = useRef(formData);

  const mapper = (t) => ({
    ...t,
    meal: t.meal,
    sales: getRandomNumber(100, 1000),
  });

  const onApply = (params) => {
    setformData({ ...params });
    if (isRestaurantLoaded && !hasRetaurants) {
      setCompetitiveTopFlop({
        top: SAMPLE_TOP_FLOPS.TOP.map(mapper),
        flop: SAMPLE_TOP_FLOPS.FLOP.map(mapper),
      });
      setMyRestaurantTopFlop({
        top: SAMPLE_TOP_FLOPS.TOP.map(mapper),
        flop: SAMPLE_TOP_FLOPS.FLOP.map(mapper),
      });
    }
  };

  useEffect(() => {
    if (isRestaurantLoaded && !hasRetaurants) {
      setSampleTopFlop();
    }
  }, [selectedRestaurantId, isRestaurantLoaded]);

  useEffect(() => {
    if (selectedRestaurantId !== "") {
      getTopFlop();
      getKeyFigures();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRestaurantLoaded]);

  const setSampleTopFlop = () => {
    setCompetitiveTopFlop({
      top: SAMPLE_TOP_FLOPS.TOP.map(mapper),
      flop: SAMPLE_TOP_FLOPS.FLOP.map(mapper),
    });
    setMyRestaurantTopFlop({
      top: SAMPLE_TOP_FLOPS.TOP.map(mapper),
      flop: SAMPLE_TOP_FLOPS.FLOP.map(mapper),
    });
    setTopFlopLoading(false);
    setKeyFiguresLoading(false);
  };

  const getKeyFigures = async () => {
    try {
        setKeyFiguresLoading(true);
        const result = await request.get(
          `market-views/key-figures`,
          {
            restaurant_id: selectedRestaurantId,
            ...timezoneFormat(
              formData.start_date,
              formData.end_date,
              selectedRestaurant?.timezone
            ),
            restaurants: formData.myRestaurants
              ? formData.myRestaurants.map((r) => r.id)
              : [],
            competitors: formData.competitors ?? [],
            time_ranges: getTimeRanges(formData, selectedRestaurant?.timezone),
          },
          true,
          true,
          true
        );

        setCompetitiveKeyFigures(result?.key_figures?.competitors);
        setMyRestaurantKeyFigures(result?.key_figures?.restaurants);
        setKeyFiguresLoading(false);
    } catch (error) {
      if (error?.status !== 499) {
        setError(DEFAULT_ERROR_MESSAGE);
        setKeyFiguresLoading(false);
      }
    }
  };

  const getTopFlop = async () => {
    try {
        setTopFlopLoading(true);
        const result = await request.get(
          `top-and-flop/market-views`,
          {
            restaurant_id: selectedRestaurantId,
            ...timezoneFormat(
              formData.start_date,
              formData.end_date,
              selectedRestaurant?.timezone
            ),
            restaurants: formData.myRestaurants
              ? formData.myRestaurants.map((r) => r.id)
              : [],
            competitors: formData.competitors ?? [],
            time_ranges: getTimeRanges(formData, selectedRestaurant?.timezone),
          },
          true,
          true,
          true
        );

        // if (result?.top?.length === 0 && result?.flop?.length === 0) {
        //   return setSampleTopFlop();
        // }

        setCompetitiveTopFlop({
          top: result?.competitors?.top,
          flop: result?.competitors?.flop,
        });
        setMyRestaurantTopFlop({
          top: result?.restaurants?.top,
          flop: result?.restaurants?.flop,
        });
        setTopFlopLoading(false);
    } catch (error) {
      if (error?.status !== 499) {
        setError(DEFAULT_ERROR_MESSAGE);
        setTopFlopLoading(false);
      }
    }
  };

  return (
    <>
      <div className="divider">
        <ul className="navbtns mb-0">
          <li className={`${isCompetitiveset ? "active" : ""}`}>
            <button
              className={`nav-link ${isCompetitiveset ? "active" : ""}`}
              onClick={() => setIsCompetitiveset(true)}
            >
              {t("CompetitiveSet")}
            </button>
          </li>
          <li className={`${!isCompetitiveset ? "active" : ""}`}>
            <button
              className={`nav-link ${!isCompetitiveset ? "active" : ""}`}
              onClick={() => setIsCompetitiveset(false)}
            >
              {t("MyRestaurants")}
            </button>
          </li>
        </ul>
      </div>
      <div className="d-flex after-divider-container">
        {isCompetitiveset && (
          <LeftSide
            formData={formData}
            keyFigures={competitiveKeyFigures}
            keyFiguresLoading={keyFiguresLoading}
            topFlopLoading={topFlopLoading}
            competitiveTopFlop={competitiveTopFlop}
            myRestaurantTopFlop={myRestaurantTopFlop}
          />
        )}
        {!isCompetitiveset && (
          <MyResturants
            formData={formData}
            keyFigures={myRestaurantKeyFigures}
            topFlopLoading={topFlopLoading}
            myRestaurantTopFlop={myRestaurantTopFlop}
          />
        )}
        <RightSide onApply={onApply} formData={formData} />
      </div>
    </>
  );
}

export default MarketView;
