import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import moment from "moment";
import { Row, Col, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { debounce } from "lodash";

import request from "services/request";
import CustomTable from "components/customTable";
import EventTable from "../../../components/customTable/eventTable";
import SortFilter from "components/customTable/sortFilter";
import { cloneDeep } from "common/utils.ts";
import { useLoading } from "contexts/LoadingContextManagement";
import {
  DEBOUNCE_DELAY,
  DEFAULT_ERROR_MESSAGE,
  EVENT_TYPES,
  ITEMS_PER_PAGE,
} from "common/constants";
import { useUserData } from "contexts/AuthContextManagement";
import { getRandomNumber } from "views/occupancy/data";

import CustomModal from "./modal";
import DeleteModal from "views/commonViews/DeleteModal";
import { RATING } from "./utils";

import Addicon from "assets/images/icon/ADD_WHITE.svg";
import Trashicon from "assets/images/icon/filter.svg";
import EditIcon from "assets/images/icon/EDIT.svg";
import FilterIcon from "assets/images/icon/FILTER_ICON1.svg";

import "./index.scss";

const randomAddresses = [
  "91 Bd Poniatowski, 75012 Paris, France",
  "102-104, Av. de Verdun B.P. 78, 92390 Villeneuve-la-Garenne, France",
  "Bd André Malraux, 78990 Élancourt, France",
  "55 Rue du Cardinal Lemoine, 75005 Paris, France",
  "91 Bd Poniatowski, 75012 Paris, France",
  "102-104, Av. de Verdun B.P. 78, 92390 Villeneuve-la-Garenne, France",
  "Bd André Malraux, 78990 Élancourt, France",
  "55 Rue du Cardinal Lemoine, 75005 Paris, France",
  "91 Bd Poniatowski, 75012 Paris, France",
  "102-104, Av. de Verdun B.P. 78, 92390 Villeneuve-la-Garenne, France",
];

const types = [
  "cultural", "cultural","cultural","cultural","cultural",
  "sports","sports","sports","sports","sports"
]
const dummyEvents = [
  "Mardi Gras",
  "Cannes Film Festival",
  "Bastille Day",
  "Tour de France",
  'Basketball - NBA - Detroit Pistons vs Washington Wizards',
  'Basketball - NBA - Memphis Grizzlies vs Philadelphia 76ers',
  'Football - Ligue 1 - Boston Celtics vs Sacramento Kings',
  'Football - Ligue 1 - Nantes vs Lille',
  'IceHockey - NHL - Dallas Stars vs Arizona Coyotes',
  'IceHockey - QMJHL - Saint John Sea Dogs vs Moncton Wildcats',

];

function LeftSide({ formData }) {
  const { t } = useTranslation();
  const { setLoading, setError } = useLoading();
  const [modalShow, setModalShow] = useState(false);
  const [fd, setfd] = useState({ orderBy: "desc" , search: "" });
  const [selectedEvents, setSelectedEvents] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [deleteModalShow, setDeleteModelShow] = useState(false);
  // We start with an empty list of items.
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedRowType, setSelectedRowType] = useState(null)
  const [selectedEventType, setSelectedEventType] = useState(null)
  const {
    selectedRestaurantId,
    isRestaurantLoaded,
    hasRetaurants,
    isFilterShown,
    setFilterShown,
    setSampleRestaurantModal,
  } = useUserData();

  const tableColumns = [
    {
      dataField: "name",
      caption: t("Events"),
      className: "fw-bold",
      style: { width: "150px" },
      headerStyle: { width: "150px" },
      type: "string",
    },
    {
      dataField: "type",
      caption: t("Type"),
      className: "text-center",
      headerClassName: "text-center",
      type: "customRender",
      render: (_, it) => {
        return <span>
          {t(EVENT_TYPES.find(v => v.value === it?.type)?.label)}
        </span>
      }
    },
    {
      dataField: "impact_type",
      caption: t("Impact on"),
      className: "text-center",
      headerClassName: "text-center",
      type: "customRender",
      render: (_, it) => {
        return (
          <span>
            {it?.impact?.map((impactObj, index) => (
              <React.Fragment key={index}>
                <span key={index}>{t(RATING.find(v => v.value === impactObj.impact_type)?.label)}</span>
                {index !== it.impact.length - 1 && <span>,&nbsp;&nbsp;</span>}
              </React.Fragment>
            ))}
          </span>
        );
      }
    },
    {
      dataField: "startDateTime",
      caption: t("Date & Time"),
      className: "text-center",
      headerClassName: "text-center",
      type: "customRender",
      render: (_, it) => {
        let startDateTime = "";
        if (it.start_date) {
          startDateTime = moment(it.start_date).format("MM/DD/YYYY");
        }
        if (it.start_time) {
          startDateTime += ` ${moment(it.start_time, ["HH:mm"]).format("h:mm A")}`;
        }
        return (
          startDateTime
        );
      }
    },
    {
      dataField: "action",
      caption: "",
      className: "text-center",
      headerClassName: "text-center",
      type: "customRender",
      render: (_, it) => (
        <img
          src={EditIcon}
          className="cursor-pointer"
          alt="..."
          onClick={showRow(it)}
        />
      ),
    },
  ];

  const eventCategories = [
    {
      id:"1",
      name : "Football"
    },
    {
      id:"2",
      name : "IceHockey"
    },
    {
      id:"3",
      name : "Basketball"
    },
  ]

  const handleEventCategory = async (type) => {
    if(!hasRetaurants){
      setSelectedRowType(type)
      if(type == selectedRowType){
        setSelectedRowType(null)
        setCurrentItems([])
      }
      else {
        setSelectedRowType(type)
      }
    }
    else {
      if(type == selectedRowType){
        setSelectedRowType(null)
        setCurrentItems([])
      }
      else {
        setCurrentItems([])
        setSelectedRowType(type)
        const result = await getEvents(1, type);
        setCurrentItems(result?.events)
        setPageCount(result.total_pages);
        setCurrentPage(result.page - 1);
        setLoading(false);
      } 
    }
  }
  // Invoke when user click to request another page.
  const handlePageClick = async (event) => {
    try {
      const result = await getEvents(event.selected + 1);
      setCurrentItems(() =>
        result.events.map((e) => ({
          ...e,
          startDateTime: getStartDateTime(e),
        })));
      setPageCount(result.total_pages);
      setCurrentPage(event.selected);
    } catch (error) {
      console.log(error);
      setError(DEFAULT_ERROR_MESSAGE);
    }
  };

  useEffect(() => {
    fetchAndSetEvents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fd.sortby, formData, fd.orderBy, fd.search, selectedRestaurantId]);

  const getStartDateTime = (ev) => {
    let startDateTime = "";
    if (ev.start_date) {
      startDateTime = moment(ev.start_date).format("MM/DD/YYYY");
    }
    if (ev.start_time) {
      startDateTime += ` ${moment(ev.start_time, ["HH:mm"]).format("h:mm A")}`;
    }
    return startDateTime;
  };

  const fetchAndSetEvents = async () => {
    try {
      let result = {};
      if (isRestaurantLoaded && !hasRetaurants) {
        result = {
          events: dummyEvents.map((e, i) => ({
            id: getRandomNumber(),
            name: e,
            address:
              randomAddresses[
              Math.floor(Math.random() * randomAddresses.length)
              ],
            type: types[i % types.length],
            // start_date: moment().subtract(i, "days").format("MM/DD/YYYY"),
            start_date : moment().format("MM/DD/YYYY"),
            start_time: moment().subtract(i, "hours").format("h:mm A"),
          })),
          total_pages: 1,
          page: 1,
        };
      } else {
        result = await getEvents();
      }

      if (formData.type === "sports") {        
        setSelectedEventType(eventCategories)
      } 
      else {
        setCurrentItems(() =>
        result?.events?.map((e) => ({
          ...e,
          startDateTime: getStartDateTime(e),
          }))
        );
        setPageCount(result.total_pages);
        setCurrentPage(result.page - 1);
        setLoading(false);
      }
     
    } catch (error) {
      console.log(error);
      setError(DEFAULT_ERROR_MESSAGE);
    }
  };
  
  const getEvents = (page = 1, search) => {
    const payload = {
      restaurant_id: selectedRestaurantId,
      limit: ITEMS_PER_PAGE,
      ...(formData.type && { type: formData.type}),
      ...(formData.start_date && {start_date : formData.start_date}),
      ...(formData.end_date && {end_date : formData.end_date}),
      ...(search && { search }),
      ...(fd.search && { search: fd.search }),
      page,
      order_by: fd.orderBy.toUpperCase(),
      ...(fd.sortby && { sort_by: fd.sortby === 'startDateTime' ? 'start_date' : fd.sortby }),
    };
    return request.get(`events`, payload);
  };

  const showRow = (row) => () => {
    if (row?.start_time) {
      const [hh, mm] = row.start_time.split(":");
      row.start_time = `${hh}:${mm}`;
    }
    if (row?.end_time) {
      const [hh, mm] = row.end_time.split(":");
      row.end_time = `${hh}:${mm}`;
    }
    setSelectedEvents(row);
    setModalShow(true);
  };

  const onModalHide = () => {
    setSelectedEvents(null);
    setModalShow(false);
  };

  const selectChange =
    (it) =>
      ({ target: { checked } }) => {
        const newSelectedRows = cloneDeep(selectedRows);
        setSelectedRows(() =>
          checked
            ? [...newSelectedRows, it.id]
            : newSelectedRows.filter((p) => p !== it.id)
        );
  };

  const selectAllRows = ({ target: { checked } }) => {
    setSelectedRows(() => (checked ? [...currentItems.map((i) => i.id)] : []));
  };

  const deleteRows = async () => {
    try {
      const result = await request.delete(`/events`, {
        event_ids: selectedRows,
      });
      if (result.status === 200) {
        setSelectedRows([]);

        if (formData.eventTypes?.length) {
          const updatedItems = { ...currentItems };
          updatedItems[selectedRowType] = updatedItems[selectedRowType].filter((i) => !selectedRows.includes(i.id))
          setCurrentItems(updatedItems)
        } else {
          setCurrentItems(() =>
            currentItems.filter((i) => !selectedRows.includes(i.id))
          )
        }
      }
    } catch (error) {
      console.log(error);
      setError(DEFAULT_ERROR_MESSAGE);
    }
  };

  const onRowAdded = async (row, isUpdated) => {
    const result = await getEvents();
      setCurrentItems(() =>  
        result.events.map((e) => ({
        ...e,
        startDateTime: getStartDateTime(e),
      })));
  };

  const onSearchChange = debounce((ev) => {
    setfd({ ...fd, search: ev.target.value });
  }, DEBOUNCE_DELAY);

  const sortTableColumns = tableColumns
  .filter(col => col.dataField !== "impact_type")
  .map(col => ({ ...col }));

  sortTableColumns.push({
    dataField: "ranking",
    caption: t("Ranking"),
    className: "text-center",
    headerClassName: "text-center",
    type: "number",
  });

  return (
    <div className="my-ingredients">
      <CustomModal
        show={modalShow}
        onHide={onModalHide}
        onRowAdded={onRowAdded}
        data={{ ...(selectedEvents && { selectedEvent: selectedEvents }) }}
      />

      <DeleteModal
        show={deleteModalShow}
        onHide={() => setDeleteModelShow(false)}
        onPositiveClicked={() => {
          setDeleteModelShow(false);
          deleteRows();
        }}
        modalData={{
          title: t("Delete events"),
          description: t(
            "Are you sure you want to delete events ? You cannot undo this action."
          ),
          positiveBtnTitle: t("Yes, delete events"),
        }}
      />

      <Row>
        <Col lg={{ span: 3 }}>
          <p className="title-text">{t("Events")}</p>
          <p className="sub-title-text">{t("Manage your Event list.")}</p>
        </Col>
      </Row>

      <Row>
        <div className="d-flex row">
          <Col> 
            <Button
              variant="primary add-btn-container"
              onClick={() => setModalShow(true)}
            >
              <img src={Addicon} className="add-btn-icon" alt="..." />
              {t("Add Event")}
            </Button>
          </Col>
          <Col className="sort-container d-flex justify-content-end">
            {Boolean(selectedRows.length) && (
              <img
                src={Trashicon}
                className="me-3"
                onClick={() => {
                  if (isRestaurantLoaded && !hasRetaurants) {
                    setSampleRestaurantModal(true);
                    return;
                  }
                  setDeleteModelShow(true);
                }}
                alt="..."
              />
            )}
            <input
              type="search"
              className="search-input me-3"
              placeholder={t("Search")}
              onChange={onSearchChange}
            />
            <SortFilter
              cols={sortTableColumns}
              fd={fd}
              setfd={setfd}
              rootClassName="sort-filter"
            />
            {!isFilterShown && (
              <div className="headerbtns ms-3">
                <button
                  onClick={() => setFilterShown(true)}
                  className="btn btn-white btn-icon btn-theme"
                  style={{
                    height:"35px"
                  }}
                >
                  <img src={FilterIcon} alt="" className="m-0" />
                </button>
              </div>
            )}
          </Col>
        </div>
      </Row>
      <Row className="mt-3 table-container">
        {
          formData?.type === "sports" ?
              <EventTable
                  eventTypeDefaultColumn={selectedEventType}
                  columns={tableColumns}
                  data={currentItems}
                  selectChange={selectChange}
                  selectedProducts={selectedRows}
                  selectAllProducts={selectAllRows}
                  onRowSelectChanged={() => { }}
                  selectedRowType={selectedRowType}
                  onRowDoubleClick={(type) => handleEventCategory(type)}
                  currentPage={currentPage}
                  onPageChange={handlePageClick}
                  pageCount={pageCount}
              />
             :
            <>
              <CustomTable
                columns={tableColumns}
                data={currentItems}
                selectChange={selectChange}
                selectedProducts={selectedRows}
                selectAllProducts={selectAllRows}
                onRowSelectChanged={() => { }}
                onRowDoubleClick={(e) => console.log('e: ', e)}
              />
              <Row>
                <div className="d-flex justify-content-end mt-2">
                  <ReactPaginate
                    nextLabel={`${t("Next")}   >`}
                    forcePage={currentPage}
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={2}
                    pageCount={pageCount}
                    previousLabel={`<   ${t("Back")}`}
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    containerClassName="pagination"
                    activeClassName="active pagination-page-active"
                    renderOnZeroPageCount={null}
                  />
                </div>
              </Row>
            </>
        }
      </Row>
    </div>
  );
}

export default LeftSide;