import React, { useState } from "react";
import Select from "react-select";
import {
  Switch,
  Route,
  Redirect,
  useHistory
} from "react-router-dom";
import stripeCard from "assets/images/icon/stripe-card.svg";
import stripeBank from "assets/images/icon/stripe-bank.svg";


import "./index.scss";
import { Col } from "react-bootstrap";
import SelectLanguage from "components/selectLanguage";
import { useTranslation } from "react-i18next";

const PaymentElement = ({ payment_elements }) => {
  const history = useHistory();
  const [selectedMethod, setSelectedMethod] = useState({path: history.location.pathname});
  const { t } = useTranslation();
const methodHandler = (event) => {
    history.push(event.path);
    setSelectedMethod(event)
}

  return (
    <div className="DemoWrapper">
      <Col style={{marginBottom: "50px"}} md={{ span: 4, offset: 8 }} lg={{ span: 3, offset: 9 }}>
          <SelectLanguage />
      </Col>
      <div style={{marginBottom: "20px"}}>
          <h2>{t("Select Payment Method")}</h2>
        </div>
      <div className="price-container">
        <div className={"card "+ (selectedMethod.path === "/iban-element" ? "active" : "")} onClick={() => methodHandler(payment_elements[0])}>
        <img src={stripeBank} className="stripe-icon" alt="..." />
        <span className="card-text" >{t("Bank")}</span>
        </div>
        <div className={"card "+ (selectedMethod.path === "/card-element" ? "active" : "")}  onClick={() => methodHandler(payment_elements[1])}>
        <img src={stripeCard} className="stripe-icon" alt="..." />
        <span className="card-text" >{t("Card")}</span>
        </div>
      </div>
      <Switch>
        <Redirect to={payment_elements[0].path} from="/" exact />
        {payment_elements.map(({ path, component: Component }) => (
          <Route key={path} path={path}>
            <div className="Demo">
              <Component />
            </div>
          </Route>
        ))}
      </Switch>
    </div>
  );
};

export default PaymentElement;
