import React, { useState } from "react";

import { Button, Form, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import request from "services/request";

import { toast } from "react-toastify";

function LinkModal({ onHide, show, link, name, userEmail }) {
  const { t, i18n } = useTranslation();
  const [isCopied, setIsCopied] = useState(false);
  const [email, setEmail] = useState(userEmail);

  const copyLinkHandler = () => {
    //TODO: Update link according to the environment
    const updatedLink = `${process.env.REACT_APP_FRONTEND_ENDPOINT}/signup?token=${link}`
    navigator.clipboard.writeText(updatedLink);
    setIsCopied(true);
    toast.success("Link is copied!");
    // onHide();
  };

  const shareLinkHandler = async () => {
    const res = await request.post("/auth/send-registration-link", {
      token: link,
      email,
      name,
      language: i18n.language,
    })
    const data = await res.json()
    toast.success((data.msg))
  }

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      centered
      className="add-ingredient"
      backdropClassName="add-ingredient-backdrop"
    >
      <Modal.Header className="add-restaurants-modal-header" closeButton>
        <Modal.Title className="add-restaurants-modal-title">
          <h1 style={{ fontSize: "30px" }}>{t("Create Link")}</h1>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Row>
          <h2>{t("Copy Link")}</h2>
          <p style={{margin: "0px"}}>{t("You can create a direct link to your project.")}</p>
        </Row>
        <div className="inputWithButton">
          <Form.Control
            type="text"
            className="inputField"
            value={link}
            disabled
          />
          <Button
            onClick={copyLinkHandler}
            className={`${isCopied ? "active-btn" : ""} copy-btn`}
          >
            {isCopied ? t("Copied") : t("Copy")}
          </Button>
        </div>
        <Row style={{"marginTop": "20px"}}>
          <h2>{t("Share Link")}</h2>
          <p>{t("Share the registration link with your client")}</p>
        </Row>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Form.Control
            type="email"
            className="inputField"
            placeholder="demo@mail.com"
            style={{ width: "80%" }}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Button
            onClick={shareLinkHandler}
            className=""
            style={{ width: "15%", fontWeight: "bold" }}
          >
            {("Share")}
          </Button>
        </div>
        <div style={{ display: "flex", justifyContent: "end" }}></div>
      </Modal.Body>
    </Modal>
  );
}

export default LinkModal;
