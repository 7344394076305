import React, { useState, useEffect } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { useLoading } from "contexts/LoadingContextManagement";
import { DEFAULT_ERROR_MESSAGE } from "common/constants";
import { useUserData } from "contexts/AuthContextManagement";
import request from "services/request";
import AddCompetitiveModal from "./AddCompetitiveModal";
import DeleteModal from "views/commonViews/DeleteModal";
import DeleteIcon from "assets/images/icon/DELETE.svg";
import Addicon from "assets/images/icon/ADD_WHITE.svg";
import {
  getDummyCompetitiveSet,
  getDummyAddCompetitiveSet,
  DummyCompetitiveSetData,
} from "../utils";
import { useTranslation } from "react-i18next";

function CompetitiveSet() {
  const { t } = useTranslation();
  const [addCompetitiveModalShow, setAddCompetitiveModalShow] = useState(false);
  const [deleteModalShow, setDeleteModelShow] = useState(false);
  const [selectedCompetitor, setSelectedCompetitor] = useState(null);
  const [competitors, setCompetitors] = useState([]);
  const [restaurants, setAllRestaurants] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedCompetator, setSelectedCompetator] = useState([]);
  const [search, setSearch] = useState('');
  const { setLoading, setError } = useLoading();
  const {
    selectedRestaurantId,
    isRestaurantLoaded,
    hasRetaurants,
    setSampleRestaurantModal,
  } = useUserData();
  
  // temporary commented
  // useEffect(() => {
  //   if (isRestaurantLoaded && !hasRetaurants) {
  //     setAllRestaurants(DummyCompetitiveSetData);
  //     setSelectedCompetator(DummyCompetitiveSetData);
  //     setCompetitors(DummyCompetitiveSetData);
  //     setSelectedProducts(DummyCompetitiveSetData.map((s) => s.id));
  //     return;
  //   }
  //   if (selectedRestaurantId !== "") {
  //     getAllRestaurants();
  //     getCompetitorsData();
  //   }

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [
  //   selectedRestaurantId,
  //   hasRetaurants,
  //   isRestaurantLoaded,
  //   DummyCompetitiveSetData,
  // ]);

  useEffect(() => {
    if (isRestaurantLoaded && !hasRetaurants) {
      setAllRestaurants(DummyCompetitiveSetData);
      setSelectedCompetator(DummyCompetitiveSetData);
      setCompetitors(DummyCompetitiveSetData);
      setSelectedProducts(DummyCompetitiveSetData.map((s) => s.id));
    } else if (selectedRestaurantId !== "" && isRestaurantLoaded && hasRetaurants) {
      getCompetitorsData();
    }
  }, [selectedRestaurantId, isRestaurantLoaded, hasRetaurants, DummyCompetitiveSetData]);

  useEffect(() => {
    if (search && search.length > 2) {
      getAllRestaurants();
    } else {
      setAllRestaurants([]);
    }
  }, [selectedRestaurantId, search]);

  const getCompetitorsData = async () => {
    setLoading(true);
    try {
      const result = await request.get(
        "competitors?restaurant_id=" + selectedRestaurantId
      );
      const restaurantProduct = [];
      const _competitors = [];

      result.competitors.forEach((restaurant) => {
        restaurantProduct.push(restaurant.competitor.id);
        _competitors.push(restaurant.competitor);
      });
      setSelectedProducts(restaurantProduct);
      setSelectedCompetator(_competitors);
      setCompetitors(_competitors);
    } catch (error) {
      setError(DEFAULT_ERROR_MESSAGE);
    }
    setLoading(false);
  };

  const getAllRestaurants = async () => {
    if (search && search.length >= 3) { 
      setLoading(true);
      try {
        const payload = {
          search: search, 
        };
        const result = await request.get("restaurants/all", payload);
        setAllRestaurants(
          result.restaurants.filter((r) => r.id !== selectedRestaurantId)
        );
      } catch (error) {
        setError(DEFAULT_ERROR_MESSAGE);
      }
      setLoading(false);
    }
  };

  const saveCompetitor = async (competitorIds) => {
    if (selectedRestaurantId == "") {
      setAddCompetitiveModalShow(false);
      setSampleRestaurantModal(true);
      return;
    }
    const competitorData = {
      restaurant_id: selectedRestaurantId,
      competitors: competitorIds,
    };
    setLoading(true);
    try {
      const result = await request.post("/competitors", competitorData);
      const data = await result.clone().json();

      // check for error response
      if (result.ok) {
        getCompetitorsData();
        setAddCompetitiveModalShow(false);
        return;
      } else {
        const errorMsg = (data && data.msg) || result.status;
        setError(`${errorMsg}`);
      }
    } catch (error) {
      setError(DEFAULT_ERROR_MESSAGE);
    }
    setLoading(false);
  };

  const deleteCompetitor = async (competitorId) => {
    const competitorData = {
      restaurant_id: selectedRestaurantId,
      competitor_id: competitorId,
    };
    setLoading(true);
    try {
      const result = await request.delete("/competitors", competitorData);

      const data = await result.clone().json();
      // check for error response
      if (result.ok) {
        getCompetitorsData();
        return;
      } else {
        const errorMsg = (data && data.msg) || result.status;
        setError(`${errorMsg}`);
      }
    } catch (error) {
      setError(DEFAULT_ERROR_MESSAGE);
    }
    setLoading(false);
  };

  const item = (data, index) => (
    <Row key={index}>
      <Row className="pe-0">
        <Col lg={10}>
          <span className="competitor-title">{data.name}</span>
        </Col>
        <Col className="icon-container" lg={2}>
          <img
            src={DeleteIcon}
            className="icon"
            alt=""
            onClick={() => {
              setSelectedCompetitor(data);
              if (selectedRestaurantId == "") {
                setSampleRestaurantModal(true);
                return;
              } else {
                setDeleteModelShow(true);
              }
            }}
          />
        </Col>
      </Row>
      <Row>
        <span className="competitor-sub-title">{t("Address")}</span>
        <span className="competitor-text">{data.address}</span>
        <span className="competitor-sub-title">{t("SIRETNumber")}</span>
        <span className="competitor-text">{data.siret_number}</span>
        {/* divider */}
        {index === competitors.length - 1 ? (
          <></>
        ) : (
          <div className="divider"></div>
        )}
      </Row>
    </Row>
  );
  return (
    <div className="competitive-set">
      {/* Email and name block */}

      <Row>
        <Col lg={{ span: 3 }}>
          <p className="title-text">{t("GroupRestaurants")}</p>
          <p className="sub-title-text">
            {t("GroupRestaurants")}.
          </p>
        </Col>
        <Col lg={{ offset: 6, span: 3 }}>
          <Button
            variant="primary competitive-set-btn"
            onClick={() => {
              setSelectedCompetitor(null);
              setAddCompetitiveModalShow(true);
            }}
          >
            <img src={Addicon} alt="" className="competitive-set-btn-icon" />
            {t("Add")} {t("GroupRestaurants")}
          </Button>
        </Col>
      </Row>
      <Row>
        <Col className="" lg={{ offset: 3, span: 9 }}>
          {competitors.map((data, index) => item(data, index))}
        </Col>
      </Row>

      <DeleteModal
        show={deleteModalShow}
        onHide={() => setDeleteModelShow(false)}
        onPositiveClicked={() => {
          setDeleteModelShow(false);
          deleteCompetitor(selectedCompetitor.id);
        }}
        modalData={{
          title: t("DeleteCompetitor"),
          description: t("DeleteCompetitorText"),
          positiveBtnTitle: t("DeleteCompetitorBtn"),
        }}
      />
      {addCompetitiveModalShow ? (
        <AddCompetitiveModal
          show={addCompetitiveModalShow}
          onHide={() => setAddCompetitiveModalShow(false)}
          saveCompetitor={(data) => saveCompetitor(data)}
          restaurants={restaurants}
          selectedProducts={selectedProducts}
          selectedCompetator={selectedCompetator}
          setSelectedProducts={setSelectedProducts}
          setSelectedCompetator={setSelectedCompetator}
          setSearch={setSearch} 
        />
      ) : (
        <></>
      )}
    </div>
  );
}

export default CompetitiveSet;
