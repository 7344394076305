import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {  toast } from "react-toastify";


import request from "services/request";
import "react-phone-input-2/lib/style.css";
import SelectLanguage from "components/selectLanguage";

function RightSide() {
  const history = useHistory();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false)

  let token = new URLSearchParams(window.location.search).get("token");

  useEffect(async() => {
    try {
      setLoading(true)
      const result = await request.post(`/auth/add-user-restaurants?token=${token}`);

      const res = await result.json();
      // check for error response
      if (result.ok) {
        history.push("/signin");
        toast.success(res.msg);
        return
      }
      toast.error(res.msg);
      setLoading(false)
    } catch (error) {
      console.log(error);
      setLoading(false)
    }
  },[])

    
  return (
    <Col
      className="right-container align-self-center"
      md={{ span: 3, offset: 2 }}
      lg={{ span: 3, offset: 2 }}
    >
      <Row className="mt-4">
        {/* <span className="back-text ps-0">
          <img src={ArrowBack} className="pe-2" alt="" />
          {t("Back")}
        </span> */}
      </Row>
      <Col md={{ span: 4, offset: 8 }} style={{display: "flex", justifyContent: "flex-end", width: "100%", marginLeft: "0px"}} lg={{ span: 3, offset: 9 }}>
        <SelectLanguage />
      </Col>

      <Row>
        <p className="reset-password-title ps-0 mb-4">{t("Verify Your Account")}</p>
      </Row>

      <Row>
        <p className="reset-password-sub-title ps-0 mb-4">
          {t("Click the button below to verify your account.")}
        </p>
      </Row>

      <Row>
        <Form className="ps-0 pe-0">
          {loading && <h2>We are verifying your account</h2>}
          <Button style={{padding: "10px 20px"}} variant="primary reset-password-btn">
            {t("Verify Account")}
          </Button>
         
        </Form>
      </Row>
    </Col>
  );
}

export default RightSide;
