import { useState, useEffect } from "react";
import convertUnits from 'convert-units';

import {
  Modal,
  Button,
  Row,
  Col,
  Container,
  Form,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { useLoading } from "contexts/LoadingContextManagement";
import { useUserData } from "contexts/AuthContextManagement";
import SelectInput from "components/selectInput";
import request from "services/request";
import {
  UNITS,
  PROVIDER_INGREDIENT_CATEGORIES,
  STORAGE_UNITS,
} from "common/constants";

import UploadedIcon from "assets/images/uploaded_meal.png";
import { getConvertibleUnitOptions } from "../utils";

function TextInput({
  type = "text",
  dataField,
  required = true,
  caption,
  ...props
}) {
  return (
    <>
      <span className="add-restaurants-input-title ps-0">{caption}</span>
      <input
        type={type}
        name={dataField}
        className="form-control custom-input"
        required={required}
        {...props}
      />
    </>
  );
}

function CustomProviderModal({
  onHide,
  onRowAdded,
  show,
  data: { providers = [], selectedIngredient },
  isUploadedModal = true,
}) {
  const { t } = useTranslation();
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [isUploaded, setIsUploaded] = useState(false);
  const { setLoading, setError } = useLoading();
  const [fd, setFd] = useState();

  const {
    selectedRestaurantId,
    isRestaurantLoaded,
    hasRetaurants,
    setSampleRestaurantModal,
  } = useUserData();

  useEffect(() => {
    setFd({ allergens: [], details: [] });
    setIsSubmitDisabled(true);
    setIsUploaded(false);
  }, [show]);

  useEffect(() => {
    if (
      fd?.name &&
      fd?.provider_reference_name &&
      fd?.conditioning_name &&
      fd?.conditioning_unit &&
      fd?.conditioning_quantity &&
      fd?.price_excl_tax &&
      fd?.recipe_unit &&
      fd?.recipe_unit_quantity &&
      fd?.storage_unit
    ) {
      setIsSubmitDisabled(false);
      return;
    }
    setIsSubmitDisabled(true);
  }, [fd]);

  useEffect(() => {
    if (selectedIngredient) {
      setFd({...selectedIngredient, tax: (( selectedIngredient.tax / 100 ) * selectedIngredient.price_excl_tax )?.toFixed(2)});
    }
  }, [selectedIngredient]);

  const onSelectionChange =
    (type) =>
    ({ id, label, value }) => {
      if(type === "provider_reference_name"){
      setFd({ ...fd, provider_id: id, [type]: label });
        return
      }
      if(type === "recipe_unit"){
        if (convertUnits().possibilities().includes(id) && 
            convertUnits().from(id)
            .possibilities().includes(fd?.recipe_unit)) {
              const convertedValue = (convertUnits(1).from(id).to(fd?.recipe_unit));
              const convertedCost = (convertedValue * fd?.price_excl_tax)?.toFixed(4);
              setFd({ ...fd,recipe_unit: id, price_excl_tax: convertedCost });
              return
            }
      }
        setFd({...fd, [type]: id})
    };

  const onTextChange = ({ target: { name, value, type } }) => {
    if (["price_excl_tax", "tax", "recipe_unit_quantity"].includes(name)) {
        return setFd({
          ...fd,
          [name]: value,
        });
  
      }
    setFd({ ...fd, [name]: value });
  };

  const onSubmit = async (ev) => {
    ev.preventDefault();
    try {
      if (isRestaurantLoaded && !hasRetaurants) {
        setSampleRestaurantModal(true);
        onHide();
        return;
      }
      setIsSubmitDisabled(true);
      setLoading(true);
      const payload = {
        restaurant_id: selectedRestaurantId,
        ...fd,
        tax: parseFloat((parseFloat(fd.tax)/100)*fd.price_excl_tax),
      };

      

      if (!selectedIngredient) {
        delete payload.allergens
        delete payload.details
        const result = await request.post("/provider-ingredients", payload);
        onRowAdded(await result.json());
        setLoading(false);
        setIsUploaded(true)
        return
      }
      delete payload.id;
      delete payload.createdAt;
      delete payload.updatedAt;
      delete payload.provider
      delete payload.provider_name

      const resultQuery = await request.patch(
        `/provider-ingredients/${selectedIngredient.id}`,
        payload
      );
      if ([200].includes(resultQuery.status)) {
        onRowAdded(await resultQuery.json());
        setLoading(false);
        setIsUploaded(true)
        return;
      }
      throw Error(resultQuery);
    } catch (error) {
      console.log(error);
      setIsSubmitDisabled(false);
    }
  };

  const getProviderValue = () => {
    const result = providers?.find(({ id }) => id === fd?.provider_id);
    if (result) {
      return { ...result, label: result.name, value: result.id };
    }
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      centered
      className="add-ingredient"
      backdropClassName="add-ingredient-backdrop"
    >
      <Modal.Header className="add-restaurants-modal-header" closeButton>
        {!isUploaded && (
          <Modal.Title className="add-restaurants-modal-title">
            {selectedIngredient ? t("Edit") : t("Add")} {t("ProviderIngredients")}
          </Modal.Title>
        )}
      </Modal.Header>

      <Modal.Body>
        <Container className="p-0">
          {isUploadedModal && isUploaded && (
            <div className="d-flex justify-content-center flex-column  text-center upload-container">
              <div>
                <img src={UploadedIcon} alt="..." />
              </div>
              <div className="heading mt-5">
                <label>{t("Ingredient uploaded")} !</label>
              </div>
              <div className="subheading mt-2 mb-5">
                <label>
                  {t("Your ingredient has been successfully uploaded")}.
                </label>
              </div>
              <div>
                <Button className="add-restaurant-confirm-btn" onClick={onHide}>
                  {t("OK")}
                </Button>
              </div>
            </div>
          )}
          {!isUploaded && (
            <Form noValidate className="ps-0 pe-0" onSubmit={onSubmit}>
              <Row className=" d-flex justify-content-between">
                <Col lg={3}>
                  <TextInput
                    dataField={"name"}
                    caption={t("Name")}
                    placeholder={t("IngredientName")}
                    onChange={onTextChange}
                    value={fd?.name}
                    type="text"
                  />
                </Col>
                <Col lg={3}>
                  <TextInput
                    dataField={"provider_reference_name"}
                    caption={t("ProviderReferenceName")}
                    placeholder={t("ReferenceName")}
                    onChange={onTextChange}
                    value={fd?.provider_reference_name}
                    type="text"
                  />
                </Col>
                <Col lg={3}>
                  <TextInput
                    dataField={"provider_reference_number"}
                    caption={t("ProviderReferenceNumber")}
                    placeholder={t("ReferenceNumber")}
                    onChange={onTextChange}
                    value={fd?.provider_reference_number}
                    type="text"
                  />
                </Col>
                <Col lg={3}>
                  <span className="add-restaurants-input-title ps-0">
                    {t("Category")}
                  </span>
                  <SelectInput
                    dataField="category"
                    placeholder={t("SelectCategory")}
                    options={PROVIDER_INGREDIENT_CATEGORIES.map((i) => ({
                      ...i,
                      label: t(i.label),
                    }))}
                    onChange={onSelectionChange}
                    value={PROVIDER_INGREDIENT_CATEGORIES.find(
                      ({ id }) => id === fd?.category
                    )}
                  />
                </Col>
              </Row>

              <Row className="mt-4 d-flex justify-content-between">
                <Col lg={3}>
                  <TextInput
                    dataField={"conditioning_name"}
                    caption={t("ConditioningName")}
                    placeholder={t("ConditioningName")}
                    onChange={onTextChange}
                    value={fd?.conditioning_name}
                  />
                </Col>
                <Col lg={3}>
                  <span className="add-restaurants-input-title ps-0">
                    {t("ConditioningUnit")}
                  </span>
                  <SelectInput
                    dataField="conditioning_unit"
                    placeholder={t("SelectUnit")}
                    options={selectedIngredient && fd?.recipe_unit ? getConvertibleUnitOptions(fd?.recipe_unit) : UNITS.slice(1).map((r) => ({ ...r, label: t(r.label) }))}
                    onChange={onSelectionChange}
                    value={UNITS.find(({ id }) => id === fd?.conditioning_unit)}
                  />
                </Col>
                <Col lg={3}>
                  <TextInput
                    dataField={"conditioning_quantity"}
                    caption={t("ConditioningQuantity")}
                    placeholder={t("ConditioningQuantity")}
                    onChange={onTextChange}
                    value={fd?.conditioning_quantity}
                    type="number"
                  />
                </Col>
                <Col lg={3}>
                  <span className="add-restaurants-input-title ps-0">
                    {t("RecipeUnit")}
                  </span>
                  <SelectInput
                    dataField="recipe_unit"
                    placeholder={t("SelectUnit")}
                    options={selectedIngredient && fd?.recipe_unit ? getConvertibleUnitOptions(fd?.recipe_unit) : UNITS.slice(1).map((r) => ({ ...r, label: t(r.label) }))}
                    onChange={onSelectionChange}
                    value={UNITS.find(({ id }) => id === fd?.recipe_unit)}
                  />
                </Col>
              </Row>

              <Row className="mt-4 d-flex justify-content-between">
              <Col lg={3}>
                  <TextInput
                    dataField={"recipe_unit_quantity"}
                    caption={t("RecipeUnitQuantity")}
                    placeholder={t("RecipeUnitQuantity")}
                    onChange={onTextChange}
                    value={fd?.recipe_unit_quantity}
                    type="number"
                  />
                </Col>
                <Col lg={3}>
                  <span className="add-restaurants-input-title ps-0">
                    {t("StorageUnit")}
                  </span>
                  <SelectInput
                    dataField="storage_unit"
                    placeholder={t("SelectUnit")}
                    options={STORAGE_UNITS.slice(1).map((r) => ({ ...r, label: t(r.label)}))}
                    onChange={onSelectionChange}
                    value={{...STORAGE_UNITS.find(({ id }) => id === fd?.storage_unit), label: t(STORAGE_UNITS.find(({ id }) => id === fd?.storage_unit)?.label)}}
                  />
                </Col>
                <Col lg={3}>
                  <TextInput 
                    dataField={"price_excl_tax"}
                    caption={t("Price (without tax)")}
                    placeholder={t("Price without tax")}
                    onChange={onTextChange}
                    value={fd?.price_excl_tax}
                    type="number"
                  />
                </Col>
                <Col lg={3}>
                  <TextInput
                    dataField={"tax"}
                    caption={t("TVA")}
                    placeholder={t("TVA (%)")}
                    onChange={onTextChange}
                    value={fd?.tax}
                    type="number"
                  />
                </Col>
             
           
              </Row>

              <Row className="mt-4 d-flex">
              <Col lg={2}>
                  <TextInput
                    dataField={"min_stock"}
                    caption={t("min_stock")}
                    placeholder={t("min_stock")}
                    onChange={onTextChange}
                    value={fd?.min_stock}
                    type="number"
                  />
                </Col>
                <Col lg={2}>
                  <TextInput
                    dataField={"max_stock"}
                    caption={t("max_stock")}
                    placeholder={t("max_stock")}
                    onChange={onTextChange}
                    value={fd?.max_stock}
                    type="number"
                  />
                </Col>
                <Col lg={3}>
                  <span className="add-restaurants-input-title ps-0">
                    {t("Provider")}
                  </span>
                  <SelectInput
                    dataField="provider_id"
                    placeholder={t("SelectProvider")}
                    options={providers?.map((p) => ({
                      ...p,
                      label: p.name,
                      value: p.id,
                    }))}
                    onChange={onSelectionChange}
                    value={getProviderValue()}
                  />
                </Col>
              </Row>

              <Modal.Footer className="add-restaurants-modal-footer">
                <Button
                  type="submit"
                  disabled={isSubmitDisabled}
                  className="add-restaurant-confirm-btn"
                >
                  {t("Confirm")}
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Container>
      </Modal.Body>
    </Modal>
  );
}

export default CustomProviderModal;
