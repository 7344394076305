import BeverageIcon from "assets/images/beverage.png";
import DessertIcon from "assets/images/dessert.png";

function TopAndFlopView({ data }) {
  if (!data) {
    return <></>;
  }

  return data?.map((row, i) => {
    const product = row?.product ?? row?.meal;
    const text = row?.sales ?? row?.serving_minutes ?? row?.revenue;
    const type = row?.type;

    return (
      <div key={i} className="d-flex media top-flop-item">
        <div className="flex-shrink-0">
          <span className="boxicon">
            <img src={type === 'drinks' ? BeverageIcon : DessertIcon} alt={product} />
          </span>
        </div>
        <div className="flex-grow-1 ms-3">
          <h2>
            <strong>{text}</strong>
          </h2>
          <p className="text-muted">{product}</p>
        </div>
      </div>
    );
  });
}

export default TopAndFlopView;
