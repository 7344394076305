import React, { useState, Fragment, useRef, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { Row, Col } from "react-bootstrap";
import DatePicker from "react-datepicker";
import moment from "moment";
import { useTranslation } from "react-i18next";
import makeAnimated from "react-select/animated";
import Select from "react-select";

import SelectAsyncPaginate from "components/selectAsyncPaginate/index.tsx";
import { cloneDeep } from "common/utils";
import { useUserData } from "contexts/AuthContextManagement";
import { useFilterData } from "contexts/FilterContextManagment";
import { MEAL_CATEGORIES } from "common/constants";

import tableMealsDummy from "../../data/finance_meals.json";

import CloseIcon from "assets/images/close.png";
import CalendarIcon from "assets/images/calendar_datepicker.png";
import DeleteIcon from "assets/images/icon/DELETE_WHITE.svg";
import "react-datepicker/dist/react-datepicker.css";

const STATUS_CHECKBOXES = [
  {
    name: "profit",
    label: "Profit",
  },
  {
    name: "deficit",
    label: "Deficit",
  },
];

const options = tableMealsDummy.meals.map((m) => ({
  name: m.name,
  label: m.name,
  item_id: m.id,
}));

const START_DATE = new Date(new Date().setDate(new Date().getDate() - 1));
const END_DATE = new Date(new Date().setDate(new Date().getDate() - 1));

function RightSide({ onApply: onSubmit, formData: fd }) {
  const { t } = useTranslation();
  const {
    selectedFinanceFilterMeals,
    setSelectedFinanceFilterMeals,
    selectedFilterMyRestaurants,
    setSelectedFilterMyRestaurants,
    filterStartEndDate,
    setFilterStartEndDate,
    filterFormData,
    setFilterFormData,
    isFilterActive,
    updateFilterButton,
  } = useFilterData();

  const [selectedOption, setSelectedOption] = useState(selectedFinanceFilterMeals ?? []);
  const [isStartDateOpen, setIsStartDateOpen] = useState(false);
  const [isEndDateOpen, setIsEndDateOpen] = useState(false);
  const [formData, setformData] = useState(filterFormData ?? {});
  const [startEndDate, setStartEndDate] = useState({
    start_date: filterStartEndDate && filterStartEndDate.start_date !== "" ? filterStartEndDate.start_date : START_DATE,
    end_date: filterStartEndDate && filterStartEndDate.end_date !== "" ? filterStartEndDate.end_date : END_DATE,
  });
  const [selectedMyRestaurants, setSelectedMyRestaurants] = useState(selectedFilterMyRestaurants ?? []);
  const selectMyRestaurantRef = useRef(null);
  const { isFilterShown, setFilterShown, selectedRestaurantId, restaurants } = useUserData();

  const prevSelectedRestaurantId = useRef(selectedRestaurantId);
  const [resturantOptions, setResturantOptions] = useState(restaurants);

  const onDateChange = (name) => (e) => {
    setStartEndDate({ ...startEndDate, [name]: e });
    setIsStartDateOpen(false);
    setIsEndDateOpen(false);
  };

  useEffect(() => {
    // prevSelectedRestaurantId.current = selectedRestaurantId;
    onClearAll();
    setResturantOptions(restaurants)
  }, [selectedRestaurantId]);

  const TRANSITIONS_CHECKBOXES = [
    { name: "eat_in", label: "Eat in" },
    { name: "take_away", label: "Eat away" },
    { name: "delivery", label: "Delivery" },
  ];

  const onClearTransitions = () => {
    const newFormData = { ...formData };
    delete newFormData.service_type;  
    setformData(newFormData);
  };

  const onApply = (isInitial = false) => {
    const force = typeof isInitial === "boolean" ? isInitial : false;
    const payload = cloneDeep({ ...formData, ...startEndDate });
    const getFormattedDate = (d) => moment(d).format("YYYY-MM-DD");

    if (force) {
      STATUS_CHECKBOXES.map((x) => x.name).forEach((t) => delete payload[t]);
      delete payload.service_type;
    }

    payload.myRestaurants = selectedMyRestaurants.map((s) =>
      resturantOptions.find((r) => r.id === s.value).id
    );

    onSubmit({
      ...payload,
      meals: selectedOption.map((s) => s.item_id),
      mealTypes: Object.keys(formData?.mealTypes ?? {}).filter((y) => {
        if (formData.mealTypes[y]) {
          return y;
        }
      }),
      ...(payload.start_date && {
        start_date: getFormattedDate(payload.start_date),
      }),
      ...(payload.end_date && { end_date: getFormattedDate(payload.end_date) }),
      ...(force && {
        meals: [],
        mealTypes: [],
        start_date: getFormattedDate(START_DATE),
        end_date: getFormattedDate(END_DATE),
      }),
    });
  };

  const onCheckboxChange =
    (type) =>
    ({ target: { checked, name } }) => {
      setformData({
        ...formData,
        ...(type === null && { [name]: checked }),
        ...(name === "eat_in" && { service_type: "eat_in" }),
        ...(name === "take_away" && { service_type: "take_away" }),
        ...(name === "delivery" && { service_type: "delivery" }),
        ...(type === "mealTypes" && {
          [type]: { ...formData[type], [name]: checked },
        }),
      });
    };

  const customCheckbox = ({ name, label, checked, type = null }) => {
    return (
      <Form.Group className="mb-3">
        <label className="checkbox">
          <input
            type="checkbox"
            onChange={onCheckboxChange(type)}
            name={name}
            checked={checked}
          />
          <span className="me-2" />
          {label}
        </label>
      </Form.Group>
    );
  };

  const onClearStatus = () => {
    const newFormData = JSON.parse(JSON.stringify(formData));
    STATUS_CHECKBOXES.map((x) => x.name).forEach((t) => delete newFormData[t]);
    setformData({ ...newFormData });
  };

  const onClearDates = () => {
    setStartEndDate({ start_date: START_DATE, end_date: END_DATE });
  };

  const onClearAll = () => {
    onClearDates();
    setformData({});
    onClearMeals();
    onClearRestaurants();
    onClearTransitions();
    onApply(true);
  };

  const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected, ...args }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? data.color
          : isFocused
          ? "#F3F4FB"
          : undefined,
        color: isDisabled ? "#ccc" : isSelected ? "black" : "black",
      };
    },
    multiValueLabel: (base) => ({ ...base }),
  };

  const onClearRestaurants = () => {
    if (selectMyRestaurantRef?.current) {
      selectMyRestaurantRef.current.clearValue();
    }
  };

  const setDateOpen =
    (type, checked = false) =>
    () => {
      type === "start_date"
        ? setIsStartDateOpen(checked)
        : setIsEndDateOpen(checked);
    };

  const onClearMeals = () => {
    setSelectedOption([]);
  };

  const onClearMealTypes = () => {
    setformData({ ...formData, mealTypes: {} });
  };

  const onMealSelect = () => (v) => setSelectedOption([...v]);

  const onSaveFilter = () => {
    setSelectedFinanceFilterMeals(selectedOption);
    setSelectedFilterMyRestaurants(selectedMyRestaurants)
    setFilterStartEndDate(startEndDate)
    setFilterFormData(formData);
    updateFilterButton(true);
  }

  useEffect(() => {
    setSelectedMyRestaurants(selectedFilterMyRestaurants);
    setSelectedOption(selectedFinanceFilterMeals);
    setformData(filterFormData);
    if (filterStartEndDate && filterStartEndDate.start_date !== "" && filterStartEndDate.end_date !== "") {
      setStartEndDate(filterStartEndDate);
    } else {
      setStartEndDate(startEndDate);
    }
    onApply();
  }, [])

  return (
    isFilterShown && (
      <div className="rightcontent">
        <div className="card">
          <div className="card-body">
            <Row>
              <Col lg={4}>
                <h1>{t("Filters")}</h1>
              </Col>
              <Col lg={8}>
                <div
                  className="hide-filter"
                  onClick={() => setFilterShown(false)}
                >
                  {t("HideFilters")} <img src={DeleteIcon} alt="" />
                </div>
              </Col>
            </Row>

            <div className="financerightcontent-fltr">
              <div className="mb-5">
                <div className="fltr-heading">
                  <label>{t("Meals")}</label>
                  <button onClick={onClearMeals}>{t("Clear")}</button>
                </div>
                <SelectAsyncPaginate
                  dataField="is_external"
                  placeholder={t("SelectMeals")}
                  onChange={onMealSelect}
                  value={selectedOption}
                  count={selectedOption.length}
                  datasource={options}
                  isMulti
                  query="meals"
                  key="item_id"
                />
              </div>

              <div className="mb-5">
                <div className="fltr-heading">
                  <label>{t("MyRestaurants")}</label>
                  <button onClick={onClearRestaurants}>{t("Clear")}</button>
                </div>
                <Select
                  ref={selectMyRestaurantRef}
                  styles={colourStyles}
                  defaultValue={selectedMyRestaurants}
                  value={selectedMyRestaurants}
                  onChange={setSelectedMyRestaurants}
                  components={makeAnimated()}
                  options={resturantOptions.map((r) => ({
                    value: r.id,
                    label: r.name,
                  }))}
                  isMulti
                  isSearchable
                  placeholder={t("SelectRestaurants")}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 4,
                    colors: {
                      ...theme.colors,
                      danger: "#fff",
                      dangerLight: "hsl(53deg 2% 73%)",
                    },
                  })}
                />
              </div>


              <div className="mb-5">
                <div className="fltr-heading">
                  <label>{t("Date")}</label>
                  <button onClick={onClearDates}>{t("Clear")}</button>
                </div>
                <div className="d-flex datepicker-wrapper">
                  <span>{t("Start")}</span>
                  <div className="datepicker-wrapper-img">
                    <DatePicker
                      selected={startEndDate.start_date}
                      dateFormat="dd MMM yyyy"
                      placeholderText={t("Start date")}
                      onChange={onDateChange("start_date")}
                      className="date-picker"
                      open={isStartDateOpen}
                      onClickOutside={setDateOpen("start_date")}
                      onClick={setDateOpen("start_date", true)}
                    />
                    <img
                      src={CalendarIcon}
                      onClick={setDateOpen("start_date", !isStartDateOpen)}
                      alt=""
                    />
                  </div>
                </div>
                <hr className="hr-separator" />
                <div className="d-flex datepicker-wrapper">
                  <span>{t("End")}&nbsp;&nbsp;</span>
                  <div className="datepicker-wrapper-img">
                    <DatePicker
                      selected={startEndDate.end_date}
                      dateFormat="dd MMM yyyy"
                      placeholderText={t("End date")}
                      onChange={onDateChange("end_date")}
                      className="date-picker"
                      open={isEndDateOpen}
                      onClickOutside={setDateOpen("end_date")}
                      onClick={setDateOpen("end_date", true)}
                    />
                    <img
                      src={CalendarIcon}
                      onClick={setDateOpen("end_date", !isEndDateOpen)}
                      alt=""
                    />
                  </div>
                </div>
              </div>

              <div className="mb-5">
                <div className="fltr-heading">
                  <label>{t("TransationType")}</label>
                  <button onClick={onClearTransitions}>{t("Clear")}</button>
                </div>
                {TRANSITIONS_CHECKBOXES.map((check, i) => (
                  <Fragment key={i}>
                    {customCheckbox({
                      name: check.name,
                      label: t(check.label),
                      type: "service_type",
                      checked: formData.service_type === check.name,
                    })}
                  </Fragment>
                ))}
              </div>

             {
              selectedRestaurantId !== "" &&
              <div>
                <div className="fltr-heading">
                  <label>{t("Status")}</label>
                  <button onClick={onClearStatus}>{t("Clear")}</button>
                </div>
                {STATUS_CHECKBOXES.map((check, i) => (
                  <Fragment key={i}>
                    {customCheckbox({
                      name: check.name,
                      label: t(check.label),
                      checked: formData[check.name] || false,
                    })}
                  </Fragment>
                ))}
              </div>
            }
              {/* <div className="mt-4">
                <div className="fltr-heading">
                  <label>{t("MealTypes")}</label>
                  <button onClick={onClearMealTypes}>{t("Clear")}</button>
                </div>
                {MEAL_CATEGORIES.slice(1).map((check, i) => {
                  const label = t(check.label);
                  return (
                    <Fragment key={i}>
                      {customCheckbox({
                        name: check.id,
                        label,
                        checked: formData?.["mealTypes"]?.[check.id] || false,
                        type: "mealTypes",
                      })}
                    </Fragment>
                  );
                })}
              </div> */}
            </div>

            <div className="action-btns"
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column"
              }}>
              <div
                style={{
                  marginBottom: "7px"
                }}
              >
                <button onClick={onSaveFilter} className="close-btn me-3" style={{
                  width: '100%',
                  color : isFilterActive ? "#8b8f94" : "#6353ea"
                }}>
                  {t("FixFilters")}
                </button>
              </div>
              <div style={{
                display: "flex"
              }}>
                <button onClick={onClearAll} className="close-btn me-3">
                  <img src={CloseIcon} alt="" />
                  {t("Clear")}
                </button>
                <button onClick={onApply} className="apply-btn">
                  {t("Apply")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
}

export default RightSide;
