import React from "react";
import PropTypes from "prop-types";
import {
  AsyncPaginate,
  reduceGroupedOptions,
} from "react-select-async-paginate";
import { useTranslation } from "react-i18next";

import request from "services/request";
import { useUserData } from "contexts/AuthContextManagement";
import { ITEMS_PER_PAGE } from "common/constants";
import { InputProps, MultiValueGenericProps, components } from "react-select";
import { getResponse } from "common/utils";

const colourStyles = {
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? undefined
        : isSelected
        ? data.color
        : isFocused
        ? "#F3F4FB"
        : undefined,
      color: isDisabled ? "#ccc" : isSelected ? "black" : "black",
    };
  },
  menuPortal: (provided) => ({ ...provided, zIndex: 99999 }),
  menu: (provided) => ({ ...provided, zIndex: 99999 }),
  multiValueLabel: (base) => ({ ...base }),
};

type SelectAsyncPaginateProps = {
  datasource?: Array<any>;
  query: string;
  mapper?: Function;
  onChange: Function;
  dataField: string;
  value: string;
  placeholder: string;
  isMulti?: boolean;
  isExternal?: boolean;
  multiOptions?: Array<any>;
  count?: number;
  isMyMeals?: boolean;
  isDisabled?: boolean;
  isEvent?: boolean
};

const CustomInput = (inputProps: any) => {
  const count = (inputProps.selectProps?.value as any[] ?? []).length

  if (count <= 2) {
    return <components.Input {...inputProps}></components.Input>;
  }

  return (
      <span style={{ display: "flex", alignItems:"center", color: "black" }}>
        <span>{`${count} items selected`}</span>
        <components.Input {...inputProps}></components.Input>
      </span>
  );
};

const CustomOption = ({ innerProps, label, isSelected }) => (
    <div {...innerProps} style={{ color: "black", marginLeft: "5px", cursor: "pointer" }}>
      <input
        style={{ marginRight: "10px" }}
        type="checkbox"
        checked={isSelected}
        onChange={() => null}
      />
      {label}
    </div>
);

const SelectAsyncPaginate = (props: SelectAsyncPaginateProps) => {
  const { t } = useTranslation();
  const { selectedRestaurantId, hasRetaurants, isRestaurantLoaded } =
    useUserData();
  
  const loadOptions = async (
    searchQuery,
    loadedOptions,
    { page }
    ): Promise<any> => {
    if (isRestaurantLoaded && !hasRetaurants) {
      return {
        options: props.datasource?.map(i => {
          return {
            ...i,
            id: i.item_id
          }
        }),
        hasMore: false,
        additional: { page: 1 },
      };
    }

    if (!selectedRestaurantId) {
      return { options: [], hasMore: false, additional: { page: 1 } };
    }

    const payload = {
      restaurant_id: selectedRestaurantId,
      limit: ITEMS_PER_PAGE,
      page,
      ...(props.dataField === "meals" && { is_external: props.isExternal }),
      ...(props.dataField === "is_external" && {is_external: true}),
      ...(searchQuery && { search: searchQuery }),
    };
    let response = await request.get(props.query, payload, true, false);
    
    let optionsObj = {
      options: props.mapper
        ? props.mapper(response[props.query])
        : getResponse(response, props.query),
      ...(props.multiOptions && {
        options: [{ label: "Meals", options: response[props.query] }],
      }),
      ...(payload.page === 1 &&
        props.multiOptions && {
          options: [
            { label: "Meal Lists", options: props.multiOptions },
            { label: t("Meals"), options: response[props.query] },
          ],
        }),
      hasMore: response.total_pages > response.page,
      additional: { page: response.page + 1 },
    };
    if(props.isMyMeals) {
      return {...optionsObj, options: optionsObj.options.map(i => {
        return {
          ...i,
          // name: i.is_external ? i.name : i.name + " - " +  t("Recipe")
          name: i.name
        }
      })}
    }
    return optionsObj
  };

  const onChange = (option) => {
    if (typeof props.onChange === "function") {
      props.onChange(props.dataField)(option);
    }
  };

  const additionalProps = props.isMulti ? { styles: {
    ...colourStyles,
    multiValue: (base) => ({
      ...base,
      display: props.count && props.count > 2 ? `none` : `flex`,
    })
  }, components: {
      Input: CustomInput,
      Option: CustomOption,
  } 
  } : {styles: {...colourStyles}}; 

  return (
    <AsyncPaginate
      menuPortalTarget={document.body}
      menuPosition={"fixed"}  
      value={props.value}
      key={props.value}
      //@ts-ignore
      loadOptions={loadOptions}
      getOptionValue={(option) => (option as any)?.id}
      getOptionLabel={(option) => (option as any)?.name}
      onChange={onChange}
      isSearchable={true}
      {...additionalProps}
      isDisabled={props.isDisabled}
      placeholder={props.placeholder}
      additional={{ page: 1 }}
      debounceTimeout={300}
      closeMenuOnSelect={props.isMulti ? false : true}
      hideSelectedOptions={false}
      isMulti={props.isMulti || false}
      {...(props.multiOptions && { reduceOptions: reduceGroupedOptions })}
      />
  );
};

SelectAsyncPaginate.propTypes = {
  dataField: PropTypes.string.isRequired,
  value: PropTypes.any,
  onChange: PropTypes.func,
  query: PropTypes.string,
  mapper: PropTypes.func,
  key: PropTypes.string,
  placeholder: PropTypes.string,
  isMulti: PropTypes.bool,
  isExternal: PropTypes.bool
};

export default SelectAsyncPaginate;
