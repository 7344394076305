import { TIMES_CHECKBOXES } from "common/constants";

export const getTableColumns = (t, currencySymbol, isCompetitiveTab = true) => [
  {
    dataField: "name",
    caption: isCompetitiveTab ? t("Competitors") : t('MyRestaurants'),
    style: { width: "150px" },
    headerStyle: { width: "150px" },
  },
  {
    dataField: "occupancy",
    caption: t("Occupancy"),
    className: "text-center",
    headerClassName: "text-center",
  },
  {
    dataField: "occupancy_percentage",
    caption: `${t("Occupancy")} (%)`,
    className: "text-center",
    headerClassName: "text-center",
  },
  {
    dataField: "revenue",
    caption: `${t("Revenues")} (${currencySymbol})`,
    className: "text-center",
    headerClassName: "text-center",
  },
  {
    dataField: "benefit",
    caption: `${t("Benefits")} (${currencySymbol})`,
    className: "text-center",
    headerClassName: "text-center",
  },
];

export const getTimeRange = (formData) => {
  let time_range = [];
  TIMES_CHECKBOXES.forEach((data) => {
    if (data.name in formData && formData[data.name]) {
      time_range.push(data.time);
    }
  });
  return time_range;
};