import React, { useState, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { Row, Col, Card } from "react-bootstrap";
import { set } from "lodash";
import { useTranslation } from "react-i18next";

import request from "services/request";
import CustomTable from "components/customTable/index.tsx";
import { cloneDeep } from "common/utils.ts";
import useCurrencySymbol from "customHooks/useCurrencySymbol";
import SortFilter, { sortTableData } from "components/customTable/sortFilter";
import { DEFAULT_ERROR_MESSAGE } from "common/constants";
import { useLoading } from "contexts/LoadingContextManagement";

import DonationService from "./service";

const getTableColumns = (t, currencySymbol) => [
  {
    dataField: "name",
    caption: t(" Charities"),
    className: "fw-bold",
    style: { height: "60px" },
    headerStyle: { height: "60px" },
    type: "string",
  },
  {
    caption: t("Location"),
    dataField: "address",
    type: "string",
    className: "text-center",
    headerClassName: "text-center",
  },
  {
    // dataField: "pickup_date",
    caption: t("Pick up date"),
    className: "text-center",
    headerClassName: "text-center",
    type: "datapicker",
  },
  {
    dataField: "donation.past_donation",
    caption: `${t("Past donation value")} (${currencySymbol})`,
    className: "text-center",
    headerClassName: "text-center",
    type: "string",
  },
];

function CharityDonation(props) {
  const history = useHistory();
  const { t } = useTranslation();
  const { currencySymbol, currencyNameSymbol } = useCurrencySymbol();
  const [fd, setfd] = useState({ sortbyOrder: "desc" });
  const [filteredTableData, setFilteredTableData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const { setLoading, setError } = useLoading();

  const tableColumns = useMemo(
    () => getTableColumns(t, currencySymbol),
    [t, currencySymbol]
  );
  /**
   * hook to be called when this component loads
   */
  useEffect(() => {
    const itemsByProvider = DonationService.getSelectedItems();
    if (itemsByProvider?.length) {
      fetchAndSetDonationStock();
      return;
    }
    history.push({ pathname: "/donation" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchAndSetDonationStock = async () => {
    try {
      const result = await getDonationStock();
      let now = new Date();
      result.charities.forEach((charity) => {
        charity.pickup_date = now.toISOString();
      });

      setFilteredTableData(result.charities);
      setPageCount(result.total_pages);
      setCurrentPage(result.page - 1);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setError(DEFAULT_ERROR_MESSAGE);
    }
  };

  const getDonationStock = (page = 1) => {
    return request.get(`charities`);
  };

  /**
   * Total donation value
   */
  const totoalDonationValue = () => {
    let total_sum = 0;
    const donationData = DonationService.getSelectedItems();
    donationData.forEach((value) => {
      total_sum = total_sum + value.unit_price * value.qty_to_donate;
    });
    return total_sum;
  };

  /**
   * Delete rows from the table & update the state
   */
  const deleteRows = async () => {
    setSelectedRows(() => []);
    let filteredData = filteredTableData.filter(
      ({ id }) => !selectedRows.includes(id)
    );
    DonationService.setSelectedItems(filteredData);
    setFilteredTableData(() => filteredData);
  };

  const selectChange =
    (it) =>
    ({ target: { checked } }) => {
      if (checked) {
        DonationService.setSelectedCharity(it);
        setSelectedRows(() => [it.id]);
      } else {
        DonationService.setSelectedCharity({});
        setSelectedRows([]);
      }
    };

  const selectAllRows = ({ target: { checked } }) => {
    setSelectedRows(() => []);
  };

  const rows = sortTableData(fd, tableColumns, filteredTableData);

  const donateStock = () => {
    if (selectedRows.length != 0) {
      history.push("/donation/details");
    }
  };

  const onPickupDateChange = (it) => (e) => {
    const newTableData = cloneDeep(filteredTableData);
    const isExist = newTableData.find((f) => f.id === it.id);

    if (isExist) {
      set(isExist, "pickup_date", e.toISOString());
      isExist.state = "update";
      setFilteredTableData(newTableData);

      let selectedCharity = DonationService.getSelectedCharity();
      if (selectedCharity) {
        const isExist = newTableData.find((f) => f.id === selectedCharity.id);
        if (isExist) {
          DonationService.setSelectedCharity(isExist);
        }
      }
    }
  };

  return (
    <div className="wrappers suggested-orders-container review-donation">
      <section className={`maincontent h-100 maincontent-collapsed container`}>
        <Card className="p-5 order-container card-height">
          <Row className="mb-4">
            <label
              className="order-back back"
              onClick={() => props.history.goBack()}
            >
              {"<"} {t("Back")}
            </label>
          </Row>
          <Row>
            <h3 className="heading-text">Select a charity</h3>
          </Row>
          <Row>
            <div className="d-flex ro">
              <Col className="sort-container d-flex justify-content-end">
                <SortFilter
                  cols={tableColumns}
                  fd={fd}
                  setfd={setfd}
                  rootClassName="sort-filter"
                />
              </Col>
            </div>
          </Row>
          <div className="review-donation-table">
            <CustomTable
              columns={tableColumns}
              data={rows}
              selectChange={selectChange}
              selectedProducts={selectedRows}
              selectAllProducts={selectAllRows}
              onPickupDateChange={onPickupDateChange}
            />
          </div>
          <Row>
            {Boolean(selectedRows.length) && (
              <div className="d-flex justify-content-end mt-3 mb-2 selected-prod-div">
                <div className="d-flex flex-column me-3">
                  <label>Total donation value</label>
                  <label className="d-flex  justify-content-end">
                    {totoalDonationValue()} {currencyNameSymbol}
                  </label>
                </div>
                <button className="float-end donate-btn" onClick={donateStock}>
                  Donate
                </button>
              </div>
            )}
          </Row>
        </Card>
      </section>
    </div>
  );
}

export default CharityDonation;
