import React from "react";

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { BrowserRouter } from "react-router-dom";


import "./payment.css";
import PaymentElement from "components/paymentElements";
import CardForm from "components/CardForm.js";
import IbanForm from "components/IbanFrom";

const stripePromise = loadStripe(`${process.env.REACT_APP_FULLSOON_STRIPE_KEY}`);

const PAYMENT_ELEMENTS = [
  {
    path: "/iban-element",
    label: "IBAN",
    component: IbanForm
  },
  {
    path: "/card-element",
    label: "Credit Card",
    component: CardForm
  }
];

const RightSide = () => {
  return (
    <BrowserRouter>
      <Elements stripe={stripePromise}>
        <PaymentElement payment_elements={PAYMENT_ELEMENTS} />
      </Elements>
    </BrowserRouter>
  );
};

export default RightSide
