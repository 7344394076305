import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import Stock from "./stock/index";
import Sale from "./sale/sales";
import Order from "./order/index";

import "./index.scss";

function Labo() {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState('stockProduct');

  return (
    <>
      <div className="divider">
        <ul className="navbtns mb-0">
          <li className={activeTab === 'stockProduct' ? "active" : ""}>
            <button
              className={`nav-link ${activeTab === 'stockProduct' ? "active" : ""}`}
              onClick={() => setActiveTab('stockProduct')}
            >
              {t("StockProduct")}
            </button>
          </li>
          <li className={activeTab === 'salePerRestaurant' ? "active" : ""}>
            <button
                className={`nav-link ${activeTab === 'salePerRestaurant' ? "active" : ""}`}
                onClick={() => setActiveTab('salePerRestaurant')}
            >
              {t("OrderPerRestaurant")}
            </button>
          </li>
          <li className={activeTab === 'order' ? "active" : ""}>
            <button
               className={`nav-link ${activeTab === 'order' ? "active" : ""}`}
               onClick={() => setActiveTab('order')}
            >
              {t("Order")}
            </button>
          </li>
        </ul>
      </div>
      <div className="after-divider-container">
        {activeTab === 'stockProduct' && <Stock />}
        {activeTab === 'salePerRestaurant' && <Sale />}
        {activeTab === 'order' && <Order />}
      </div>
    </>
  );
}

export default Labo;