import React, { useState } from "react";
import moment from "moment";

import LeftSide from "./leftSide";
import RightSide from "./rightSide";

import "./index.scss";

function Event() {
  const [formData, setformData] = useState({
    start_date: moment().format("YYYY-MM-DD"),
    end_date: moment().format("YYYY-MM-DD")
  });

  const onApply = (params) => {
    setformData({ ...params });
  };

  return (
    <div className="events">
      <LeftSide formData={formData} />
      <RightSide onApply={onApply} formData={formData}/>
    </div>
  );
}

export default Event;
