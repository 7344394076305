import React from "react";
import { Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import BeverageIcon from "../../assets/images/beverage.png";
import DessertIcon from "../../assets/images/dessert.png";

function TopFlop({ isTop, setIsTop, heading, isSecondColumn, topFlopLoading, top, flop }) {
  const { t } = useTranslation();

  const topAndFlopView = (data, i) => (
    <div key={i} className="d-flex media top-flop-item">
      <div className="flex-shrink-0">
        <span className="boxicon">
          {data.type === 'drinks' ? (
            <img src={BeverageIcon} alt="" />
          ) : (
            <img src={DessertIcon} alt="" />
          )}
        </span>
      </div>
      <div className="flex-grow-1 ms-3">
        <h2>
          <strong>{data.sales}</strong>
        </h2>
        <p className="text-muted">{data.meal}</p>
      </div>
    </div>
  );

  return (
    <div className="card cardlist mb-0">
      <div className="card-header d-flex align-items-center justify-content-between flex-column">
        <h2 className="w-100">{heading}</h2>
        <ul className=" navbtns w-100">
          <li className={`${isTop ? "active" : ""}`}>
            <button
              className={`nav-link ${isTop ? "active" : ""}`}
              onClick={() => setIsTop(true)}
            >
              {t("Top")}
            </button>
          </li>
          <li className={`${!isTop ? "active" : ""}`}>
            <button
              className={`nav-link ${!isTop ? "active" : ""}`}
              onClick={() => setIsTop(false)}
            >
              {t("Flop")}
            </button>
          </li>
        </ul>
        {!topFlopLoading && (
          <div className="d-flex w-100 top-flop-container">
            <div className="top-flop w-100">
              {isTop
                ? top.map((data, i) => topAndFlopView(data, i))
                : flop.map((data, i) => topAndFlopView(data, i))}
            </div>
          </div>
        )}
        {topFlopLoading && (
          <div className="w-100 d-flex justify-content-center card-spinner-container">
            <Spinner animation="border" variant="primary" />
          </div>
        )}
      </div>
    </div>
  );
}

export default TopFlop;
