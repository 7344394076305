import React, { useEffect } from "react";
import { Row } from "react-bootstrap";
import LeftSide from "./leftSide";
import RightSide from "./rightSide";
import { useUserData } from "contexts/AuthContextManagement";
import storage from "services/storage";

function PricingCard() {
  const { subscription, setSubscription } = useUserData();

  useEffect(() => {
    if(!subscription?.status){
      setSubscription(JSON.parse(storage.getItem("subscription")))
    }
  },[])
  return (
    <Row className="signin-container" style={{backgroundColor: "#f8f9fd"}}>
       {subscription?.status !== 'active' && <LeftSide  />}
      <RightSide  />
    </Row>
  );
}

export default PricingCard;
