import React, { useRef, useState, useEffect } from "react";
import { get } from "lodash";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Row } from "react-bootstrap";
import CustomTable from "components/customTable";
import ReactPaginate from "react-paginate";
import { useUserData } from "contexts/AuthContextManagement";
import {updatedIceHockeyData, updatedIceFootballData, updatedBasketballData} from './utils';

import "./index.scss";
function EventTable({
  eventTypeDefaultColumn,
  columns,
  data,
  selectChange,
  selectedProducts = [],
  selectAllProducts,
  onRowDoubleClick = () => { },
  tableName,
  selectedRowType,
  currentPage,
  onPageChange,
  pageCount
}) {
  const { t } = useTranslation();
  const headRef = useRef(null);
  const [dummyEvents, setDummyEvents] = useState([])
  const { hasRetaurants } = useUserData();

  useEffect(() => {
    if (!hasRetaurants) {
      if (selectedRowType === "Football") {
        setDummyEvents(updatedIceFootballData);
      } else if (selectedRowType === "IceHockey") {
        setDummyEvents(updatedIceHockeyData);
      } else if (selectedRowType === "Basketball") {
        setDummyEvents(updatedBasketballData);
      }
    } 
    else {
      setDummyEvents([]);
    }
  }, [selectedRowType, hasRetaurants]);

  const tableColumnsDefault = [
    {   
      dataField: "name",
      caption: t("Sports"),
      className: "fw-bold",
      style: { width: "150px" },
      headerStyle: { width: "150px" },
      type: "string",
    }
  ];

  const renderTooltip = (value) => (props) =>
  (
    <Tooltip id="button-tooltip" {...props}>
      {value}
    </Tooltip>
  );

  const renderRow = (it, ii) => (
    <tr
      key={ii}
      id={`${it?.id}`}
      onDoubleClick={() =>
        onRowDoubleClick(it?.name)
      }
    >
      {tableColumnsDefault.map((c, i) => (
        <td
          key={i}
          className={`${c.className ?? ""} ${(c.isLower && c?.isLower(it[c.dataField])) ?? ""
            } ${(c.isHigher && c?.isHigher(it[c.dataField])) ?? ""}`}
          style={{
            fontSize: tableName === "stocks" ? "17px" : "14px",
            height: tableName === "stocks" ? "70px" : "40px",
            whiteSpace: c.dataField === "name" ? "initial" : "",
            ...c.style,
            width:
              i === 0 && it.prediction && headRef.current
                ? headRef.current.offsetWidth - 1
                : c.style?.width,
          }}
        >
          <div className="d-flex">
            {get(it, c.dataField) && (
              <OverlayTrigger
                placement="top"
                overlay={renderTooltip(get(it, c.dataField))}
              >
                <span>
                  {get(it, c.dataField)}
                </span>
              </OverlayTrigger>
            )}
          </div>
        </td>
      ))}
    </tr>
  );
  
  return (
    <div className="row custom-table h-100">
      <div className="col-lg-12 h-100">
        <div className="tablescroll">
          <table className="table">
            <tbody>
              {eventTypeDefaultColumn?.map((it, ii) => {
                return (
                  <React.Fragment key={ii}>
                    {renderRow(it, ii)}
                    {selectedRowType === it.name && (data.length || dummyEvents.length)? (
                      <>
                        <CustomTable
                          columns={columns}
                          data={hasRetaurants ? data : dummyEvents}
                          selectChange={selectChange}
                          selectedProducts={selectedProducts}
                          selectAllProducts={selectAllProducts}
                        />
                        <Row>
                          <div className="d-flex justify-content-end mt-2">
                            <ReactPaginate
                              nextLabel={`${t("Next")}   >`}
                              forcePage={currentPage}
                              onPageChange={onPageChange}
                              pageRangeDisplayed={3}
                              marginPagesDisplayed={2}
                              pageCount={pageCount}
                              previousLabel={`<   ${t("Back")}`}
                              pageClassName="page-item"
                              pageLinkClassName="page-link"
                              previousClassName="page-item"
                              previousLinkClassName="page-link"
                              nextClassName="page-item"
                              nextLinkClassName="page-link"
                              breakLabel="..."
                              breakClassName="page-item"
                              breakLinkClassName="page-link"
                              containerClassName="pagination"
                              activeClassName="active pagination-page-active"
                              renderOnZeroPageCount={null}
                            />
                          </div>
                        </Row>
                      </>
                    ) : null}
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

EventTable.propTypes = {
  // columns: PropTypes.array,
};

export default EventTable;
