import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Modal, Button, Row, Col, Container, Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import CustomTable from "components/customTable";
import { RESTAURANTS_TYPES_LIST } from "common/constants";
import { useLoading } from "contexts/LoadingContextManagement";

const cols = [
  { label: "Competitors", dataField: "order", type: "string", active: false },
  { label: "Type", dataField: "type", type: "string", active: true },
  { label: "Location", dataField: "location", type: "string", active: true },
];

function AddCompetitiveModal(props) {
  const { t } = useTranslation();
  const {
    restaurants,
    selectedCompetator,
    selectedProducts,
    setSelectedCompetator,
    setSelectedProducts,
    setSearch
  } = props;
  const [tableColumns, setTableColumns] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [filteredTableData, setFilteredTableData] = useState([]);
  const { setError } = useLoading();

  const intialState = {
    location: [],
    type: ["All Type"].concat(RESTAURANTS_TYPES_LIST),
    selectedLocation: "All Location",
    selectedType: "All Type",
  };
  const [state, setState] = useState(intialState);

  useEffect(() => {
    var city = [];
    restaurants.forEach((r) => {
      if (r.city !== "" && r.city !== null) {
        city.push(r.city);
      }
    });
    var uniqueCity = Array.from(new Set(city));
    setState({ ...state, location: ["All Location"].concat(uniqueCity) });
    generateTableData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restaurants]);

  const confirmBtnClick = async () => {
    // if (selectedCompetator.length > 5) {
    //   setError(t("Maximum 5 Competitors only"));
    //   return;
    // }
    const competitorIds = [];
    selectedCompetator.forEach((restaurant) => {
      competitorIds.push(restaurant.id);
    });
    props.saveCompetitor(competitorIds);
  };

  const selectChange =
    (it) =>
    ({ target: { checked } }) => {
      const newSelectedProducts = JSON.parse(JSON.stringify(selectedProducts));
      const newSelectedCompetator = JSON.parse(
        JSON.stringify(selectedCompetator)
      );
      if (checked) {
        setSelectedProducts(() => [...newSelectedProducts, it.id]);
        setSelectedCompetator(() => [...newSelectedCompetator, it]);
      } else {
        setSelectedProducts(() =>
          newSelectedProducts.filter((p) => p !== it.id)
        );
        setSelectedCompetator(() =>
          newSelectedCompetator.filter((p) => p.id !== it.id)
        );
      }
    };

  const selectAllProducts = ({ target: { checked } }) => {
    if (checked) {
      setSelectedProducts(() => [
        ...filteredTableData.map((obj) => {
          return obj.id;
        }),
      ]);
      setSelectedCompetator(() => [
        ...filteredTableData.map((obj) => {
          return obj;
        }),
      ]);
    } else {
      setSelectedProducts(() => []);
      setSelectedCompetator(() => []);
    }
  };

  const tableInputChange =
    (it) =>
    ({ target: { name, value } }) => {
      const newFilteredData = JSON.parse(JSON.stringify(filteredTableData));
      const isExist = newFilteredData.find((f) => f.id === it.id);
      if (isExist) {
        isExist[name] = value;
        setFilteredTableData(newFilteredData);
      }
    };

  const filterTableData = (location, type) => {
    var _tableData = tableData.filter((data) => {
      return (
        (location === data.location || location === "All Location") &&
        (type === data.type || type === "All Type")
      );
    });
    setFilteredTableData(_tableData);
  };

  const generateTableData = () => {
    const { data } = generateMyOrderTableRawData();
    const newRawTableData = [
      {
        dataField: "name",
        caption: t("Competitors"),
        className: "",
        style: { width: "100px" },
        headerStyle: { width: "100px" },
      },
      ...cols
        .filter((c) => c.active)
        .map(({ label, dataField, ...rest }) => ({
          ...rest,
          caption: t(label),
          dataField,
          className: `text-center ${
            dataField === "qty_to_buy" ? "text-purple" : ""
          }`,
          headerClassName: `text-center ${
            dataField === "qty_to_buy" ? "text-purple" : ""
          }`,
        })),
    ];
    setTableColumns(newRawTableData);
    setTableData(() => [...data]);
    setFilteredTableData(() => [...data]);
  };

  const generateMyOrderTableRawData = () => {
    const newData = [];
    restaurants.forEach((restaurant) => {
      newData.push({
        name: restaurant.name,
        type: restaurant.type,
        location: restaurant.city,
        id: restaurant.id,
      });
    });

    return { data: newData };
  };

  const handleLocationSelect = (eventKey, event) => {
    var location = eventKey;
    setState({ ...state, selectedLocation: location });
    filterTableData(location, state.selectedType);
  };

  const handleTypeSelect = (eventKey, event) => {
    var type = eventKey;
    setState({ ...state, selectedType: type });
    filterTableData(state.selectedLocation, type);
  };

  const onSearchChange = (ev) => {
    setSearch(ev.target.value);
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header
        className="add-restaurants-modal-header"
        style={{ paddingBottom: "5px" }}
        closeButton
      >
        <Modal.Title
          className="add-restaurants-modal-title"
          id="contained-modal-title-vcenter"
        >
          {t("AddCompetitors")}
        </Modal.Title>
      </Modal.Header>
      <div className="modal-sub-title">
        {t("SelectYourCompetitors")}.
      </div>

      <Modal.Body className="pe-0 ps-0">
        <Container>
          <Row className="pe-2">
            <Col lg={6}>
              <input
                type="search"
                className="search-input me-3"
                placeholder={t("Search")}
                onChange={onSearchChange}
              />
            </Col>
            <Col lg={{ span: 3 }}>
              <Row className="pe-3">
                <Dropdown className="ps-0 pe-0" onSelect={handleLocationSelect}>
                  <Dropdown.Toggle
                    variant="button"
                    className="dropdown-toggle btn-icon custom-dropdown"
                  >
                    {state.selectedLocation}
                  </Dropdown.Toggle>

                  <Dropdown.Menu className=" ps-0">
                    {state.location.map((data, index) => (
                      <Dropdown.Item key={index} eventKey={data}>
                        {data}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </Row>
            </Col>
            <Col lg={{ span: 3 }}>
              <Row>
                <Dropdown className="pe-0 ps-0" onSelect={handleTypeSelect}>
                  <Dropdown.Toggle
                    variant="button"
                    className="dropdown-toggle btn-icon custom-dropdown"
                  >
                    {state.selectedType}
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="pe-3 type-dropdown-menu">
                    {state.type.map((data, index) => (
                      <Dropdown.Item
                        key={index}
                        className="type-dropdown-menu-item"
                        eventKey={data}
                      >
                        {data}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </Row>
            </Col>
          </Row>
          <Row>
            <div className="mt-3 add-competitive-table">
              <div className="gcontainer">
                <CustomTable
                  columns={tableColumns}
                  data={filteredTableData}
                  selectChange={selectChange}
                  selectedProducts={selectedProducts}
                  selectAllProducts={selectAllProducts}
                  onInputChange={tableInputChange}
                />
              </div>
            </div>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer className="competitive-modal-footer">
        <Button
          variant="primary"
          onClick={() => confirmBtnClick()}
          className={
            selectedCompetator.length > 0
              ? "competitive-btn"
              : "competitive-btn-disabled"
          }
          disabled={selectedCompetator.length < 1}
        >
          {t("Confirm")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

AddCompetitiveModal.propTypes = {
  saveCompetitor: PropTypes.func.isRequired,
};

export default AddCompetitiveModal;
