import React, {useState} from "react";
import moment from "moment";
import "moment-timezone";

import ForecastPage from "./forecast";
import LeftSide from "./leftSide";
import RightSide from "./rightSide";

import "./index.scss";

function Forecast() {
  const currentDate = moment();
  const [weekOffset, setWeekOffset] = useState(0); // Tracks the number of weeks from the current week
  const [activeWeek, setActiveWeek] = useState('current');

  const calculateWeekDates = (offset) => {
    const startOfCurrentWeek = currentDate.clone().subtract(3, 'days');
    const startOfTargetWeek = startOfCurrentWeek.clone().add(offset * 7, 'days');
    const endOfTargetWeek = startOfTargetWeek.clone().add(6, 'days');

    return {
      start_date: startOfTargetWeek.format('YYYY-MM-DD'),
      end_date: endOfTargetWeek.format('YYYY-MM-DD'),
    };
  };

  const [formData, setFormData] = useState(calculateWeekDates(0));

  const handleCurrentWeek = () => {
    setWeekOffset(0);
    setFormData(calculateWeekDates(0));
    setActiveWeek('current');
  };

  const handleNextWeek = () => {
    if (weekOffset < 2) { // Allow moving to the next week only twice
      const nextWeekOffset = weekOffset + 1;
      setWeekOffset(nextWeekOffset);
      setFormData(calculateWeekDates(nextWeekOffset));
      setActiveWeek('next');
    }
  };

  const handlePreviousWeek = () => {
    const previousWeekOffset = weekOffset - 1;
    setWeekOffset(previousWeekOffset);
    setFormData(calculateWeekDates(previousWeekOffset));
    setActiveWeek('previous');
  };

  // const currentDate = moment().utc().format("YYYY-MM-DD");
  // const nextDate = moment().add(1, "days").utc().format("YYYY-MM-DD");

  return (
    <>
      <ForecastPage 
        formData={formData}
        onPreviousWeek={handlePreviousWeek}
        onCurrentWeek={handleCurrentWeek}
        onNextWeek={handleNextWeek}
        activeWeek={activeWeek}
        isNextDisabled={weekOffset >= 2}
      />
      {/* <LeftSide />
      <RightSide currentDate={currentDate} nextDate={nextDate} />  */}

    </>
  );
}

export default Forecast;
