import { forwardRef } from "react";
import { Dropdown } from "react-bootstrap";


function ColumnSelect({ title, onSelect, dropdownOptions }) {

  return (
    <Dropdown onSelect={onSelect}>
      <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
        {dropdownOptions[title]?dropdownOptions[title]:title}
      </Dropdown.Toggle>

      <Dropdown.Menu style={{width: "min-content"}}>
        {Object.keys(dropdownOptions).map((item, index) => (<Dropdown.Item key={index} eventKey={item}>{dropdownOptions[item]}</Dropdown.Item>))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default ColumnSelect;

const CustomToggle = forwardRef(({ children, onClick }, ref) => (
  <a
    href=""
    ref={ref}
    className="simple-anchor"
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    &#x25bc;
  </a>
));