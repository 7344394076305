/**
 * Service to share the data across the stock screen, like donation-page, review-donation
 * donation chairty, donation details
 */

class StockService {
  selectedItems = [];
  selectedCharity = {};

  setSelectedItems(items) {
    this.selectedItems = items;
  }

  getSelectedItems() {
    return this.selectedItems;
  }

  setSelectedCharity(item) {
    this.selectedCharity = item;
  }

  getSelectedCharity() {
    return this.selectedCharity;
  }

}

export default new StockService();
