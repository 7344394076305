import { cloneDeep } from "common/utils";
import moment from "moment";

export const RATING = [
  { label: "Revenue", value: "revenue" },
  { label: "Occupancy", value: "occupancy" },
  { label: "Specified Meals", value: "specified_meals" },
];


export function nearestMinutes(interval, someMoment) {
  const roundedMinutes =
    Math.round(someMoment.clone().minute() / interval) * interval;
  return someMoment.clone().minute(roundedMinutes).second(0);
}

export function nearestFutureMinutes(interval, someMoment) {
  const roundedMinutes = Math.ceil(someMoment.minute() / interval) * interval;
  return someMoment.clone().minute(roundedMinutes).second(0);
}

const now = moment();
const nearest5min = nearestMinutes(5, now);
const nearest15min = nearestMinutes(60, now);
const nearest60min = nearestFutureMinutes(60, now);
const nearestFuture5min = nearestFutureMinutes(90, now);

//console.log(nearest60min.format("LLLL"));

export const DAYS = [
  { label: "Monday", value: "monday", labelP: "M" },
  { label: "Tuesday", value: "tuesday", labelP: "T" },
  { label: "Wednesday", value: "wednesday", labelP: "W" },
  { label: "Thursday", value: "thursday", labelP: "T" },
  { label: "Friday", value: "friday", labelP: "F" },
  { label: "Saturday", value: "saturday", labelP: "S" },
  { label: "Sunday", value: "sunday", labelP: "S" },
];

export const MONTHS = [
  { label: "January", value: "january" },
  { label: "February", value: "february" },
  { label: "March", value: "march" },
  { label: "April", value: "april" },
  { label: "May", value: "may" },
  { label: "June", value: "june" },
  { label: "July", value: "july" },
  { label: "August", value: "august" },
  { label: "September", value: "septempber" },
  { label: "October", value: "october" },
  { label: "November", value: "november" },
  { label: "December", value: "december" },
];

export const DAY_TYPES = [
  { label: "First", value: "first" },
  { label: "Second", value: "second" },
  { label: "Third", value: "third" },
  { label: "Fourth", value: "fourth" },
  { label: "Last", value: "last" },
];

export const TIMES = [
  { label: "00:00", value: "00:00" },
  { label: "00:30", value: "00:30" },
  { label: "01:00", value: "01:00" },
  { label: "01:30", value: "01:30" },
  { label: "02:00", value: "02:00" },
  { label: "02:30", value: "02:30" },
  { label: "03:00", value: "03:00" },
  { label: "03:30", value: "03:30" },
  { label: "04:00", value: "04:00" },
  { label: "04:30", value: "04:30" },
  { label: "05:00", value: "05:00" },
  { label: "05:30", value: "05:30" },
  { label: "06:00", value: "06:00" },
  { label: "06:30", value: "06:30" },
  { label: "07:00", value: "07:00" },
  { label: "07:30", value: "07:30" },
  { label: "08:00", value: "08:00" },
  { label: "08:30", value: "08:30" },
  { label: "09:00", value: "09:00" },
  { label: "09:30", value: "09:30" },
  { label: "10:00", value: "10:00" },
  { label: "10:30", value: "10:30" },
  { label: "11:00", value: "11:00" },
  { label: "11:30", value: "11:30" },
  { label: "12:00", value: "12:00" },
  { label: "12:30", value: "12:30" },
  { label: "13:00", value: "13:00" },
  { label: "13:30", value: "13:30" },
  { label: "14:00", value: "14:00" },
  { label: "14:30", value: "14:30" },
  { label: "15:00", value: "15:00" },
  { label: "15:30", value: "15:30" },
  { label: "16:00", value: "16:00" },
  { label: "16:30", value: "16:30" },
  { label: "17:00", value: "17:00" },
  { label: "17:30", value: "17:30" },
  { label: "18:00", value: "18:00" },
  { label: "18:30", value: "18:30" },
  { label: "19:00", value: "19:00" },
  { label: "19:30", value: "19:30" },
  { label: "20:00", value: "20:00" },
  { label: "20:30", value: "20:30" },
  { label: "21:00", value: "21:00" },
  { label: "21:30", value: "21:30" },
  { label: "22:00", value: "22:00" },
  { label: "22:30", value: "22:30" },
  { label: "23:00", value: "23:00" },
  { label: "23:30", value: "23:30" },
];

// export const INITIAL_IMPACT = {
//   type: null,
  
// };


export const INITIAL_RECURRENCE = {
  type: null,
  number: 1,
  radioTypeMonth: "monthly_on_day",
  radioTypeYear: "yearly_on",
  weeklyDays: [],
  monthly_on_day: null,
  monthly_on_the: null,
  yearly_on: null,
  yearly_on_the: null,
};

export const RECURRENCE_TYPES_FIELDS = {
  DAILY: "daily",
  WEEKLY: "weekly",
  MONTHLY: "monthly",
  YEARLY: "yearly",
};

export const RECURRENCE_TYPES = [
  { label: "Does not repeat", value: null },
  {
    label: "Day",
    value: RECURRENCE_TYPES_FIELDS.DAILY,
  },
  { label: "Week", value: RECURRENCE_TYPES_FIELDS.WEEKLY },
  { label: "Month", value: RECURRENCE_TYPES_FIELDS.MONTHLY },
  { label: "Year", value: RECURRENCE_TYPES_FIELDS.YEARLY },
];

const getDailyText = (recurrence, t) => {

  const { number } = cloneDeep(recurrence);
  if (number == 0) {
    return "";
  }
  let string = t("Occurs every ");

  if (number > 1) {
    string += `${number} `;
  }

  string += `day${number > 1 ? "s" : ""}`;

  return string;
};

const getWeeklyText = (recurrence, t) => {
  console.log(recurrence);
  const { number, days } = cloneDeep(recurrence);

  if (!days || days?.length === 0 || number == 0) {
    return "";
  }

  // let string = "Occurs every ";
  let string = t("Occurs every ");

  if (number > 1) {
    string += `${number} `;
  }
  string += `week${number > 1 ? "s" : ""} on `;

  if (days.length === 1) {
    console.log(days[0]);
    string += ` ${DAYS.find((d) => d.value === days[0])?.label}`;
  }

  if (days.length > 1) {
    const lastEle = days.pop();

    string += days
      .map((e) => DAYS.find((d) => d.value === e)?.label)
      ?.join(", ");

    string += ` and ${DAYS.find((d) => d.value === lastEle)?.label}`;
  }

  return string;
};

const getMonthlyText = (recurrence, t) => {
  const { number, radioTypeMonth, monthly_on_day, monthly_on_the } =
    cloneDeep(recurrence);

  if (number == 0) {
    return "";
  }

  let string = t("Occurs every ");
  if (number > 1) {
    string += `${number} `;
  }

  string += `month${number > 1 ? "s" : ""} on `;

  if (radioTypeMonth === "monthly_on_day") {
    string += ` day ${monthly_on_day}`;
  }

  if (radioTypeMonth === "monthly_on_the" && monthly_on_the) {
    const { day_name = "", day_type = "" } = monthly_on_the;
    string += ` ${day_type} ${
      DAYS.find((d) => d.value === day_name)?.label ?? ""
    }`;
  }

  return string;
};

const getYearlyText = (recurrence, t) => {
  const { number, radioTypeYear, yearly_on, yearly_on_the } =
    cloneDeep(recurrence);

    let string = t("Occurs every ");
    if (number > 1) {
    string += `${number} `;
  }

  string += `year${number > 1 ? "s" : ""} on `;

  if (radioTypeYear === "yearly_on" && yearly_on) {
    const { month_name, day_number } = yearly_on;
    string += ` day ${day_number} of ${
      MONTHS.find((m) => m.value === month_name)?.label
    }`;
  }

  if (radioTypeYear === "yearly_on_the" && yearly_on_the) {
    const { day_name, day_type, month_name } = yearly_on_the;
    string += ` the ${day_type} ${
      DAYS.find((d) => d.value === day_name)?.label
    } of ${MONTHS.find((m) => m.value === month_name)?.label}`;
  }

  return string;
};

// export const getTextualRecurrence = (recurrence,t) => {
//   const { type } = cloneDeep(recurrence);
//   const textFuncs = {
//     [RECURRENCE_TYPES_FIELDS.DAILY]: getDailyText,
//     [RECURRENCE_TYPES_FIELDS.WEEKLY]: getWeeklyText,
//     [RECURRENCE_TYPES_FIELDS.MONTHLY]: getMonthlyText,
//     [RECURRENCE_TYPES_FIELDS.YEARLY]: getYearlyText,
//   };
//   // return textFuncs[type]?.(cloneDeep(recurrence));
//   return textFuncs[type]?.(cloneDeep(recurrence));
// };

export const getTextualRecurrence = (recurrence, t) => {
  const { type } = cloneDeep(recurrence);
  const textFuncs = {
    [RECURRENCE_TYPES_FIELDS.DAILY]: (r) => getDailyText(r, t),
    [RECURRENCE_TYPES_FIELDS.WEEKLY]: (r) => getWeeklyText(r, t),
    [RECURRENCE_TYPES_FIELDS.MONTHLY]: (r) => getMonthlyText(r, t),
    [RECURRENCE_TYPES_FIELDS.YEARLY]: (r) => getYearlyText(r, t),
  };
  return textFuncs[type]?.(cloneDeep(recurrence));
};

