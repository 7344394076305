import React from "react";
import { useHistory } from "react-router-dom";

import {
  Modal,
  Row,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { useUserData } from "contexts/AuthContextManagement";
import request from "services/request";

import storage from "services/storage";
import { toast } from "react-toastify";


function CustomModal({
  plan,
  onHide,
  isDelete,
  show
}) {
  const { t } = useTranslation();
  const { subscription, setSubscription } = useUserData();
  const history = useHistory();
  const user = JSON.parse(storage.getItem("user"))

  const subscriptionHandler =async () => {
    if(isDelete) {
      const res = await request.delete(`/subscription`);
      setSubscription({})
      storage.removeItem("plan");	
      storage.removeItem("subscription");
      history.push("/signin");
    }else {
      const res = await request.patch('/subscription', {plan: plan.name})
      const response = await res.json();
      if(res.ok) {
        storage.setItem("subscription", JSON.stringify({status: response.status, plan: response?.plan, features: response.features}))
        storage.setItem("user", JSON.stringify({...user, subscription: {status: response.status, plan: response?.plan, features: response.features}}))
        toast.success(t("Plan updated Successfully!"))
        window.location.reload()
      }
      onHide()
    }
	}

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      centered
      className="add-ingredient"
      backdropClassName="add-ingredient-backdrop"
    >
      <Modal.Header className="add-restaurants-modal-header" closeButton>
          <Modal.Title className="add-restaurants-modal-title">
           <h2 style={{fontSize: "30px"}}>{t(`${isDelete ? 'Cancel' : 'Update'} Subscription`)}</h2>
          </Modal.Title>
      </Modal.Header>

      <Modal.Body>
          <Row>
           {isDelete ? <p style={{fontSize: "17px"}}>{t("Would you like to cancel the subscription? By Cancelling you will not have access to Fullsoon's Data anymore.")}</p> : <p>{t("Would you like to update your subscription?")}</p>}
          </Row>
          <Row style={{display: "flex", justifyContent: "center"}}>
          {subscription?.status === "active" && <a href="#/" onClick={subscriptionHandler} style={{width: "50%", marginTop: "50px"}} className="btn btn-primary btn-mid">{t(`${isDelete ? 'Cancel' : 'Update'} Subscription`)}</a>}
          </Row>
      </Modal.Body>
    </Modal>
  );
}

export default CustomModal;
