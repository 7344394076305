import drinks from '../../assets/images/inventories/drinks.svg';
import fruites from '../../assets/images/inventories/fruites.svg';
import meet from '../../assets/images/inventories/meet.svg';
import milkProducts from '../../assets/images/inventories/milkProducts.svg';
import others from '../../assets/images/inventories/others.svg';
import pasta from '../../assets/images/inventories/pasta.svg';

export const INGREDIENT_CATEGORIES = [
    { id: "pastaAndRiceAndLegumes, breadsAndBakery", value: ["pastaAndRiceAndLegumes", "breadsAndBakery"], label: "Pasta, rice, bakery & else", imagePath : pasta, color :"#8232FF" },
    { id: "meatFish", value: ["meatFish"], label: "Meet & Fish", imagePath : meet, color : "#535355" },
    { id: "fruits", value: ["fruits","vegetables"], label: "Fruits and vegetables" , imagePath : fruites, color : "#9EDF5D"},
    { id: "milkProduct, eggs, sauces, cheese, oilsAndButter, fatsAndOils", value: ["milkProduct", "eggs", "sauces" ,"cheese", "oilsAndButter", "fatsAndOils"], label: "Milk product, eggs, cheese, sauces & oils" , imagePath : milkProducts, color : "#FFC700"},
    { id: "drinks", value: ["drinks"], label: "Drinks" , imagePath : drinks , color : "#DF5D5D"},
    { id: "packaging, cleaning", value: ["packaging", "cleaning", "others"], label: "Others (packaging, cleaning, else)", imagePath : others, color:"#F78600"}
  ];