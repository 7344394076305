import { useState, useEffect, Fragment, useMemo, useRef } from "react";
import Form from "react-bootstrap/Form";
import { Row, Col } from "react-bootstrap";
import DatePicker from "react-datepicker";
import moment from "moment";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { useTranslation } from "react-i18next";

import request from "services/request";
import { DEFAULT_ERROR_MESSAGE, TIMES_CHECKBOXES } from "common/constants";
import { useUserData } from "contexts/AuthContextManagement";
import { useFilterData } from "contexts/FilterContextManagment";
import { useLoading } from "contexts/LoadingContextManagement";

import CloseIcon from "../../assets/images/close.png";
import CalendarIcon from "../../assets/images/calendar_datepicker.png";
import DeleteIcon from "assets/images/icon/DELETE_WHITE.svg";
import "react-datepicker/dist/react-datepicker.css";
import { myResturants } from "views/occupancy/data";
import { DummyCompetitiveSetData } from "views/settings/utils";

const START_DATE = new Date(new Date().setDate(new Date().getDate() - 1));
const END_DATE = new Date(new Date().setDate(new Date().getDate() - 1));

function RightSide({ onApply: onSubmit, formData: fd }) {
  const { t } = useTranslation();
  
  const {
    restaurants,
    selectedRestaurantId,
    isRestaurantLoaded,
    hasRetaurants,
  } = useUserData();
  const {
    selectedCompetitorList,
    setSelectedCompetitorList,
    selectedFilterMyRestaurants,
    setSelectedFilterMyRestaurants,
    filterStartEndDate,
    setFilterStartEndDate,
    filterFormData,
    setFilterFormData,
    isFilterActive,
    updateFilterButton
  } = useFilterData();

  const { setError } = useLoading();
  const selectCompetitorRef = useRef(null);
  const selectMyRestaurantRef = useRef(null);
  const [competitorLoading, setCompetitorLoading] = useState(true);
  const [competitors, setCompetitors] = useState([]);
  const [selectedCompetitors, setSelectedCompetitors] = useState(selectedCompetitorList ?? []);
  const [selectedMyRestaurants, setSelectedMyRestaurants] = useState(selectedFilterMyRestaurants ?? []);
  const [isStartDateOpen, setIsStartDateOpen] = useState(false);
  const [isEndDateOpen, setIsEndDateOpen] = useState(false);
  const [formData, setformData] = useState(filterFormData ?? {});
  const [startEndDate, setStartEndDate] = useState({
    start_date: filterStartEndDate && filterStartEndDate.start_date !== "" ? filterStartEndDate.start_date : START_DATE,
    end_date: filterStartEndDate && filterStartEndDate.end_date !== "" ? filterStartEndDate.end_date : END_DATE,
  });
  const { isFilterShown, setFilterShown } = useUserData();


  const prevSelectedResturantId = useRef();

  const [resturantOptions, setResturantOptions] = useState(restaurants);
  let dummyDatasource = useMemo(
    () =>
      DummyCompetitiveSetData.map((m) => ({
        value: m.name,
        label: m.name,
        name: m.name,
        id: m.id,
      })),
    []
  );

  let dummyRestaurantDatasource = useMemo(
    () =>
      myResturants.map((m) => ({
        value: m.id,
        label: m.name,
        name: m.name,
        id: m.id,
      })),
    []
  );

  useEffect(() => {
    if (selectedRestaurantId !== "") {
      // if (prevSelectedResturantId.current != selectedRestaurantId) {
        // onClearAll();
        getCompetitors();
      // } else {
        // getCompetitors();
      // }
    }
    prevSelectedResturantId.current = selectedRestaurantId;
    if (isRestaurantLoaded && !hasRetaurants) {
      onClearAll();
      setCompetitors(dummyDatasource);
      setResturantOptions(dummyRestaurantDatasource);
    }
  }, [selectedRestaurantId, hasRetaurants, isRestaurantLoaded]);

  useEffect(() => {
    if (!competitorLoading) {
      onApply();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [competitorLoading]);

  function setRestaurantIdToLocalStorage(id) {
    localStorage.setItem("selectedRestaurantId", id);
  }

  const getCompetitors = async () => {
    try {
      setCompetitorLoading(true);
      const result = await request.get(
        "competitors?restaurant_id=" + selectedRestaurantId
      );
      const competitors = [];

      result.competitors.forEach((restaurant) => {
        competitors.push(restaurant.competitor);
      });
      setCompetitors(competitors);
    } catch (error) {
      setError(DEFAULT_ERROR_MESSAGE);
    } finally {
      setCompetitorLoading(false);
    }
  };

  const onDateChange = (name) => (e) => {
    setStartEndDate({ ...startEndDate, [name]: e });
    setIsStartDateOpen(false);
    setIsEndDateOpen(false);
  };

  const onApply = (isInitial = false) => {
    const payload = JSON.parse(JSON.stringify({ ...fd, ...startEndDate }));
    const getFormattedDate = (d) => moment(d).format("YYYY-MM-DD");

    if (payload.start_date) {
      payload.start_date = getFormattedDate(payload.start_date);
    }
    if (payload.end_date) {
      payload.end_date = getFormattedDate(payload.end_date);
    }

    payload.competitors = selectedCompetitors.map((c) => c.value);
    payload.myRestaurants = selectedMyRestaurants.map((s) =>
      resturantOptions.find((r) => r.id === s.value)
    );

    if (typeof isInitial === "boolean" && isInitial !== false) {
      payload.start_date = getFormattedDate(START_DATE);
      payload.end_date = getFormattedDate(END_DATE);
      payload.myRestaurants = [];
      payload.competitors = [];
      TIMES_CHECKBOXES.map((x) => x.name).forEach((t) => delete payload[t]);
    }

    if (payload.competitors.length === 0) {
      payload.competitors = competitors.map((c) => c.id);
    }
    if (payload.myRestaurants.length === 0) {
      payload.myRestaurants = resturantOptions;
    }
    onSubmit(payload);
  };

  const onCheckboxChange = ({ target: { checked, name } }) => {
    setformData({ ...formData, [name]: checked });
  };

  const customCheckbox = ({ name, label, checked }) => {
    return (
      <Form.Group className="mb-3">
        <label className="checkbox">
          <input
            type="checkbox"
            onChange={onCheckboxChange}
            name={name}
            checked={checked}
          />
          <span className="me-2" />
          {label}
        </label>
      </Form.Group>
    );
  };

  const onClearTimes = () => {
    const newFormData = JSON.parse(JSON.stringify(formData));
    TIMES_CHECKBOXES.map((x) => x.name).forEach((t) => delete newFormData[t]);
    setformData({ ...newFormData });
  };

  const onClearDates = () => {
    setStartEndDate({ start_date: START_DATE, end_date: END_DATE });
  };

  const onClearAll = () => {
    onClearDates();
    setformData({});
    onClearCompetitors();
    onClearRestaurants();
    onApply(true);
  };

  const setDateOpen =
    (type, checked = false) =>
    () => {
      type === "start_date"
        ? setIsStartDateOpen(checked)
        : setIsEndDateOpen(checked);
    };

  const onClearCompetitors = () => {
    if (selectCompetitorRef?.current) {
      selectCompetitorRef.current.clearValue();
    }
  };

  const onClearRestaurants = () => {
    if (selectMyRestaurantRef?.current) {
      selectMyRestaurantRef.current.clearValue();
    }
  };

  const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected, ...args }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? data.color
          : isFocused
          ? "#F3F4FB"
          : undefined,
        color: isDisabled ? "#ccc" : isSelected ? "black" : "black",
      };
    },
    multiValueLabel: (base) => ({ ...base }),
  };

  const onSaveFilter = () => {
    setFilterFormData(formData);
    setSelectedCompetitorList(selectedCompetitors);
    setFilterStartEndDate(startEndDate);
    setSelectedFilterMyRestaurants(selectedMyRestaurants);
    updateFilterButton(true);
  }

  useEffect(() => {
    setformData(filterFormData);
    setSelectedCompetitors(selectedCompetitorList);
    setSelectedMyRestaurants(selectedFilterMyRestaurants);
    if (filterStartEndDate && filterStartEndDate.start_date !== "" && filterStartEndDate.end_date !== "") {
      setStartEndDate(filterStartEndDate);
    } else {
      setStartEndDate(startEndDate);
    }  
    onApply();
  }, [])

  return (
    isFilterShown && (
      <div className="rightcontent">
        <div className="card">
          <div className="card-body">
            <Row>
              <Col lg={4}>
                <h1>{t("Filters")}</h1>
              </Col>
              <Col lg={8}>
                <div
                  className="hide-filter"
                  onClick={() => setFilterShown(false)}
                >
                  {t("HideFilters")} <img src={DeleteIcon} alt="" />
                </div>
              </Col>
            </Row>
            <div className="rightcontent-fltr">
              <div className="mb-5">
                <div className="fltr-heading">
                  <label>{t("Competitors")}</label>
                  <button onClick={onClearCompetitors}>{t("Clear")}</button>
                </div>
                <Select
                  ref={selectCompetitorRef}
                  styles={colourStyles}
                  value={selectedCompetitors}
                  defaultValue={selectedCompetitors}
                  onChange={setSelectedCompetitors}
                  components={makeAnimated()}
                  options={competitors.map((c) => ({
                    value: c.id,
                    label: c.name,
                  }))}
                  isMulti
                  isSearchable
                  placeholder={t("Select at least 3 competitors")}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 10,
                    colors: {
                      ...theme.colors,
                      danger: "#fff",
                      dangerLight: "hsl(53deg 2% 73%)",
                    },
                  })}
                />
              </div>

              <div className="mb-5">
                <div className="fltr-heading">
                  <label>{t("MyRestaurants")}</label>
                  <button onClick={onClearRestaurants}>{t("Clear")}</button>
                </div>
                <Select
                  ref={selectMyRestaurantRef}
                  styles={colourStyles}
                  defaultValue={selectedMyRestaurants}
                  onChange={setSelectedMyRestaurants}
                  components={makeAnimated()}
                  options={resturantOptions.map((r) => ({
                    value: r.id,
                    label: r.name,
                  }))}
                  isMulti
                  isSearchable
                  placeholder={t("SelectRestaurants")}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 10,
                    colors: {
                      ...theme.colors,
                      danger: "#fff",
                      dangerLight: "hsl(53deg 2% 73%)",
                    },
                  })}
                />
              </div>

              <div className="mb-5">
                <div className="fltr-heading">
                  <label>{t("Date")}</label>
                  <button onClick={onClearDates}>{t("Clear")}</button>
                </div>
                <div className="d-flex datepicker-wrapper">
                  <span>{t("Start")}</span>
                  <div className="datepicker-wrapper-img">
                    <DatePicker
                      selected={startEndDate.start_date}
                      dateFormat="dd MMM yyyy"
                      placeholderText={t("Start date")}
                      onChange={onDateChange("start_date")}
                      className="date-picker"
                      open={isStartDateOpen}
                      onClickOutside={setDateOpen("start_date")}
                      onClick={setDateOpen("start_date", true)}
                    />
                    <img
                      src={CalendarIcon}
                      onClick={setDateOpen("start_date", !isStartDateOpen)}
                      alt=""
                    />
                  </div>
                </div>
                <hr className="hr-separator" />
                <div className="d-flex datepicker-wrapper">
                  <span>{t("End")}&nbsp;&nbsp;</span>
                  <div className="datepicker-wrapper-img">
                    <DatePicker
                      selected={startEndDate.end_date}
                      dateFormat="dd MMM yyyy"
                      placeholderText={t("End date")}
                      onChange={onDateChange("end_date")}
                      className="date-picker"
                      open={isEndDateOpen}
                      onClickOutside={setDateOpen("end_date")}
                      onClick={setDateOpen("end_date", true)}
                    />
                    <img
                      src={CalendarIcon}
                      onClick={setDateOpen("end_date", !isEndDateOpen)}
                      alt=""
                    />
                  </div>
                </div>
              </div>

              <div>
                <div className="fltr-heading">
                  <label>{t("Time")}</label>
                  <button onClick={onClearTimes}>{t("Clear")}</button>
                </div>
                {TIMES_CHECKBOXES.map((check, i) => {
                  const [labelName, ...rest] = check.label.split(" ");
                  const label = t(labelName) + " " + rest.join(" ");
                  return (
                    <Fragment key={i}>
                      {customCheckbox({
                        name: check.name,
                        label,
                        checked: formData[check.name] || false,
                      })}
                    </Fragment>
                  );
                })}
              </div>
            </div>

            <div className="action-btns" style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column"
              }}>
                <div 
                style={{
                  marginBottom : "7px"
                }}
                >
                  <button onClick={onSaveFilter} className="close-btn me-3" style={{
                    width: '100%',
                    color : isFilterActive ? "#8b8f94" : "#6353ea"
                  }}>
                    {t("FixFilters")}
                  </button>
                </div>
                <div style={{
                  display:"flex"
                }}>
                  <button onClick={onClearAll} className="close-btn me-3">
                    <img src={CloseIcon} alt="" />
                    {t("Clear")}
                  </button>
                  <button onClick={onApply} className="apply-btn">
                    {t("Apply")}
                  </button>
                </div>
              </div>
          </div>
        </div>
      </div>
    )
  );
}

export default RightSide;
