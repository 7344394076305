import React, { useState, useEffect } from "react";
import { Row, Col, Container, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import SelectInput from "components/selectInput";
import {
  getTextualRecurrence,
  RECURRENCE_TYPES_FIELDS,
  DAY_TYPES,
  MONTHS,
  DAYS,
} from "./utils";

import "./index.scss";

function Recurrence(props) {
  const { t } = useTranslation();

  const [fd, setFd] = useState({
    type: props.recurrence?.type,
    number: props.recurrence?.number,
    radioTypeMonth: "monthly_on_day",
    radioTypeYear: "yearly_on",
    days: props.recurrence?.days ?? [],
    monthly_on_day: null,
    monthly_on_the: null,
    yearly_on: null,
    yearly_on_the: null,
  });

  useEffect(() => {
    const recurrenceType = props.recurrence?.type;

    if (!recurrenceType) {
      return;
    }

    let newFd = {
      ...fd,
      number: props.recurrence?.number,
      days: props.recurrence?.days ?? [],
      radioTypeMonth: props.recurrence?.radioTypeMonth,
      radioTypeYear: props.recurrence?.radioTypeYear,
      monthly_on_day: props.recurrence?.monthly_on_day,
      monthly_on_the: props.recurrence?.monthly_on_the,
      yearly_on: props.recurrence?.yearly_on,
      yearly_on_the: props.recurrence?.yearly_on_the,
      type: recurrenceType,
    };

    setFd(newFd);
  }, [props?.recurrence]);

  const handleChange = (type) => (e) => {
    e.persist();
    props.setFormData({ [type]: e.target.value });
  };

  const onDaysClick = (day) => () => {
    if (fd?.days?.includes(day)) {
      props.setFormData({
        days: props?.recurrence?.days.filter((x) => x !== day),
      });
    } else {
      props.setFormData({
        days: [...new Set([...(props?.recurrence?.days || []), day])],
      });
    }
  };

  const textChange =
    (obj) =>
    ({ target: { name, value } }) => {
      if (name === "monthly_on_day" && (value > 31 || value < 0)) {
        return;
      }

      props.setFormData({
        ...(obj && {
          [obj]: {
            ...(props?.recurrence?.[obj] ?? {}),
            [name]: value,
          },
        }),
        ...(!obj && { [name]: value }),
      });
    };

  const onSelectionChange = (obj, property) => (_) => (ev) => {
    props.setFormData({
      [obj]: { ...(props?.recurrence?.[obj] ?? {}), [property]: ev?.value },
    });
  };

  const recurrenceType = props?.recurrence?.type;

  return (
    <Container className="p-0">
      <Form>
        {recurrenceType && (
          <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
            <Form.Label column sm={2}>
              {t("Repeat Every")}
            </Form.Label>
            <Col sm={2}>
              <Form.Control
                type="number"
                className="input"
                min={0}
                max={99}
                onChange={(ev) => {
                  if (ev.target?.value < 0 || ev.target?.value > 99) {
                    return;
                  }
                  props.setFormData({ number: ev.target?.value });
                }}
                value={fd.number}
              />
            </Col>
          </Form.Group>
        )}

        {recurrenceType === RECURRENCE_TYPES_FIELDS.WEEKLY && (
          <Form.Group
            as={Row}
            className="mb-3"
            controlId="formHorizontalPassword"
          >
            <Form.Label column sm={2} />
            <Col>
              {DAYS.map((day, index) => {
                return (
                  <button
                    type="button"
                    key={index}
                    className={`${index === 0 ? "ms-0" : "ms-3"} week-day ${
                      fd?.days?.includes(day.value) ? "active" : ""
                    }`}
                    onClick={onDaysClick(day.value)}
                  >
                    {day.labelP}
                  </button>
                );
              })}
            </Col>
          </Form.Group>
        )}

        {recurrenceType === RECURRENCE_TYPES_FIELDS.MONTHLY && (
          <Form.Group as={Row} className="mb-3" controlId="radioTypeMonth">
            <Form.Label column sm={2}></Form.Label>
            <Col sm={9}>
              <Row className="mb-3">
                <Col sm={3}>
                  <Form.Check
                    type="radio"
                    label="On day"
                    value="monthly_on_day"
                    onChange={handleChange("radioTypeMonth")}
                    checked={fd?.radioTypeMonth === "monthly_on_day"}
                  />
                </Col>
                <Col sm={2} className="ps-0">
                  <Form.Control
                    name="monthly_on_day"
                    type="number"
                    disabled={fd?.radioTypeMonth === "monthly_on_the"}
                    className="input"
                    min={1}
                    max={31}
                    onChange={textChange(null)}
                    value={fd?.monthly_on_day}
                  />
                </Col>
              </Row>

              <Row>
                <Col sm={3}>
                  <Form.Check
                    type="radio"
                    label="On the"
                    value="monthly_on_the"
                    onChange={handleChange("radioTypeMonth")}
                    checked={fd?.radioTypeMonth === "monthly_on_the"}
                  />
                </Col>
                <Col sm={8} className="ps-0">
                  <Row>
                    <Col sm={6}>
                      <SelectInput
                        isDisabled={fd?.radioTypeMonth === "monthly_on_day"}
                        portal
                        dataField={"value"}
                        options={DAY_TYPES}
                        onChange={onSelectionChange(
                          "monthly_on_the",
                          "day_type"
                        )}
                        value={DAY_TYPES.find(
                          (k) => k.value === fd?.monthly_on_the?.day_type
                        )}
                      />
                    </Col>
                    <Col sm={6} className="ps-0">
                      <SelectInput
                        isDisabled={fd?.radioTypeMonth === "monthly_on_day"}
                        portal
                        dataField={"value"}
                        placeholder={t("Day of the week")}
                        options={DAYS.map(day => ({...day, label: t(day.label)}))}
                        onChange={onSelectionChange(
                          "monthly_on_the",
                          "day_name"
                        )}
                        value={DAYS.find(
                          (k) => k.value === fd?.monthly_on_the?.day_name
                        )}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Form.Group>
        )}

        {recurrenceType === RECURRENCE_TYPES_FIELDS.YEARLY && (
          <Form.Group as={Row} className="mb-3" controlId="radioTypeYear">
            <Form.Label column sm={2}></Form.Label>
            <Col sm={9}>
              <Row className="mb-3">
                <Col sm={3}>
                  <Form.Check
                    type="radio"
                    label="On"
                    value="yearly_on"
                    onChange={handleChange("radioTypeYear")}
                    checked={fd?.radioTypeYear === "yearly_on"}
                  />
                </Col>
                <Col sm={7} className="ps-0">
                  <Row>
                    <Col sm={7} className="ps-0">
                      <SelectInput
                        portal
                        dataField="value"
                        isDisabled={fd?.radioTypeYear === "yearly_on_the"}
                        placeholder={t("select month")}
                        options={MONTHS}
                        onChange={onSelectionChange("yearly_on", "month_name")}
                        value={MONTHS.find(
                          (k) => k.value === fd?.yearly_on?.month_name
                        )}
                      />
                    </Col>
                    <Col sm={5}>
                      <Form.Control
                        min={1}
                        max={31}
                        type="number"
                        name="day_number"
                        className="input"
                        disabled={fd?.radioTypeYear === "yearly_on_the"}
                        onChange={textChange("yearly_on")}
                        value={fd?.yearly_on?.day_number}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row>
                <Col sm={3}>
                  <Form.Check
                    type="radio"
                    label="On the"
                    value="yearly_on_the"
                    onChange={handleChange("radioTypeYear")}
                    checked={fd?.radioTypeYear === "yearly_on_the"}
                  />
                </Col>
                <Col sm={9} className="ps-0">
                  <Row>
                    <Col sm={4} className="ps-0">
                      <SelectInput
                        portal
                        dataField="value"
                        isDisabled={fd?.radioTypeYear === "yearly_on"}
                        options={DAY_TYPES}
                        onChange={onSelectionChange(
                          "yearly_on_the",
                          "day_type"
                        )}
                        value={DAY_TYPES.find(
                          (k) => k.value === fd?.yearly_on_the?.day_type
                        )}
                      />
                    </Col>
                    <Col sm={4} className="ps-0">
                      <SelectInput
                        isDisabled={fd?.radioTypeYear === "yearly_on"}
                        portal
                        dataField={"value"}
                        placeholder={t("Monday")}
                        options={DAYS}
                        onChange={onSelectionChange(
                          "yearly_on_the",
                          "day_name"
                        )}
                        value={DAYS.find(
                          (k) => k.value === fd?.yearly_on_the?.day_name
                        )}
                      />
                    </Col>
                    <Col sm={4} className="ps-0">
                      <SelectInput
                        isDisabled={fd?.radioTypeYear === "yearly_on"}
                        portal
                        dataField="recurrence"
                        placeholder={t("July")}
                        // options={MONTHS}
                        options={MONTHS.map((r) => ({ value: t(r.value), label: t(r.label) }))}
                        onChange={onSelectionChange(
                          "yearly_on_the",
                          "month_name"
                        )}
                        value={MONTHS.find(
                          (k) => k.value === fd?.yearly_on_the?.month_name
                        )}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Form.Group>
        )}
      </Form>

      {/* <>{t(getTextualRecurrence(props.recurrence))}</> */}
      <>{getTextualRecurrence(props.recurrence, t)}</>
    </Container>
  );
}

export default Recurrence;
