import { Col } from "react-bootstrap";

import SelectLanguage from "components/selectLanguage";

import ArrowBack from "assets/images/icon/SIGN_UP_ARROW_BACK.svg";
import "./index.css"


import "react-phone-input-2/lib/style.css";
import { useTranslation } from "react-i18next";
import PricingCard from "components/pricingCard";
import { useEffect, useState } from "react";
import request from "services/request";
import storage from "services/storage";

const PLAN_MAP = {
  "restauration_collective_standard": "Restauration Collective Standard",
  "restauration_collective_premium": "Restauration Collective Premium",
  "collective_catering_premium": "Collective Catering Premium",
  "hotel_premium": "Hotel Premium",
}



function RightSide() {
  const { t } = useTranslation();
  const [plans, setPlans] = useState(null)
  const subscription = JSON.parse(storage.getItem("subscription"))

  useEffect(async() => {
		const result = await request.get("subscription/plans");
    const foundPackage = result.plans.find(plan => {
      if(subscription?.plan === 'hotel_premium' && plan.amount == 250) {
        return plan
      }
      if(subscription?.plan === 'collective_catering_premium' && plan.amount == 200) {
        return plan
      }
    })
    if(foundPackage) {
      setPlans([{...foundPackage, name: PLAN_MAP[foundPackage.name], plan: foundPackage.name}])
    }else {
      const selectedPlans = result.plans.filter(plan => plan.amount !== 250 && plan.amount !== 200)
      setPlans(selectedPlans.map(plan => ({...plan, name: PLAN_MAP[plan.name], plan: plan.name})))
    }
	}, [])

  return (
      <>
      <Col
        style={{width: subscription?.status === 'active' ? "75%" : "60%", alignItems: "center"}}
        className={"right-container align-self-center "+ (subscription?.status === 'active' ? "payment-right" : "")}
        md={{ span: 3, offset: 2 }}
        lg={{ span: 3, offset: 2 }}
      >
        
        {subscription?.status !== 'active' && <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
          <span onClick={() => window.history.back()} className="back-text ps-0" style={{cursor: "pointer", display: "inline-block"}}>
            <img src={ArrowBack} className="pe-2" alt="" />
            {t("Back")}
          </span>
          <span style={{display: "inline-block"}}>
          <SelectLanguage />
          </span>
        </div>}

        <>
          <div>
            {plans?.length > 0 && <PricingCard plans={plans} t={t} />}
          </div>

        </>
      </Col>
      </>
  );
}

export default RightSide;


