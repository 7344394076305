import React, { useState, useEffect, useMemo, useCallback } from "react";
import Autocomplete from "react-google-autocomplete";
import DatePicker from "react-datepicker";
import omit from "lodash/omit";
import moment from "moment";
import Creatable from "react-select/creatable";
import { useTranslation } from "react-i18next";
import { Modal, Button, Row, Col, Container, Form } from "react-bootstrap";
import { withAsyncPaginate } from "react-select-async-paginate";
import request from "services/request";
import SelectInput from "components/selectInput";
import { useLoading } from "contexts/LoadingContextManagement";
import { useUserData } from "contexts/AuthContextManagement";
import { DEFAULT_ERROR_MESSAGE, EVENT_TYPES } from "common/constants";
import { useOnDropAccepted } from 'common/utils.ts';
import "rc-tooltip/assets/bootstrap.css";
import 'bootstrap/dist/css/bootstrap.css';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';

import Select from "react-select";
import makeAnimated from "react-select/animated";

import {
  INITIAL_RECURRENCE,
  nearestFutureMinutes,
  RATING,
  RECURRENCE_TYPES,
  RECURRENCE_TYPES_FIELDS,
  TIMES,
} from "./utils";

import UploadedIcon from "assets/images/uploaded_meal.png";
import ArrowRight from "assets/images/arrow_right.png";

import Recurrence from "./recurrence";
import SelectAsyncPaginate from "components/selectAsyncPaginate/index.tsx";
import tableMealsDummy from "../../../data/table_meals.json";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import "react-time-picker-input/dist/components/TimeInput.css";
import HandleTooltip from '../../../components/rangeTooltip';
import { myResturants } from "views/occupancy/data";
import { useDropzone } from "react-dropzone";
import JPEG_ICON from "assets/images/icon/jpeg.svg";
import CLOSE_ICON from "assets/images/icon/DELETE.svg";
import EYE_ICON from "assets/images/icon/eye.svg";

const CreatableAsyncPaginate = withAsyncPaginate(Creatable);

const getDateTime = (mins = 30, dateTime = moment()) => {
  const nearest30minutes = nearestFutureMinutes(mins, dateTime);
  return {
    dateTime: nearest30minutes,
    date: nearest30minutes.format("YYYY-MM-DD"),
    time: nearest30minutes.format("HH:mm"),
  };
};

function CustomModal({
  onHide,
  onRowAdded,
  show,
  data: { selectedEvent },
  isUploadedModal = true,
}) {
  const { t } = useTranslation();
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [isUploaded, setIsUploaded] = useState(false);
  const { setLoading, setError } = useLoading();
  const [showRecurrenceModal, setShowRecurrenceModal] = useState(false);
  const [mealsList, setMealsList] = useState([]);
  const [meals, setMeals] = useState([]);
  const [selectedOption, setSelectedOption] = useState([]);
  const [selectedMyRestaurants, setSelectedMyRestaurants] = useState([]);
  const [resturantOptions, setResturantOptions] = useState([]);
  const [checked, setChecked] = useState(false);
  const [files, setFiles] = useState([]);

  let mappedRestaurants = [];

  const [fd, setFd] = useState({
    name: selectedEvent?.name ?? "",
    address: selectedEvent?.address ?? "",
    state:selectedEvent?.state ?? "",
    impact_type: selectedEvent?.impact ?? [],
    restaurants: mappedRestaurants ?? selectedMyRestaurants,
    image_path : selectedEvent?.image_path?? null,
    startDate: selectedEvent?.start_date
      ? new Date(selectedEvent?.start_date)
      : new Date(),
    endDate: selectedEvent?.end_date ? new Date(selectedEvent?.end_date) : null,
    recurrence: { ...INITIAL_RECURRENCE },
    startTime: selectedEvent?.start_time ?? getDateTime()?.time,
    endTime: selectedEvent?.end_time ?? getDateTime()?.time,
  });
  
  let dummyDatasource = useMemo(
    () =>
      tableMealsDummy.meals.map((m) => ({
        value: m.name,
        label: m.name,
        name: m.name,
        item_id: m.id,
      })),
    []
  );

  const handleRender = (node, props) => {
    return (
      <HandleTooltip value={props.value} visible={props.dragging}>
        {node}
      </HandleTooltip>
    );
  };

  const { selectedRestaurantId, isRestaurantLoaded, restaurants, hasRetaurants, selectedRestaurant} =
    useUserData();

    const onDropAccepted = useOnDropAccepted(setFiles, setError, t);
  
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
      accept: "image/jpg, image/jpeg, image/png",
      maxFiles: 1,
      onDropAccepted,
    });
  
    const thumbsContainer = {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      marginTop: 16,
    };
  
    const thumb = {
      display: "inline-flex",
      borderRadius: 2,
      border: "1px solid #eaeaea",
      marginBottom: 8,
      marginRight: 8,
      width: 100,
      height: 100,
      padding: 4,
      boxSizing: "border-box",
    };
  
    const thumbInner = {
      display: "flex",
      minWidth: 0,
      overflow: "hidden",
    };
  
    const img = {
      display: "block",
      width: "auto",
      height: "100%",
    };
  
  const onRemoveFile = (file, i) => () => {
    const newFiles = [...files];
    newFiles.splice(newFiles.indexOf(file), 1);
    setFiles(newFiles);
  };

  const thumbs = files.map((file) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img src={file.preview} style={img} alt="..." />
      </div>
    </div>
  ));

  const getRadioValues = (recurrence = {}) => {
    if (recurrence?.type === RECURRENCE_TYPES_FIELDS.MONTHLY) {
      return {
        radioTypeMonth: "monthly_on_the",
        ...(!!recurrence?.monthly_on_day && {
          radioTypeMonth: "monthly_on_day",
        }),
      };
    }

    if (recurrence?.type === RECURRENCE_TYPES_FIELDS.YEARLY) {
      return {
        radioTypeYear: "yearly_on_the",
        ...(!!recurrence?.yearly_on && { radioTypeYear: "yearly_on" }),
      };
    }
  };

  useEffect(()=>{
    if (Array.isArray(selectedEvent?.restaurants) && selectedEvent.restaurants.every(item => typeof item === 'string')) {
      let result = [];
      resturantOptions.forEach(restaurant => {
        if(selectedEvent?.restaurants.includes(restaurant?.id)) {
            result.push({ label: restaurant.name, value: restaurant.id.toString() });
        }
      });
      mappedRestaurants = result
    }
    else{
      mappedRestaurants = selectedEvent?.restaurants?.map(restaurant => ({value: restaurant?.id, label: restaurant?.name}))
    }
  },[selectedEvent])

  useEffect(() => {
    setIsUploaded(false);
    if (show && !!selectedEvent) {
      setFd({
        recurrence: {
          ...INITIAL_RECURRENCE,
          ...selectedEvent?.recurrence,
          ...getRadioValues(selectedEvent?.recurrence),
        } ?? {
          ...INITIAL_RECURRENCE,
        },
        name: selectedEvent?.name ?? "",
        address: selectedEvent?.address ?? "",
        country: selectedEvent?.country ?? "",
        state: selectedEvent?.state ?? "",
        city: selectedEvent?.city ?? "",
        impact_type: selectedEvent?.impact ?? [],
        restaurants: mappedRestaurants ?? [],
        image_path : selectedEvent?.image_path?? null,
        startDate: selectedEvent?.start_date
          ? new Date(selectedEvent?.start_date)
          : new Date(),
        endDate: selectedEvent?.end_date
          ? new Date(selectedEvent?.end_date)
          : null,
        startTime: selectedEvent?.start_time ?? getDateTime()?.time,
        endTime: selectedEvent?.end_time ?? null,
        type: selectedEvent?.type,
      });
    } else {
      setFd({
        startDate: new Date(getDateTime()?.date),
        endDate: new Date(getDateTime(60, getDateTime()?.dateTime)?.date),
        startTime: getDateTime()?.time,
        impact_type: [],
        endTime: getDateTime(60, getDateTime()?.dateTime)?.time,
        recurrence: { ...INITIAL_RECURRENCE },
      });
    }
  }, [show,selectedEvent]);

  useEffect(() => {
    if (
      fd.name &&
      fd.address &&
      fd.country &&
      fd.city &&
      fd.state &&
      fd.percentage &&
      fd.meals &&
      fd.impact_type !== undefined &&
      fd.impact_type !== null
    ) {
      setIsSubmitDisabled(false);
      return;
    }
    setIsSubmitDisabled(true);
  }, [fd]);
  
  useEffect(() => {
    selectedEvent?.impact?.forEach(item => {
      if (item?.impact_type === "specified_meals") {
          setChecked(item.is_all_meals);
      }
     });
  }, [selectedEvent])

  useEffect(() => {
    if (!fd?.recurrence?.type) {
      return;
    }
    setShowRecurrenceModal(true);
  }, [fd?.recurrence?.type]);

  let dummyRestaurantDatasource = useMemo(
    () =>
      myResturants.map((m) => ({
        value: m.name,
        label: m.name,
        name: m.name,
        id: m.id,
      })),
    []
  );

  useEffect(() => {
    if (isRestaurantLoaded && !hasRetaurants) {
      setResturantOptions(dummyRestaurantDatasource);
    }
    setResturantOptions(restaurants)
  }, [hasRetaurants, isRestaurantLoaded, dummyRestaurantDatasource]);

  const onSelectionChange = (type) => (ev) => {
    let impactList = []
    if(type == "impact_type"){
      impactList = ev?.map(ev =>{
      const data = fd?.impact_type?.find(imt => imt.impact_type === ev.value )
      return (
        {
          impact_type: data ? data.impact_type : ev.value,
          percentage: data ? data.percentage : 0,
          ...(data?.impact_type == "specified_meals" && { meals: data.meals }),
        }
      )
     })
    }

    setFd({
      ...fd,
      ...(type === "recurrence" && {
        recurrence: {
          ...(fd?.recurrence ?? {}),
          type: ev.value,
        },
      }),
      ...(type !== "recurrence" && type !== "impact_type" && { [type]: ev.value }),
      ...(type == "impact_type" && { impact_type : impactList })
    });
  };

  const onSubmit = async (ev) => {
    ev.preventDefault();

    let recurrencePayload = {
      type: fd?.recurrence?.type,
      number: parseInt(fd?.recurrence?.number),
    };

    if (fd?.recurrence?.type === RECURRENCE_TYPES_FIELDS.WEEKLY) {
      recurrencePayload = {
        ...recurrencePayload,
        days: fd?.recurrence?.days,
      };
    }

    if (fd?.recurrence?.type === RECURRENCE_TYPES_FIELDS.MONTHLY) {
      recurrencePayload = {
        ...recurrencePayload,
        ...(fd?.recurrence?.radioTypeMonth === "monthly_on_day" && {
          monthly_on_day: fd?.recurrence?.monthly_on_day,
        }),
        ...(fd?.recurrence?.radioTypeMonth === "monthly_on_the" && {
          monthly_on_the: fd?.recurrence?.monthly_on_the,
        }),
      };
    }

    if (fd?.recurrence?.type === RECURRENCE_TYPES_FIELDS.YEARLY) {
      recurrencePayload = {
        ...recurrencePayload,
        ...(fd?.recurrence?.radioTypeYear === "yearly_on" && {
          yearly_on: fd?.recurrence?.yearly_on,
        }),
        ...(fd?.recurrence?.radioTypeYear === "yearly_on_the" && {
          yearly_on_the: fd?.recurrence?.yearly_on_the,
        }),
      };
    }


    const mealListsItems = selectedOption
    .filter((o) => !!o.details)
    .flatMap((o) => o.details);

    const mealItems = selectedOption.filter((o) => !o.details);

    let restaurantIds = [];
    if(fd?.restaurants && fd?.restaurants.length > 0){
      fd?.restaurants?.forEach((restaurant) => {
        restaurantIds.push(restaurant?.value)
      });
    }  

    const updatedImpactType = fd?.impact_type.map((impactType) => {
      if (impactType.impact_type === "specified_meals") {
          const isAllMealExists = checked
          return {
            ...impactType,
            ...(isAllMealExists ? { is_all_meals: true, meals: undefined } : { is_all_meals: false, meals: impactType.meals }),
        };
      }
      return impactType;
  });

      const payload = omit(
        {
          ...fd,
          start_date: moment(fd.startDate).format("YYYY-MM-DD"),
          end_date: moment(fd.endDate).format("YYYY-MM-DD"),
          start_time: fd?.startTime,
          end_time: fd?.endTime,
          ...(updatedImpactType.length > 0 && { impact: updatedImpactType }),
          restaurants: restaurantIds,
          recurrence: null,
          ...(recurrencePayload &&
            recurrencePayload?.type && { recurrence: recurrencePayload }),
        },
        ["impact_type","percentage","meals","startDate", "endDate", "startTime", "endTime"]
      );  

    try {
      if (isRestaurantLoaded && !hasRetaurants) {
        setIsSubmitDisabled(false);
        setLoading(false);
        setIsUploaded(true);
        onRowAdded(payload, !!selectedEvent);
        setFd({
          name: "",
          address: "",
          impact_type: [],
          startDate: new Date(),
          endDate: null,
          restaurants:null,
          image_path: null,
          recurrence: { ...INITIAL_RECURRENCE },
          startTime: getDateTime()?.time,
          endTime: getDateTime()?.time,
        });
        return;
      }

      setIsSubmitDisabled(true);
      setLoading(true);
      let result = null;

      if (selectedEvent) {
        delete payload.image_path;
        result = await request.patch(`/events/${selectedEvent.id}`, {
          ...payload,
          address: fd.address === "" ? null : fd.address,
          state: fd.state === "" ? null : fd.state,
          country: fd.country === "" ? null : fd.country,
          city: fd.city === "" ? null : fd.city,
          restaurants: restaurantIds,
        });
        if ([200].includes(result.status)) {
          fileUpload(await result.json());
          return;
        }
      } else {
        result = await request.post("/events", {
          ...payload,
          restaurants: restaurantIds,
        });

        if ([200, 201].includes(result.status)) {
          fileUpload(await result.json());
          return;
        }
      }

      if (!result) {
        throw new Error(DEFAULT_ERROR_MESSAGE);
      }

      if (![200, 201].includes(result.status)) {
        throw await result.json();
      }
      
      setIsSubmitDisabled(false);
      setLoading(false);
      setIsUploaded(true);
      onRowAdded(await result.json(), !!selectedEvent);
    } catch (error) {
      setIsSubmitDisabled(false);
      const errorMsg = (error && error.msg);
      setError(`${errorMsg}`);
    }
  };

  const fileUpload = async (event, create = true) => {
    if (!acceptedFiles.length) {
      onRowAdded(event, create);
      setFd({ ...fd });
      setLoading(false);
      setIsSubmitDisabled(false);
      if (isUploadedModal) {
        setIsUploaded(true);
        return;
      }
      onHide();
      return;
    }
    try {
      let formData = new FormData();
      formData.append("image", acceptedFiles[0]);
      await request.patchFile(`/events/upload-image/${event.id}`, formData);

      setIsUploaded(true);
      onRowAdded(event, create);
      if (!isUploadedModal) {
        onHide();
      }
      setIsUploaded(true);
      setLoading(false);
      // setFd({ ...fd });
    } catch (error) {
      setError(DEFAULT_ERROR_MESSAGE);
    } finally {
      setIsSubmitDisabled(false);
    }
  };

  const handleChange = () => {
    setChecked(!checked);
  };

  const onPlaceSelect = (place) => {
    var filtered_array = place.address_components.filter(function (
      address_component
    ) {
      return address_component.types.includes("country");
    });
    //country
    var country = filtered_array.length ? filtered_array[0].long_name : "";

    //city
    let matches = place.address_components.filter((address_component) =>
      ["locality", "colloquial_area"].some(
        (word) => ~address_component.types.indexOf(word)
      )
    );
    let city = "dummy";
    if (!matches || !matches.length) {
      console.log("Could not resolve city name.");
    } else {
      city = matches[0].short_name;
    }

    let stateMatches = place.address_components.filter((address_component) =>
      ["administrative_area_level_1"].some(
        (word) => ~address_component.types.indexOf(word)
      )
    );
    let countryState = "";
    if (!stateMatches || !stateMatches.length) {
      console.log("Could not resolve countryState name.");
    } else {
      countryState = stateMatches[0].short_name;
    }

    setFd((prevFd) => ({
      ...prevFd,
      address: place.formatted_address,
      country: country,
      city: city,
      ...(countryState !== "" && { state: countryState })
    }));
  };

  const onChange = (type) => (date) => {
    const payload = { ...fd, [type]: date };
    if (type === "startDate" && moment(fd?.endDate).isBefore(date)) {
      payload.endDate = date;
    }
    setFd(payload);
  };

  const loadOptions = async (search, prevOptions) => {
    if (!search || search.length < 3 || search === "") {
      return { options: [], hasMore: false };
    }

    try {
      const response = await request.get(
        `sports/matches?date=2020-01-01&restaurant_id=${selectedRestaurantId}`,
        {},
        true,
        false
      );

      return {
        options: response.matches.map((i) => ({
          ...i,
          value: i.sport_name,
          label: `${i?.tournament_name} - ${i.team1} vs ${i.team2}`,
        })),
        hasMore: false,
      };
    } catch (error) {
      console.log(error);
      return { options: [], hasMore: false };
    }
  };

  const createSelectChange = (obj, { action }) => {
    setFd((prev) => ({
      ...prev,
      name: obj?.label || "",
      ...(action === "select-option" && {
        ...(obj.match_venue && { address: obj.match_venue }),
        ...(obj.match_datetime && {
          startDate: new Date((obj.match_datetime?.split("T"))[0]),
          endDate: new Date((obj.match_datetime?.split("T"))[0]),
        }),
      }),
      ...(action === "create-option" && {}),
      ...(action === "clear" && !selectedEvent && { name: "", address: undefined }),
    }));

    if (obj?.match_datetime) {
      /* setStartTime(
        moment(obj.match_datetime.replace(".000Z", "")).format("HH:mm")
      ); */
    }
  };

  const setFormData = (payload) => {
    setFd({ ...fd, recurrence: { ...fd?.recurrence, ...payload } });
  };

  const onMealSelect = () => (v) => {
    const selectedMeals = v.map((meal) => ({
      id: meal.id,       
      item_id: meal.item_id,
      name: meal.name
    }));
    const updated = fd.impact_type.map(item => {
      if(item.impact_type === 'specified_meals') {
        return {...item, meals: selectedMeals}
      }
      return item
    })
    setMeals(selectedMeals);
    setSelectedOption([selectedMeals]);
    setFd({...fd, impact_type: updated})
  };
  
  const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected, ...args }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? data.color
          : isFocused
          ? "#F3F4FB"
          : undefined,
        color: isDisabled ? "#ccc" : isSelected ? "black" : "black",
      };
    },
    multiValueLabel: (base) => ({ ...base }),
  };

  const handleSelectedRestaurantsChange = (selectedOptions) => {
    setFd((prevFd) => ({
      ...prevFd,
      restaurants: selectedOptions,
    }));
    setSelectedMyRestaurants(selectedOptions);
  };

  const deleteImage = () => {
    setFd((prev) => ({ ...prev, image_path: null }));
  };

  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        size="lg"
        centered
        className="add-ingredient add-event"
        backdropClassName="add-ingredient-backdrop"
        dialogClassName="recurrence-modal"
      >
        <Modal.Header className="add-restaurants-modal-header" closeButton>
          {!isUploaded && (
            <Modal.Title className="add-restaurants-modal-title">
              {selectedEvent ? t("Edit") : t("Add")} {t("Event(s)")}
            </Modal.Title>
          )}
        </Modal.Header>

        <Modal.Body className="add-event-body">
          <Container className="p-0">
            {isUploadedModal && isUploaded && (
              <div className="d-flex justify-content-center flex-column  text-center upload-container">
                <div>
                  <img src={UploadedIcon} alt="..." />
                </div>
                <div className="heading mt-5">
                  <label>
                    {selectedEvent ? t("Event updated") : t("Event created")} !
                  </label>
                </div>
                <div className="subheading mt-2 mb-5">
                  <label>
                    {selectedEvent
                      ? t("Your event has been successfully updated")
                      : t("Your event has been successfully created")}
                    .
                  </label>
                </div>
                <div>
                  <Button
                    className="add-restaurant-confirm-btn"
                    onClick={onHide}
                  >
                    {t("OK")}
                  </Button>
                </div>
              </div>
            )}

            {!isUploaded && (
              <Form className="ps-0 pe-0  col-lg-12" onSubmit={onSubmit}>
                <Form.Group className="mb-2">
                  <span className="add-restaurants-input-title ps-0">
                    {t("Event Name")}
                  </span>
                  <CreatableAsyncPaginate
                    value={fd?.name ? { label: fd.name, value: fd.name } : ""}
                    loadOptions={loadOptions}
                    onChange={createSelectChange}
                    placeholder={t("Event Name")}
                    isClearable
                  />
                </Form.Group>

                <Form.Group className="mb-2">
                  <span className="add-restaurants-input-title ps-0">
                    {t("Address")}
                  </span>

                  <Autocomplete
                    placeholder={t("Enter a location")}
                    className="w-100 form-control custom-input"
                    apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
                    onPlaceSelected={onPlaceSelect}
                    options={{ types: ["geocode", "establishment"] }}
                    defaultValue={fd?.address ?? ""}
                  />
                </Form.Group>

                <Form.Group className="mb-2">
                  <Row>
                    <Col>
                      <span className="add-restaurants-input-title ps-0">
                        {t("Type")}
                      </span>
                      <SelectInput
                        portal
                        dataField="type"
                        placeholder={t("Select type")}
                        options={EVENT_TYPES.map(item => ({
                          ...item,
                          label: t(item.label),
                        }))}
                        onChange={onSelectionChange}
                        value={EVENT_TYPES.find((v) => v.value === fd?.type)}
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <>
                  {!(fd?.type === "closing") && (
                    <>
                      <Form.Group className="mb-2">
                        <Row>
                          <Col>
                            <span className="add-restaurants-input-title ps-0">
                              {t("Impact on")}
                            </span>
                            <SelectInput
                              portal
                              dataField="impact_type"
                              placeholder={t("Select impact type")}
                              onChange={onSelectionChange}
                              isMulti
                              options={RATING.map(item => ({
                                ...item,
                                label: t(item.label)
                              }))}
                              value={fd?.impact_type.map(v =>(RATING?.find(item => t(v.impact_type) === t(item.value))))}
                            />
                          </Col>
                        </Row>
                      </Form.Group>

                      {
                        fd?.impact_type && fd?.impact_type.some(obj => obj.impact_type === "revenue")&& (
                        <Form.Group className="rangeSlider">
                          <span className="add-restaurants-input-title ps-0">
                            {t("Impact in percentage")}
                            {" in "}
                            {t("Revenue")}
                            {" "}
                            {fd?.impact_type?.find(item => item.impact_type === 'revenue')?.percentage ?? 0}
                          </span>
                            <Slider
                                min={-100}
                                max={100}
                                handleRender={handleRender}
                                onChange={(value) => {
                                  let updatedArray = fd.impact_type.map(item => {
                                    if(item.impact_type === 'revenue'){
                                      return {...item, percentage: value}
                                    }
                                    return item
                                  })
                                  setFd({...fd, impact_type: [...updatedArray]})
                                }}                          
                                startPoint={0}
                                defaultValue={fd?.impact_type?.find(item => item.impact_type === 'revenue')?.percentage ?? 0}
                              />
                        </Form.Group>
                       )
                      }

                      {
                        fd?.impact_type && fd?.impact_type.some(obj => obj.impact_type === "occupancy") && (
                        <>
                          <Form.Group className="rangeSlider">
                            <span className="add-restaurants-input-title ps-0">
                              {t("Impact in percentage")}
                              {" in "}
                              {t("Occupancy")}
                              {" "}
                              {fd?.impact_type?.find(item => item.impact_type === 'occupancy')?.percentage ?? 0}
                            </span>
                            <Slider
                              min={-100}
                              max={100}
                              handleRender={handleRender}
                              onChange={(value) => {
                                  let updatedArray = fd.impact_type.map(item => {
                                    if(item.impact_type === 'occupancy'){
                                      return {...item, percentage: value}
                                    }
                                    return item
                                  })
                                  setFd({...fd, impact_type: [...updatedArray]})
                                }}   
                              startPoint={0}
                              defaultValue={fd?.impact_type?.find(item => item.impact_type === 'occupancy')?.percentage ?? 0}
                            />
                          </Form.Group>
                        </>
                       )
                      }

                      {
                        fd?.impact_type && fd?.impact_type.some(obj => obj.impact_type === "specified_meals") &&
                        <>
                          <Form.Group className="rangeSlider">
                          <span className="add-restaurants-input-title ps-0">
                          {t("Impact in percentage")}
                          {" in "}
                          {t("Specified Meals")}
                          {" "}
                          {fd?.impact_type?.find(item => item.impact_type === 'specified_meals')?.percentage ?? 0}
                          </span>
                            <Slider
                              min={-100}
                              max={100}
                              handleRender={handleRender}
                              onChange={(value) => {
                                  let updatedArray = fd.impact_type.map(item => {
                                    if(item.impact_type === 'specified_meals'){
                                      return {...item, percentage: value}
                                    }
                                    return item
                                  })
                                  setFd({...fd, impact_type: [...updatedArray]})
                                }}   
                              startPoint={0}
                              type="specified_meals"
                              defaultValue={fd?.impact_type?.find(item => item.impact_type === 'specified_meals')?.percentage ?? 0}
                            />
                          </Form.Group>

                          <Form.Group>
                            <Row style={{
                              display:"flex",
                              justifyContent:"center",
                              alignItems:"center"
                            }}>
                              <Col lg={{ span: 2 }}>
                                <label style={{display:"flex"}}>
                                  {t("SelectAllMeals")}
                                  <input
                                    type="checkbox"
                                    checked={checked}
                                    onChange={handleChange}
                                    style={{
                                      marginLeft:"4px"
                                    }}
                                  />
                                </label>
                              </Col>
                              <Col lg={{ span: 10 }}>
                                <div className="row custom-table h-100">
                                    <div className="col-lg-12 h-100 p-2">
                                      <SelectAsyncPaginate
                                        {...(!selectedRestaurant && {
                                          datasource: dummyDatasource,
                                        })}
                                        dataField="ingredient_id"
                                        placeholder={t("SelectImpactedMeals")}
                                        onChange={onMealSelect}
                                        value={fd?.impact_type?.find(item => item.impact_type === "specified_meals")?.meals}
                                        count={meals.length}
                                        isMulti
                                        query="meals"
                                        key={(mealsList ?? []).join("_")}
                                        multiOptions={mealsList}
                                        isEvent={true}
                                        isDisabled={checked ? true : false}
                                      />
                                    </div>
                                </div>
                              </Col>
                            </Row>
                          </Form.Group>
                        </>
                      }
                    </>
                  )}
                </>

                <Form.Group className="mb-2">
                  <Row>
                    <Col lg={{ span: 3 }} className="date-time">
                      <span className="add-restaurants-input-title ps-0">
                        {t("Date")}
                      </span>
                      <div className="d-flex datepicker-wrapper">
                        <DatePicker
                          className="date-picker form-control custom-input"
                          dateFormat="dd MMM yyyy"
                          placeholderText={t("SelectDate")}
                          selected={fd.startDate}
                          onChange={onChange("startDate")}
                        />
                      </div>
                    </Col>

                    <Col
                      lg={{ span: 3 }}
                      className="position-relative date-time"
                    >
                      <span className="add-restaurants-input-title ps-0">
                        {t("Time")}
                      </span>

                      <div className="d-flex datepicker-wrapper">
                        <SelectInput
                          portal
                          dataField="startTime"
                          placeholder={t("SelectTime")}
                          options={TIMES}
                          onChange={onSelectionChange}
                          value={TIMES.find((t) => t.value === fd?.startTime)}
                        />
                        <img
                          src={ArrowRight}
                          className="position-absolute right-arrow"
                          alt="..."
                        />
                      </div>
                    </Col>

                    <Col lg={{ span: 3 }} className="date-time">
                      <span className="add-restaurants-input-title ps-0">
                        {t("Date")}
                      </span>

                      <div className="d-flex datepicker-wrapper">
                        <DatePicker
                          className="date-picker form-control custom-input"
                          dateFormat="dd MMM yyyy"
                          placeholderText={t("SelectDate")}
                          selected={fd.endDate}
                          onChange={onChange("endDate")}
                          minDate={fd?.startDate}
                        />
                      </div>
                    </Col>

                    <Col lg={{ span: 3 }} className="date-time">
                      <span className="add-restaurants-input-title ps-0">
                        {t("Time")}
                      </span>

                      <div className="d-flex datepicker-wrapper">
                        <SelectInput
                          portal
                          dataField="endTime"
                          placeholder={t("SelectTime")}
                          options={TIMES}
                          onChange={onSelectionChange}
                          value={fd?.endTime ? TIMES.find(t => t.value === fd.endTime) : ""}
                        />
                      </div>
                    </Col>
                  </Row>
                </Form.Group>

                <Form.Group className="mb-2 mt-4">
                  <Row>
                    <Col>
                      <SelectInput
                        portal
                        dataField="recurrence"
                        placeholder={t("Does not repeat")}
                        onChange={onSelectionChange}
                        options={RECURRENCE_TYPES.map(item => ({...item, label: t(item.label)}))}
                        value={RECURRENCE_TYPES.find(
                          (r) => r.value === fd?.recurrence?.type
                        )}
                      />
                    </Col>
                  </Row>
                </Form.Group>

                <Recurrence
                  setFd={setFd}
                  setFormData={setFormData}
                  recurrence={fd?.recurrence}
                />

                <div className="row custom-table h-100">
                  <div className="col-lg-12 h-100 pt-2">
                    <Select
                      styles={colourStyles}
                      value={fd?.restaurants}
                      onChange={handleSelectedRestaurantsChange}
                      components={makeAnimated()}
                      options={resturantOptions.map((r) => ({
                        value: r.id,
                        label: r.name,
                      }))}
                      isMulti
                      isSearchable
                      placeholder={t("SelectRestaurants")}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          danger: "#fff",
                          dangerLight: "hsl(53deg 2% 73%)",
                        },
                      })}
                    />
                  </div>
                </div>

                {/* event image */}

            <Row className="mt-4 photo-container">
              <Col lg={12}>
                <span className="add-restaurants-input-title ps-0">
                  {t("Photo")}
                </span>
                <div className="">
                  <div {...getRootProps({ className: "dropzone" })}>
                    <input {...getInputProps()} />
                    <p>{t("Drag&Drop")}</p>
                  </div>
                  <aside>
                    <span className="add-restaurants-input-title m-0">
                      {t("AcceptedFiles")}
                    </span>
                    <div className="files-list">
                      <ul>
                        {files.map((file, i) => (
                          <li
                            key={i}
                            className="d-flex justify-content-between"
                          >
                            <div className="d-flex">
                              <img src={JPEG_ICON} alt="..." />
                              <p className="ps-3">
                                <span>{file.path}</span>
                                <span className="size">
                                  {parseInt(file.size * 0.001)} Kb
                                </span>
                              </p>
                            </div>
                            <div>
                              <img
                                src={EYE_ICON}
                                alt="..."
                                className="eye me-3"
                              />
                              <img
                                src={CLOSE_ICON}
                                alt="..."
                                className="close"
                                onClick={onRemoveFile(file, i)}
                              />
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </aside>
                  <aside style={thumbsContainer}>{thumbs}</aside>
                  {fd?.image_path && !files.length && (
                      <div style={thumb} className="position-relative">
                        <img
                          src={CLOSE_ICON}
                          alt="..."
                          className="deleteImage"
                          onClick={deleteImage}
                        />
                        <div style={thumbInner}>
                          <img src={fd.image_path} style={img} alt="..." />
                        </div>
                      </div>
                  )}
                </div>
              </Col>
            </Row>

              <Modal.Footer className="add-restaurants-modal-footer">
                <Button
                  type="submit"
                  /* disabled={isSubmitDisabled} */
                  className="add-restaurant-confirm-btn"
                >
                  {t("Confirm")}
                </Button>
              </Modal.Footer>
            </Form>
            )}
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default CustomModal;
