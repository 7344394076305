import React, { Fragment, useState } from "react";
import CreateLinkIcon from "assets/images/icon/create-link.svg"


import {
  Button,
  Form,
  Modal,
  Row,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";

function ConfirmationModal({
  onHide,
  show,
  state,
  getLink
}) {
  const { t } = useTranslation();
  const [restaurants, setRestaurants] = useState(state.restaurants.map(item => ({...item, checked: true})))

  const checkboxHandler = (type) => {
      setRestaurants(prev => prev.map(item => {
        console.log({item, type})
        if(item.value === type) {
          return {
            ...item,
            checked: !item.checked
          }
        }
        return item
      }))
  }

  const customCheckbox = ({ name, id, label, checked, onChange, type = null }) => (
    <Form.Group className="mb-3">
      <label className="checkbox">
        <input
          type="checkbox"
          onChange={() => onChange(id)}
          name={name}
          checked={checked}
        />
        <span className="me-2" />
        {label}
      </label>
    </Form.Group>
  );

  const createLinkHandler = () => {
    getLink(restaurants)
    onHide()
  }

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      centered
      className="add-ingredient"
      backdropClassName="add-ingredient-backdrop"
    >
      <Modal.Header className="add-restaurants-modal-header" closeButton>
          <Modal.Title className="add-restaurants-modal-title">
           <h2 style={{fontSize: "30px"}}>{t("Confirmation")}</h2>
          </Modal.Title>
      </Modal.Header>

      <Modal.Body>
          <Row>
           <p style={{fontSize: "17px"}}>{t("Are you sure to add all these restaurants listed below ?")}</p>
          </Row>
          <Row style={{display: "flex", justifyContent: "center"}}>
          </Row>
          <Row style={{ width: "100%", height: "1px", backgroundColor: "gray", opacity: "30%"}}></Row>
          <ul style={{marginTop: "20px"}}>
            {restaurants.map((item, index) => (
               <li key={index}>
               <Fragment key={'as'}>
                 {customCheckbox({
                   name: item.label,
                   label: item.label,
                   checked: item.checked,
                   id: item.value,
                   onChange: checkboxHandler
                 })}
               </Fragment>
             </li>
            ))}
          </ul>
          <div style={{display: "flex", justifyContent: "end"}}>
          <Button variant="primary add-btn-container" onClick={createLinkHandler}>
          <img src={CreateLinkIcon} className="add-btn-icon" alt="..." />
          {t("Create Link")}
        </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ConfirmationModal;
