import React, { useState, useEffect } from "react";
import { Row, Col, Button, Form } from "react-bootstrap";
import { useLoading } from "contexts/LoadingContextManagement";
import { DEFAULT_ERROR_MESSAGE } from "common/constants";
import { useUserData } from "contexts/AuthContextManagement";
import makeAnimated from "react-select/animated";
import Select from "react-select";
import request from "services/request";
import UserManagementModal from "./UserManagementModal";
import DeleteModal from "views/commonViews/DeleteModal";
import DeleteIcon from "assets/images/icon/DELETE.svg";
import Addicon from "assets/images/icon/ADD_WHITE.svg";
import {
  DummyCompetitiveSetData,
} from "../utils";
import { useTranslation } from "react-i18next";


const PACKAGE_OPTIONS = [
  {
    id: "standard",
    name: "Standard"
  },
  {
    id: "premium",
    name: "Premium"
  }
]

const colourStyles = {
  placeholder: (defaultStyles) => {
    return {
        ...defaultStyles,
        color: "#6353EA",
        opacity: "60%"
      }
  },
  singleValue: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: "#6353EA",
    }
  },
  option: (styles, { data, isDisabled, isFocused, isSelected, ...args }) => {
    return {
      ...styles,
      backgroundColor: "white",
      color: isDisabled ? "#ccc" : isSelected ? "black" : "black",
    };
  },
  multiValueLabel: (base) => ({ ...base, color: "#6353EA", fontWeight: 600, fontSize: "15px" }),
  control: (styles) => ({
    ...styles,
    minHeight: 55, // Set the height of the input field
    height: "auto",
    backgroundColor: "#efedfd", // Set the background color
    borderRadius: 10, // Set the border radius
    padding: "5px"
  }),
};

function UserManagement() {
  const { t } = useTranslation();
  const [addCompetitiveModalShow, setAddCompetitiveModalShow] = useState(false);
  const [deleteModalShow, setDeleteModelShow] = useState(false);
  const [selectedCompetitor, setSelectedCompetitor] = useState(null);
  const [competitors, setCompetitors] = useState([]);
  const [restaurants, setAllRestaurants] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedCompetator, setSelectedCompetator] = useState([]);
  const [formData, setFormData] = useState({})
  const { setLoading, setError } = useLoading();
  const [resturantOptions, setResturantOptions] = useState([]);
  const {
    selectedRestaurantId,
    isRestaurantLoaded,
    hasRetaurants,
    setSampleRestaurantModal,
  } = useUserData();

  useEffect(() => {
    if (isRestaurantLoaded && !hasRetaurants) {
      setAllRestaurants(DummyCompetitiveSetData);
      setSelectedCompetator(DummyCompetitiveSetData);
      setCompetitors(DummyCompetitiveSetData);
      setSelectedProducts(DummyCompetitiveSetData.map((s) => s.id));
      return;
    }
    if (selectedRestaurantId !== "") {
      getAllRestaurants();
      getCompetitorsData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedRestaurantId,
    hasRetaurants,
    isRestaurantLoaded,
    DummyCompetitiveSetData,
  ]);

  const getCompetitorsData = async () => {
    setLoading(true);
    try {
      const result = await request.get(
        "competitors?restaurant_id=" + selectedRestaurantId
      );
      const restaurantProduct = [];
      const _competitors = [];

      result.competitors.forEach((restaurant) => {
        restaurantProduct.push(restaurant.competitor.id);
        _competitors.push(restaurant.competitor);
      });
      setSelectedProducts(restaurantProduct);
      setSelectedCompetator(_competitors);
      setCompetitors(_competitors);
    } catch (error) {
      setError(DEFAULT_ERROR_MESSAGE);
    }
    setLoading(false);
  };

  
  const setSelectedPackage = (value) => {
    setFormData((prevState) => ({ ...prevState, package: value }));
  }

  const getAllRestaurants = async () => {
    setLoading(true);
    try {
      const result = await request.get("restaurants/all");
      setAllRestaurants(
        result.restaurants.filter((r) => r.id !== selectedRestaurantId)
      );
    } catch (error) {
      setError(DEFAULT_ERROR_MESSAGE);
    }
    setLoading(false);
  };

  const saveCompetitor = async (competitorIds) => {
    if (selectedRestaurantId == "") {
      setAddCompetitiveModalShow(false);
      setSampleRestaurantModal(true);
      return;
    }
    const competitorData = {
      restaurant_id: selectedRestaurantId,
      competitors: competitorIds,
    };
    setLoading(true);
    try {
      const result = await request.post("/competitors", competitorData);
      const data = await result.clone().json();

      // check for error response
      if (result.ok) {
        getCompetitorsData();
        setAddCompetitiveModalShow(false);
        return;
      } else {
        const errorMsg = (data && data.msg) || result.status;
        setError(`${errorMsg}`);
      }
    } catch (error) {
      setError(DEFAULT_ERROR_MESSAGE);
    }
    setLoading(false);
  };

  const handleInputChange = (event) => {
    const filteredOptions = restaurants?.filter((option) => {
       if(option.name.toLowerCase().includes(event.toLowerCase())) {
        return true
       }
       return false
    })
    setResturantOptions(filteredOptions);
  }

  const deleteCompetitor = async (competitorId) => {
    const competitorData = {
      restaurant_id: selectedRestaurantId,
      competitor_id: competitorId,
    };
    setLoading(true);
    try {
      const result = await request.delete("/competitors", competitorData);

      const data = await result.clone().json();
      // check for error response
      if (result.ok) {
        getCompetitorsData();
        return;
      } else {
        const errorMsg = (data && data.msg) || result.status;
        setError(`${errorMsg}`);
      }
    } catch (error) {
      setError(DEFAULT_ERROR_MESSAGE);
    }
    setLoading(false);
  };

  const item = (data, index) => (
    <Row key={index}>
      <Row className="pe-0">
        <Col lg={10}>
          <span className="competitor-title">{data.name}</span>
        </Col>
        <Col className="icon-container" lg={2}>
          <img
            src={DeleteIcon}
            className="icon"
            alt=""
            onClick={() => {
              setSelectedCompetitor(data);
              if (selectedRestaurantId == "") {
                setSampleRestaurantModal(true);
                return;
              } else {
                setDeleteModelShow(true);
              }
            }}
          />
        </Col>
      </Row>
      <Row>
        <span className="competitor-sub-title">{t("Address")}</span>
        <span className="competitor-text">{data.address}</span>
        <span className="competitor-sub-title">{t("SIRETNumber")}</span>
        <span className="competitor-text">{data.siret_number}</span>
        {/* divider */}
        {index === competitors.length - 1 ? (
          <></>
        ) : (
          <div className="divider"></div>
        )}
      </Row>
    </Row>
  );
  return (
    <div className="competitive-set">
      {/* Email and name block */}

      <Row>
        <Col lg={{ span: 3 }}>
          <p className="title-text">{t("UserManagement")}</p>
          <p className="sub-title-text">
            {t("Manage your users here.")}
          </p>
        </Col>
      </Row>
      <Row className="mt-5">
        <Col lg={6}>
          <span className="add-restaurants-input-title ps-0">
            {t("FirstName")}
          </span>
          <Form.Control
            type="email"
            className="inputField"
            placeholder={t("Email")}
            onChange={(event) =>
              setFormData({ ...formData, email: event.target.value })
            }
            value={formData.email}
          />
        </Col>
        <Col lg={6}>
          <span className="add-restaurants-input-title ps-0">
            {t("LastName")}
          </span>
          <Form.Control
            type="tel"
            className="inputField"
            placeholder={t("PhoneNumber")}
            required
            onChange={(event) =>
              setFormData({ ...formData, phone_number: event.target.value })
            }
            value={formData.phone_number}
          />
        </Col>
      </Row>
      <Row className="mt-5">
      <Col lg={6}>
          <span className="add-restaurants-input-title ps-0">
            {t("Email")}
          </span>
          <Form.Control
            type="tel"
            className="inputField"
            placeholder={t("PhoneNumber")}
            required
            onChange={(event) =>
              setFormData({ ...formData, phone_number: event.target.value })
            }
            value={formData.phone_number}
          />
        </Col>
        <Col lg={6}>
          <span className="add-restaurants-input-title ps-0">
            {t("Generate a strong password")}
          </span>
          <Form.Control
            type="tel"
            className="inputField"
            placeholder={t("PhoneNumber")}
            required
            onChange={(event) =>
              setFormData({ ...formData, phone_number: event.target.value })
            }
            value={formData.phone_number}
          />
        </Col>
      </Row>
   
    </div>
  );
}

export default UserManagement;
