import UploadedIcon from "assets/images/uploaded_meal.png";
import { Button } from "react-bootstrap";


function Uploaded({ onHide, t, providers, ingredients, responseMessage, modalName }) {
  const providerLength = providers?.length;
  const ingredientLength = ingredients?.length;
  return (
    <div className="d-flex justify-content-center flex-column  text-center upload-container">
      <div>
        <img src={UploadedIcon} alt="..." />
      </div>
      <div className="heading mt-5">
        <label>{t(`${modalName}s imported`)} !</label>
      </div>
      <div className="subheading mt-2 mb-5">
        <label>{t(responseMessage)}</label>
      </div>
      {!!providers?.length && <div className="subheading mt-2 mb-2">
        <label style={{ color: "red"}}>&#9888; {t("Please Add the details of these Providers.")}.</label>
        {providerLength > 3 ? <><ul>
          {providers.slice(0, 3).map(provider => (<li key={provider}>{provider}</li>))}
        </ul>
        <span>{t(`And ${providerLength - 3} more`)}</span>
        </> : <ul>
          {providers.map(provider => (<li key={provider}>{provider}</li>))}
        </ul> }
      </div>}
      {!!ingredients?.length && <div className="subheading mt-2 mb-5">
        <label style={{ color: "red"}}>&#9888; {t("Please Add the details of these Ingredinets.")}.</label>
        {ingredientLength > 3 ? <><ul>
          {ingredients.slice(0, 3).map(ingredient => (<li key={ingredient}>{ingredient}</li>))}
        </ul>
        <span>{t(`And ${ingredientLength - 3} more`)}</span>
        </> : <ul>
          {ingredients.map(ingredient => (<li key={ingredient}>{ingredient}</li>))}
        </ul> }
      </div>}
      <div>
        <Button className="add-restaurant-confirm-btn" onClick={onHide}>
          {t("OK")}
        </Button>
      </div>
    </div>
  );
}

export default Uploaded;
