import React, { useState, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { Row, Col, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import CustomTable from "components/customTable/index.tsx";
import useCurrencySymbol from "customHooks/useCurrencySymbol";
import SortFilter, { sortTableData } from "components/customTable/sortFilter";
import { cloneDeep, customToFixed } from "common/utils";

import DonationService from "./service";

import Trashicon from "assets/images/icon/filter.svg";

const getTableColumns = (t, currencySymbol) => [
  {
    dataField: "name",
    caption: t("Products"),
    className: "fw-bold",
    style: { width: "100px", height: "60px" },
    headerStyle: { width: "100px", height: "60px" },
    type: "string",
  },
  {
    caption: t("Stock"),
    dataField: "stock.unit_stock",
    className: "text-center",
    headerClassName: "text-center",
    type: "customRender",
    render: (_, it) => (
      <span>
        {it.stock === null ? 0 : it.stock.unit_stock !== null ? it.stock.unit_stock : 0}
      </span>
    ),
  },
  {
    dataField: "unit",
    caption: t("Unit"),
    className: "text-center",
    headerClassName: "text-center",
    type: "string",
  },
  {
    dataField: "unit_price",
    caption: `${t("Value")} (${currencySymbol})`,
    className: "text-center",
    headerClassName: "text-center",
    type: "string",
  },
  {
    caption: t("Qty to donate"),
    dataField: "qty_to_donate",
    type: "string",
    className: "text-center",
    headerClassName: "text-center",
  },
  {
    dataField: "donation_value",
    caption: `${t("Donation value")}(${currencySymbol})`,
    className: "text-center",
    headerClassName: "text-center",
    type: "customRender",
    render: (_, it) => (
      <span>
        {customToFixed(
          parseFloat(it.qty_to_donate || 0) * parseFloat(it.unit_price || 0)
        )}
      </span>
    ),
  },
];

/**
 * ReviewDonation component which displays the suggested orders after the user clicks on place-order of stock screen
 * @param {*} props
 * @returns UI component
 */
function ReviewDonation(props) {
  const history = useHistory();
  const { t } = useTranslation();
  const { currencySymbol, currencyNameSymbol } = useCurrencySymbol();
  const [fd, setfd] = useState({ sortbyOrder: "desc" });
  const [tableData, setTableData] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [filteredTableData, setFilteredTableData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  const tableColumns = useMemo(
    () => getTableColumns(t, currencySymbol),
    [t, currencySymbol]
  );

  /**
   * hook to be called when this component loads
   */

  useEffect(() => {
    const donations = DonationService.getSelectedItems();
    if (donations?.length) {
      setFilteredTableData(() => donations);
      return;
    }
    history.push({ pathname: "/donation" });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   *
   * @param {Event} target
   * @returns undefined
   */

  const selectAllProducts = ({ target: { checked } }) =>
    setSelectedItems(() =>
      checked ? tableData.map(({ provider: { id } }) => id) : []
    );

  const totoalDonationValue = () => {
    let total_sum = 0;
    filteredTableData.forEach((value, index) => {
      total_sum = total_sum + value.unit_price * value.qty_to_donate;
    });
    return total_sum;
  };

  const selectChange =
    (it) =>
    ({ target: { checked } }) => {
      const newSelectedRows = cloneDeep(selectedRows);
      if (checked) {
        setSelectedRows(() => [...newSelectedRows, it.id]);
      } else {
        setSelectedRows(() => newSelectedRows.filter((p) => p !== it.id));
      }
    };

  const selectAllRows = ({ target: { checked } }) => {
    setSelectedRows(() =>
      checked ? [...filteredTableData.map((i) => i.id)] : []
    );
  };

  /**
   * Delete rows from the table & update the state
   */
  const deleteRows = async () => {
    setSelectedRows(() => []);
    let filteredData = filteredTableData.filter(
      ({ id }) => !selectedRows.includes(id)
    );
    DonationService.setSelectedItems(filteredData);
    setFilteredTableData(() => filteredData);
  };

  const rows = sortTableData(fd, tableColumns, filteredTableData);

  const donateStock = () => {
    history.push("/donation/charity");
  };

  return (
    <div className="wrappers suggested-orders-container review-donation">
      <section className={`maincontent h-100 maincontent-collapsed container`}>
        <Card className="p-5 order-container card-height">
          <Row className="mb-4">
            <label
              className="order-back back"
              onClick={() => props.history.goBack()}
            >
              {"<"} {t("Back")}
            </label>
          </Row>
          <Row>
            <h3 className="heading-text">Review your donation</h3>
          </Row>
          <Row>
            <div className="d-flex ro">
              <Col className="sort-container d-flex justify-content-end">
                {Boolean(selectedRows.length) && (
                  <img
                    src={Trashicon}
                    onClick={deleteRows}
                    className="me-3 cursor-pointer"
                    alt="..."
                  />
                )}
                <SortFilter
                  cols={tableColumns}
                  fd={fd}
                  setfd={setfd}
                  rootClassName="sort-filter"
                />
              </Col>
            </div>
          </Row>
          <div className="review-donation-table">
            <CustomTable
              columns={tableColumns}
              data={rows}
              selectChange={selectChange}
              selectedProducts={selectedRows}
              selectAllProducts={selectAllRows}
            />
          </div>
          <Row>
            <div className="d-flex justify-content-end mt-3 mb-2 selected-prod-div">
              <div className="d-flex flex-column me-3">
                <label>Total donation value</label>
                <label className="d-flex  justify-content-end">
                  {totoalDonationValue()} {currencyNameSymbol}
                </label>
              </div>
              <button className="float-end donate-btn" onClick={donateStock}>
                Next
              </button>
            </div>
          </Row>
        </Card>
      </section>
    </div>
  );
}

export default ReviewDonation;
