import React from "react";
import Carousel from "react-multi-carousel";
import DefaultResturantIcon from "../../assets/images/default_resturant.png";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 3,
  },
};

class MyResturantsCarousel extends React.Component {
  constructor(props) {
    super(props);
  }
  state = { additionalTransfrom: 0 };

  onSelectPlace = (place) => () => {
    this.props.onSelectPlace(place);
    this.setState({ place: place.name });
  };

  render() {
    const CustomSlider = ({ carouselState }) => {
      let value = 0;
      let carouselItemWidth = 0;
      if (this.Carousel) {
        carouselItemWidth = this.Carousel.state.itemWidth;
        const maxTranslateX = Math.round(
          // so that we don't over-slide
          carouselItemWidth *
            (this.Carousel.state.totalItems -
              this.Carousel.state.slidesToShow) +
            150
        );
        value = maxTranslateX / 100; // calculate the unit of transform for the slider
      }
      const { transform } = carouselState;
      return (
        <div className="custom-slider">
          <input
            type="range"
            value={Math.round(Math.abs(transform) / value)}
            className="custom-slider__input"
            max={
              (carouselItemWidth *
                (carouselState.totalItems - carouselState.slidesToShow) +
                (this.state.additionalTransfrom === 150 ? 0 : 150)) /
              value
            }
            onChange={(e) => {
              if (this.Carousel.isAnimationAllowed) {
                this.Carousel.isAnimationAllowed = false;
              }
              const nextTransform = e.target.value * value;
              const nextSlide = Math.round(nextTransform / carouselItemWidth);
              if (
                parseInt(e.target.value) === 0 &&
                this.state.additionalTransfrom === 150
              ) {
                this.Carousel.isAnimationAllowed = true;
                this.setState({ additionalTransfrom: 0 });
              }
              this.Carousel.setState({
                transform: -nextTransform, // padding 20px and 5 items.
                currentSlide: nextSlide,
              });
            }}
          />
        </div>
      );
    };
    return (
      <Carousel
        ssr={false}
        ref={(el) => (this.Carousel = el)}
        arrows={false}
        partialVisbile={true}
        customButtonGroup={<CustomSlider />}
        itemClass="slider-image-item"
        responsive={responsive}
        containerClass="carousel-container-with-scrollbar"
        additionalTransfrom={-this.state.additionalTransfrom}
        beforeChange={(nextSlide) => {
          if (nextSlide !== 0 && this.state.additionalTransfrom !== 150) {
            this.setState({ additionalTransfrom: 150 });
          }
          if (nextSlide === 0 && this.state.additionalTransfrom === 150) {
            this.setState({ additionalTransfrom: 0 });
          }
        }}
      >
        {this.props?.data?.map((r, i) => (
          <div
            key={i}
            className={`place-container d-flex ${
              this.state.place === r.name ? "place-container-active" : ""
            }`}
            onClick={this.onSelectPlace(r)}
          >
            <div className="parent" />
            <img
              src={DefaultResturantIcon}
              className="rounded me-3 resturant-icon"
              alt="..."
            />
            <div className="d-flex flex-column">
              <p className="place-name">{r.name}</p>
              <p className="address">{r.address}</p>
            </div>
          </div>
        ))}
      </Carousel>
    );
  }
}

export default MyResturantsCarousel;
