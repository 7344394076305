import React, { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

import PieChart from "components/pieChart";
import useCurrencySymbol from "customHooks/useCurrencySymbol";
import CustomTable from "components/customTable/index.tsx";
import {
  generateCompetitorsTableData,
  getRandomNumber,
} from "views/occupancy/data";

import { useUserData } from "contexts/AuthContextManagement";

import TopFlop from "./topFlop";
import { getTableColumns } from "./utils";
import { Spinner } from "react-bootstrap";

const PieChartContainer = React.memo(({ pieCharts, t }) => (
  <div className="second-card pie-container">
    <div className="card h-100 mb-0">
      <div className="card-header d-flex align-items-center justify-content-between">
        <h2>{t("PieCharts")}</h2>
      </div>
      <div className="card-body card-pie-chart pb-0 pt-0">
        {pieCharts.map((pie, i) => (
          <PieChart
            key={i}
            data={pie}
            options={{
              legend: { enabled: false },
              title: {
                text: t(pie[0].name),
                verticalAlign: "bottom",
              },
              plotOptions: {
                pie: {
                  slicedOffset: 0,
                  size: "100%",
                  dataLabels: {
                    enabled: false,
                  },
                },
              },
            }}
          />
        ))}
      </div>
    </div>
  </div>
));

function LeftSide({
  formData,
  keyFigures,
  topFlopLoading,
  competitiveTopFlop,
  myRestaurantTopFlop,
  keyFiguresLoading,
}) {
  const { t } = useTranslation();
  const { currencySymbol } = useCurrencySymbol();
  const { selectedRestaurantId, isRestaurantLoaded, hasRetaurants } =
    useUserData();

  const tableColumns = useMemo(
    () => getTableColumns(t, currencySymbol, true),
    [t, currencySymbol]
  );

  const initialPieData = [
    [
      { name: t("Occupancy rate"), y: 0, color: "#6353EA" },
      { name: "", y: 0, color: "#E5E9FF" },
    ],
    [
      { name: t("Benefits"), y: 0, color: "#6353EA" },
      { name: "", y: 0, color: "#E5E9FF" },
    ],
    [
      { name: t("Avg. occupancy"), y: 0, color: "#E73858" },
      { name: "Remaining", y: 0, color: "#F4D1D7" },
    ],
    [
      { name: t("Avg. benefit"), y: 0, color: "#E73858" },
      { name: "Remaining", y: 0, color: "#F4D1D7" },
    ],
  ];

  const [tableData, setTableData] = useState([]);
  const [pieCharts, setpieCharts] = useState(initialPieData);
  const [isMysales, setIsMysales] = useState(true);
  const [isTop, setIsTop] = useState(true);

  useEffect(() => {
    setPieChartData();

    if (selectedRestaurantId !== "") {
      generateTableData(keyFigures);
    } else {
      generateTableData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formData,
    keyFigures,
    competitiveTopFlop,
    myRestaurantTopFlop,
  ]);

  const setPieChartData = () => {
    const newData = [];
    initialPieData.forEach((ele) => {
      const [el1, el2] = ele;
      el1.y = getRandomNumber();
      el2.y = 100 - el1.y;
      newData.push([el1, el2]);
    });
    setpieCharts(newData);
  };

  const generateTableData = (keyFigures = null) => {
    if (keyFigures) {
      setTableData(() => keyFigures);
      return;
    }
    setTableData(generateCompetitorsTableData(formData));
  };

  const topFlopContainer = () => {
    return (
      <div className="row second-card h-100">
        <div className="h-100 col-lg-6">
          <TopFlop
            heading={t("MySales")}
            isTop={isTop}
            setIsTop={setIsTop}
            isSecondColumn
            topFlopLoading={topFlopLoading}
            top={myRestaurantTopFlop?.top}
            flop={myRestaurantTopFlop?.flop}
          />
        </div>

        <div className="h-100 col-lg-6">
          <TopFlop
            heading="Competitive set's sales"
            isTop={isTop}
            setIsTop={setIsTop}
            isSecondColumn
            topFlopLoading={topFlopLoading}
            top={competitiveTopFlop?.top}
            flop={competitiveTopFlop?.flop}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="leftcontent leftcontent-market-view">
      <div className="main-container main-container-competitiveset h-100">
        <div className="card">
          <div className="card-header d-flex align-items-center justify-content-between card-navbtns">
            <h2>{t("CompetitiveSetKeyFigures")}</h2>
          </div>
          <div className="card-body">
            {!keyFiguresLoading && (
              <CustomTable columns={tableColumns} data={tableData} />
            )}
            {keyFiguresLoading && (
              <div className="w-100 d-flex justify-content-center card-spinner-container">
                <Spinner animation="border" variant="primary" />
              </div>
            )}
          </div>
        </div>
        <div className={`${isMysales ? "h-100" : ""}`}>
          <div className="pie-sales-navbtns divider">
            <ul className="navbtns mb-0">
              <li className={`${isMysales ? "active" : ""}`}>
                <button
                  className={`nav-link ${isMysales ? "active" : ""}`}
                  onClick={() => setIsMysales(true)}
                >
                  {t("PieCharts")}
                </button>
              </li>
              <li className={`${!isMysales ? "active" : ""}`}>
                <button
                  className={`nav-link ${!isMysales ? "active" : ""}`}
                  onClick={() => setIsMysales(false)}
                >
                  {t("MySales")}
                </button>
              </li>
            </ul>
          </div>
          <div
            className={`show-below-800 show-below-800-${
              isMysales ? "pie" : "topflop"
            } h-100`}
          >
            {isMysales && <PieChartContainer pieCharts={pieCharts} t={t} />}
            {!isMysales && topFlopContainer()}
          </div>
          <div className="show-above-800">
            <PieChartContainer pieCharts={pieCharts} t={t} />
            {topFlopContainer()}
          </div>
        </div>
      </div>
    </div>
  );
}

export default LeftSide;
