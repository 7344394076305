import React, { useState, useEffect, useRef } from "react";
import jsPDF from "jspdf";
import "assets/fonts/NunitoSans-Light-normal";

import { Card, Row, Col } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import request from "services/request";
import storage from "services/storage";
import CustomTable from "components/customTable/index.tsx";
import useCurrencySymbol from "customHooks/useCurrencySymbol";
import { useUserData } from "contexts/AuthContextManagement";
import { customToFixed } from "common/utils";
import { DEFAULT_ERROR_MESSAGE } from "common/constants";
import { useLoading } from "contexts/LoadingContextManagement";

import UploadModal from "./modal.tsx";
import DonationService from "./service";

import FullSoonLogo from "assets/images/cible_l.png";

/**
 * function to render when a user navigates to order-to-provider screen
 * @returns
 */
function OrderProvider() {
  const history = useHistory();
  const { t } = useTranslation();
  const { currencyNameSymbol } = useCurrencySymbol();
  let pdfRef = useRef(null);
  const [isModal, setIsModal] = useState(false);
  const currentUser = JSON.parse(storage.getItem("user"));
  const { selectedRestaurantId, restaurants } = useUserData();
  const [filteredTableData, setFilteredTableData] = useState([]);
  const { setLoading, setError } = useLoading();
  const charityDetails = DonationService.getSelectedCharity();

  var doc = new jsPDF({ format: "letter", unit: "px" });
  doc.setFont("NunitoSans-Light-normal", "normal");

  const currentRestaurant = restaurants.find(
    (r) => r.id === selectedRestaurantId
  );

  const tableColumns = [
    {
      dataField: "name",
      caption: "Products",
      className: "",
      style: { width: "100px", height: "60px" },
      headerStyle: { width: "100px", height: "60px" },
      type: "string",
    },
    {
      caption: "Unit",
      dataField: "unit",
      type: "string",
      className: "text-center",
      headerClassName: "text-center",
    },
    {
      caption: "Qty",
      dataField: "qty_to_donate",
      type: "string",
      className: "text-center",
      headerClassName: "text-center",
    },
    {
      dataField: "unit_price",
      caption: "Donation value",
      className: "text-center fw-bold",
      headerClassName: "text-center",
      type: "customRender",
      render: (col, it) => (
        <span>
          {customToFixed(
            parseFloat(it.qty_to_donate || 0) * parseFloat(it.unit_price || 0)
          )}
        </span>
      ),
    },
  ];

  /**
   * hook to be called whenever this component loads
   */
  useEffect(() => {
    const donations = DonationService.getSelectedItems();
    if (donations?.length) {
      setFilteredTableData(() => donations);
      return;
    }
    history.push({ pathname: "/donation" });
  }, []);

  const totoalDonationValue = () => {
    let total_sum = 0;
    filteredTableData.forEach((value, index) => {
      total_sum = total_sum + value.unit_price * value.qty_to_donate;
    });
    return total_sum;
  };

  const sendRequest = async (currentDoc) => {
    setLoading(true);
    const payload = {
      user_id: currentUser?.id,
      user_email: currentUser?.email,
      charity_id: charityDetails?.id,
      charity_email: charityDetails?.email,
      charity_name: charityDetails?.name,
      pickup_date: charityDetails.pickup_date.slice(0, 10),
      total_donation: totoalDonationValue(),
      restaurant_id: selectedRestaurantId,
      details: filteredTableData.map((i) => ({
        ingredient_id: i.id,
        name: i.name,
        format: i.format,
        unit: i.unit,
        unit_quantity: i.qty_to_donate,
        unit_price: i.unit_price,
      })),
    };
    try {
      const result = await request.post("/donations", payload, false);
      if (![200, 201].includes(result.status)) {
        throw new Error((await result.json())?.msg);
      }

      const resultBody = await result.json();

      let formData = new FormData();
      formData.append("file", currentDoc.output("blob"), "file.pdf");
      const resultFile = await request.bodyFile(
        `/donations/send-to-charity/${resultBody.id}`,
        formData
      );

      if (![200, 201].includes(resultFile.status)) {
        throw new Error((await result.json())?.msg);
      }

      setLoading(false);
      setIsModal(true);
    } catch (error) {
      setLoading(false);
      console.error(error);
      setError(error.message ?? DEFAULT_ERROR_MESSAGE);
    }
  };

  const sendToProvider = async () => {
    const ele = document.getElementById("print");

    doc.html(ele, {
      html2canvas: { scale: 0.57 },

      callback: async (currentDoc) => {
        pdfRef.current = currentDoc;
        let formData = new FormData();
        formData.append("image", currentDoc.output("blob"));
        currentDoc.addImage(FullSoonLogo, "PNG", 415, 30);

        sendRequest(currentDoc);
      },
    });
  };

  const onDownloadClick = () => {
    pdfRef.current?.save(`donation-details.pdf`);
  };

  const detailComponent = (type, className) => {
    const isDonation = type === "charity";
    const user = isDonation ? charityDetails : currentUser;

    return (
      <>
        <div className={`provider-details  ${className}`}>
          <div>
            <h5>{isDonation ? "Charity" : "Client"} details</h5>
          </div>

          <div className="mt-4 d-flex justify-content-between">
            <div>
              <label className="p-caption">
                {isDonation ? "Charity" : "Name, Surname"}
              </label>
              <br />
              <label className="p-value">
                {user?.name?.toUpperCase() ?? user?.username}
              </label>
            </div>
            <div>
              <label className="p-caption">
                {isDonation ? "Contact name" : "Restaurant"}
              </label>
              <br />
              <label className="p-value">
                {user?.contact_name ?? currentRestaurant?.name}
              </label>
            </div>
          </div>
          <div>
            <label className="p-caption">Email</label>
            <br />
            <label className="p-value">
              {user?.email_address ?? user?.email}
            </label>
          </div>
          <div>
            <label className="p-caption">Phone number</label>
            <br />
            <label className="p-value">
              {user?.phone_number ?? user?.contact_number}
            </label>
          </div>
          <div>
            <label className="p-caption">Address</label>
            <br />
            <label className="p-value mb-0">{user?.address}</label>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="wrappers orders-provider-container">
      <UploadModal
        show={isModal}
        onHide={() => {
          history.push("/donation");
          setIsModal(false);
        }}
        title="Thank you for your donation !"
        subTitle="Your donation has been sent successfully to the selected charity. We have just sent you a confirmation email including your donation details.
        Check carefully your inbox to receive the donation details."
        onDownloadClick={onDownloadClick}
      />

      <section className={`maincontent h-100 maincontent-collapsed`}>
        <Card className="p-5 order-container " id="order-container">
          <Row className="mb-4">
            <label className="order-back back" onClick={() => history.goBack()}>
              {"<"} {t("Back")}
            </label>
          </Row>
          <Row className="mb-3">
            <h3 className="heading-text fw-bold">Donation</h3>
          </Row>
          <Row className="h-100">
            <Col sm={4} className="details">
              {detailComponent("charity")}
              {detailComponent("client", "order-provider-client mt-5")}
            </Col>
            <Col sm={{ span: 6, offset: 2 }}>
              <h3 className="o-d">Donation details</h3>
              <div className="c-t-order-provider">
                <CustomTable
                  columns={tableColumns}
                  data={filteredTableData}
                  selectedKey="id"
                />
              </div>
              <div className="order-summary my-3">
                <div className="d-flex justify-content-between d-date">
                  <span className="caption">Pick up date</span>
                  <span className="value">
                    {charityDetails.pickup_date
                      ? charityDetails.pickup_date.slice(0, 10)
                      : charityDetails.pickup_date}
                  </span>
                </div>

                <div className="d-flex justify-content-between t-order">
                  <span className="caption">Total order</span>
                  <span className="value">
                    {totoalDonationValue()} {currencyNameSymbol}
                  </span>
                </div>
              </div>
              <div className="d-flex justify-content-end mt-3 selected-prod-div">
                <button
                  className="float-end place-order-btn"
                  onClick={sendToProvider}
                >
                  Confirm donation
                </button>
              </div>
            </Col>
          </Row>
        </Card>
      </section>
      <div className=" d-none d-print-block">
        <section
          className={`maincontent h-100 maincontent-collapsed p-5`}
          id="print"
        >
          <Row className="mb-4">
            <h5 className=" fw-bold">Donation details</h5>
            <div>
              <span>Pickup date:</span>
              <span>
                {charityDetails.pickup_date
                  ? charityDetails.pickup_date.slice(0, 10)
                  : charityDetails.pickup_date}
              </span>
            </div>
          </Row>
          <div className="d-flex flex-row">
            {detailComponent("charity", "details-tab p-d col")}
            {detailComponent("client", "details-tab c-d col")}
          </div>
          <Row className="mt-5">
            <div>
              <h5>Donation details</h5>
            </div>
            <Col sm={{ span: 12 }}>
              <div className="custom-table">
                <div className="tablescroll">
                  <table className="w-100 c-t">
                    <thead>
                      <tr style={{ background: "#fff" }}>
                        <th style={{ height: "50px", width: "70%" }}>
                          Products
                        </th>
                        <th className="text-center">Qty</th>
                        <th className="text-end">Donation value</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredTableData.map((it, i) => (
                        <tr key={i}>
                          <td style={{ height: "50px", width: "70%" }}>
                            {it.name}
                          </td>
                          <td className="text-center">{it.qty_to_donate}</td>
                          <td className="text-end">
                            {customToFixed(
                              parseFloat(it.qty_to_donate || 0) *
                                parseFloat(it.unit_price || 0)
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="order-summary mt-5">
                <div className="d-flex justify-content-between d-date">
                  <span className="caption">Pick up date</span>
                  <span className="value">
                    {charityDetails.pickup_date
                      ? charityDetails.pickup_date.slice(0, 10)
                      : charityDetails.pickup_date}
                  </span>
                </div>

                <div className="d-flex justify-content-between t-order">
                  <span className="caption">Total order</span>
                  <span className="value">
                    {totoalDonationValue()} {currencyNameSymbol}
                  </span>
                </div>
              </div>
              {/* <div>
                *Based on delivery frequency and not on the provider’s schedule.
              </div> */}
            </Col>
          </Row>
        </section>
      </div>
    </div>
  );
}

export default OrderProvider;
