import React, { useEffect } from "react";
import { Row } from "react-bootstrap";
import LeftSide from "./leftSide";
import RightSide from "./rightSide";
import { useUserData } from "contexts/AuthContextManagement";
import storage from "services/storage";

function Payment() {
  const { subscription, setSubscription } = useUserData();

  useEffect(() => {
    if(!subscription?.status){
      setSubscription(JSON.parse(storage.getItem("subscription")))
    }
  },[])

  return (
    <Row className="signin-container">
      <LeftSide  />
      <RightSide  />
    </Row>
  );
}

export default Payment;
