import { useEffect, useState } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import useCurrencySymbol from "customHooks/useCurrencySymbol";
import { useUserData } from "contexts/AuthContextManagement";
// import { getRandomNumber } from "views/occupancy/data";

import { utcTimezoneFormat } from "common/utils";
import dummyForecastAnalytics from "../../data/forecast_analytics.json";
import request from "services/request";

import OccupancyBlueIcon from "assets/images/occupancy_blue.png";
import DollarBlueIcon from "assets/images/dollar_circle_blue.png";
import ReceiptIcon from "assets/images/receipt_blue.png";

const start_date = moment().format("YYYY-MM-DD");
const end_date = moment().format("YYYY-MM-DD");

/**
 * Analytics widget on the right side of the screen
 * @param {Object} param0
 * @returns
 */
function AnalyticsWidget() {
  const { 
    selectedRestaurant, 
    selectedRestaurantId, 
    isRestaurantLoaded, 
    hasRetaurants 
  } = useUserData();
  const { t } = useTranslation();
  const { currencySymbol } = useCurrencySymbol();
  const [loading, setLoading] = useState(false)
  const [analyticsData, setAnalyticsData] = useState(null)

  // const { loading, data: analyticsData } = useFetch(`forecast/analytics`, {
  //   restaurant_id: selectedRestaurantId,
  //   ...timezoneFormat(
  //     start_date, 
  //     end_date, 
  //     selectedRestaurant?.timezone
  //   ),
  // });

  useEffect(async () => {
    if(selectedRestaurantId) {
      setLoading(true)
      const result = await request.get(
        `forecast/analytics`,
        {
          restaurant_id: selectedRestaurantId,
          ...utcTimezoneFormat(
            start_date, 
            end_date, 
            selectedRestaurant?.timezone
          ),
        }
      );
      setAnalyticsData(result)
      setLoading(false)
    }
  }, [selectedRestaurantId])

  let forecastedData = analyticsData || {};

  // if (!loading && !analyticsData) {
  //   forecastedData = dummyForecastAnalytics;
  // }

  // forecastedData.waiting_time = getRandomNumber(5, 150);

  if (isRestaurantLoaded && !hasRetaurants) {
    forecastedData = dummyForecastAnalytics;
  }

  return (
    <div className="card  cardlist">
      <div className="card-body">
        <div className="d-flex total_cont">
          <div className="flex-shrink-0">
            <span className="boxicon">
              <img src={OccupancyBlueIcon} alt="" />
            </span>
          </div>
          <div className="flex-grow-1 ms-3">
            <h2>
              <strong>{loading ? "---" : forecastedData?.total_guests}</strong>
            </h2>
            <p className="text-muted">{t("TotalGuests")}</p>
          </div>
        </div>
        <div className="d-flex total_cont">
          <div className="flex-shrink-0">
            <span className="boxicon">
              <img src={DollarBlueIcon} alt="" />
            </span>
          </div>
          <div className="flex-grow-1 ms-3">
            <h2>
              <strong>
                {loading ? "---" : forecastedData?.gross_revenue} {currencySymbol}
              </strong>
            </h2>
            <p className="text-muted">{t("GrossRevenue")}</p>
          </div>
        </div>
        <div className="d-flex total_cont">
          <div className="flex-shrink-0">
            <span className="boxicon">
              <img src={ReceiptIcon} alt="" />
            </span>
          </div>
          <div className="flex-grow-1 ms-3">
            <h2>
              <strong>
                {loading ? "---" : forecastedData?.average_ticket} {currencySymbol}
              </strong>
            </h2>
            <p className="text-muted">{t("AverageTicket")}</p>
          </div>
        </div>
        {/* <div className="d-flex">
          <div className="flex-shrink-0">
            <span className="boxicon">
              <img src={ClockBlueIcon} alt="" />
            </span>
          </div>
          <div className="flex-grow-1 ms-3">
            <h2>
              <strong>
                {forecastedData?.average_minutes_spent ?? "---"} {t("Mins")}
              </strong>
            </h2>
            <p className="text-muted">{t("Average time spent")}</p>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default AnalyticsWidget;
