// @ts-nocheck

import { MEAL_CATEGORIES, UNITS } from "common/constants";
import { getRandomNumber } from "views/occupancy/data";
import tableMealsDummy from "../../data/meals1.json";
import tableIngredientsDummy from "../../data/ingredients.json";
import tableProviderIngredientsDummy from "../../data/provider_ingredients.json";
import moment from "moment";
import convertUnits from 'convert-units';




export const getIngredientsDummyData = () => {
  // const ingredient1 = () => ({
  //   id: getRandomNumber(10, 1000),
  //   name: "Pasta coquillette",
  //   unit: "g",
  //   unit_price: getRandomNumber(),
  //   provider: { id: "1", name: "Panzani" },
  //   category: "pulses",
  //   format: "500",
  //   allergens: [
  //     { id: "93cfe10c-18dd-4e33-af85-aa4a94410115", name: "Eggs" },
  //     { id: "5f610fba-dddb-427c-a51e-de425fd0608e", name: "Dairy" },
  //   ],
  // });
  // const ingredient2 = () => ({
  //   id: getRandomNumber(1000, 10000),
  //   name: "Mozarella",
  //   unit: "g",
  //   unit_price: getRandomNumber(),
  //   provider: { id: "2", name: "Galbani" },
  //   category: "milkProduct",
  //   format: "1000",
  //   allergens: [
  //     { id: "e7d072f4-2ec9-4d46-9517-ce2b3aa52375", name: "Crustaceans" },
  //     { id: "eb4e532f-94e9-4ae0-a584-40d39eff97c4", name: "Molluscs" },
  //     { id: "50b923ff-8d91-4fa1-ada2-63ac2db49ab6", name: "Soya" },
  //   ],
  // });

  // return Array(25)
  //   .fill({})
  //   .map((it, i) => ({
  //     ...(i % 2 === 0 && ingredient1()),
  //     ...(i % 2 !== 0 && ingredient2()),
  //   }));
  return tableIngredientsDummy.ingredients
};

export const getProviderIngredientsDummyData = () => {
  return tableProviderIngredientsDummy.provider_ingredients
};

export const getProvidersDummy = () => {
  return {
    providers: [
      {
        delivery_frequency: null,
        id: "1",
        name: "Panzani",
        contact_name: "panzani",
        contact_number: "98569856",
        email_address: "panzani@fullsoon.com",
        address: "central france",
        country: null,
        state: null,
        city: null,
        zip_code: null,
        category: "france",
        restaurant_id: "1",
      },
      {
        delivery_frequency: {
          delivery_days: ["monday", "friday"],
          periodicity: "every week",
          periodicity_start_date: "2022-01-01",
          periodicity_end_date: "2023-01-01",
          delivery_start_time: "15:00",
          delivery_end_time: "15:30",
          delivery_duration: "30 minutes",
        },
        id: "2",
        name: "Galbani",
        contact_name: "galbani",
        contact_number: "56256325",
        email_address: "galbani@fullsoon.com",
        address: "france",
        country: "france",
        state: null,
        city: null,
        zip_code: null,
        category: "france",
        restaurant_id: "2",
      },
    ],
  };
};

export const getDummyProviders = () =>
  new Array(3).fill({}).map((_, index) => ({
    address: "18 Rue Boursault 75017 Paris FRANCE",
    category: "france",
    city: "paris",
    contact_name: "John Doe",
    contact_number: "+33 756897654",
    country: "france",
    delivery_frequency: {
      delivery_days: ["monday", "friday"],
      periodicity: "every week",
      periodicity_start_date: "2022-01-01",
      periodicity_end_date: "2023-01-01",
      delivery_start_time: "15:00",
      delivery_end_time: "15:30",
      delivery_duration: "30 minutes",
    },
    email_address: "metro.provider@gmail.com",
    id: getRandomNumber(10, 1000),
    name: index == 0 ? "Metro" : index == 1 ? "FRANPRIX" : "BANAGRUMES",
    restaurant_id: getRandomNumber(10, 1000),
    state: "Île-de-France",
    zip_code: 75001,
  }));

export const getDummyOrders = () => {
  const purchase_orders = new Array(10).fill({}).map((_, index) => ({
    createdAt: "2022-03-14T05:52:26.000Z",
    estimated_delivery_date: ["2021-12-08", "2021-12-05"][index % 2],
    id: getRandomNumber(10, 1000),
    is_delivered: [true, false][Math.floor(index % 2)],
    order_number: ["#3588", "#3589"][Math.floor(index % 2)],
    provider: {
      id: getRandomNumber(10, 1000),
      name: ["METRO", "BANAGRUMES"][Math.floor(index % 2)],
    },
    purchase_items: new Array(5).fill({}).map((_, index2) => ({
      format: Math.floor(Math.random() * 100.0 + 1) + ".00",
      id: getRandomNumber(10, 1000),
      ingredient_id: getRandomNumber(10, 1000),
      is_delivered: [true],
      name: `Item # ${getRandomNumber(1, 100)}`,
      product_quantity: getRandomNumber(1, 10),
      unit: UNITS[Math.floor(Math.random() * UNITS.length)]?.label,
      product_price: Math.floor(Math.random() * 100.0 + 1) + ".00",
      provider_reference_name: ["FRITES MC CAIN EXPRESS 10 KG", "STEAK 120 gr 6 KG (INICIA)","NUGGETS 800 gr","PAIN 2141 48 PCS HARRIS"][Math.floor(index % 2)],
      conditioning_name : ["box", "packets"][Math.floor(index % 2)],
    })),
    restaurant_id: getRandomNumber(10, 1000),
    total_price: Math.floor(Math.random() * 100.0 + 1) + ".00",
  }));

  return {
    purchase_orders: purchase_orders,
    limit: 25,
    order_by: "DESC",
    page: 1,
    sort_by: "createdAt",
    total_pages: 1,
    total_results: 10,
  };
};

export const DummyCompetitiveSetData = [
  {
    address: "8 Rue Irène et Frédéric Joliot-Curie, 95100 Argenteuil, France",
    city: ["Argenteuil"],
    country: "FRANCE",
    id: 1,
    location_id: 45,
    name: "G la Dalle - Argenteuil Orgemont ",
    siret_number: 88371518700018,
    state: "paris",
    timezone: "Europe/Paris",
    type: "Italian",
    zip_code: "54000",
  },
  {
    address: "44 Rue Louis Talamoni, 94500 Champigny-sur-Marne, France",
    city: ["Champigny-sur-Marne"],
    country: "FRANCE",
    id: 2,
    location_id: 67,
    name: "G la Dalle - Champigny",
    siret_number: 55,
    state: "paris",
    timezone: "Europe/Paris",
    type: "Italian",
    zip_code: "54000",
  },
  {
    address: "4 Rue Eugène Berrurier, 78700 Conflans-Sainte-Honorine, France",
    city: ["Conflans-Sainte-Honorine"],
    country: "FRANCE",
    id: 3,
    location_id: 67,
    name: "G la Dalle - Conflans",
    siret_number: 893489310,
    state: "paris",
    timezone: "Europe/Paris",
    type: "Italian",
    zip_code: "54000",
  },
  {
    address: "7 Pl. Pierre Mendès France, 91000 Évry-Courcouronnes, France",
    city: ["Évry-Courcouronnes"],
    country: "FRANCE",
    id: 4,
    location_id: 67,
    name: "G la Dalle - Evry",
    siret_number: 89113764800012,
    state: "paris",
    timezone: "Europe/Paris",
    type: "Italian",
    zip_code: "54000",
  },
];

export const getDummyCompetitiveSet = () => {
  return getDummyAddCompetitiveSet().slice(0, 3);
};

export const getConvertibleUnitOptions = (unit: string) => {
  if(!unit || unit !== 'kg' && unit !== 'g' && unit !== 'cl' && unit !== 'ml' && unit !== 'L' && unit !== 'PIECE' && unit !== 'cm' && unit !== 'm') {
    return []
  }
  if(unit === 'item' || unit === 'Colis' || unit === 'pièces' || unit === 'Boite' || unit === 'Colis de 24' || unit === 'Carton de 10' || unit === 'Colis de 200 unités' || unit === 'Colis de 8 barquettes') {
    return []
  }
 return UNITS.filter(element => convertUnits().from(unit).possibilities().includes(element.id))
}

export const getDummyAddCompetitiveSet = () =>
  new Array(5).fill({}).map((_, index) => ({
    address: "18 Rue Boursault 75017 Paris FRANCE",
    city: ["Paris (10e)", "Paris (9e)"][index % 2],
    country: "FRANCE",
    id: index,
    location_id: getRandomNumber(10, 1000),
    name: "Pizzeria Parigi",
    siret_number: getRandomNumber(10, 1000),
    state: "paris",
    timezone: "Europe/Paris",
    type: "Italian",
    zip_code: "54000",
  }));

export const getMealsDummyData = () => {
  // const getRandomType = () => {
  //   const random = Math.floor(Math.random() * MEAL_CATEGORIES.length);
  //   return MEAL_CATEGORIES[random]?.label;
  // };
  // return Array(25)
  //   .fill({})
  //   .map((it, i) => ({
  //     id: getRandomNumber(10, 1000),
  //     name: "Banana Bread",
  //     type: getRandomType(),
  //     servings: getRandomNumber(1, 15),
  //     cost: getRandomNumber(100, 1500),
  //     selling_price_per_person: getRandomNumber(100, 1500),
  //     margin: getRandomNumber(0, 1200),
  //     image_path: null,
  //     item_id: getRandomNumber(10, 1000),
  //     restaurant_id: getRandomNumber(10, 1000),
  //     ingredients: [],
  //     allergens: [
  //       { id: "93cfe10c-18dd-4e33-af85-aa4a94410115", name: "Eggs" },
  //       { id: "5f610fba-dddb-427c-a51e-de425fd0608e", name: "Dairy" },
  //     ],
  //   }));
  return tableMealsDummy.meals
};

// export const ingredientsKeys = {
//   name: "Name",
//   unit: "Unit",
//   category: "Category",
//   allergens: "Allergens",
//   cost_excl_tax: "Cost",
// };

// export const requiredIngredientsKeys = {
//   name: "Name",
//   unit: "Unit",
//   cost_excl_tax: "Cost",
// };

// export const mealsKeys = {
//   name: "NAME OF THE DISH",
//   ingredient: "Ingredient",
//   ingredient_unit: "UNIT",
//   ingredient_quantity: "Quantity",
//   ingredient_cost: "cost",
//   servings: "SERVINGS",
//   type: "DISH TYPE",
//   total_price: "Selling price"
// };

// export const requiredMealsKeys = {
//   name: "NAME OF THE DISH",
//   servings: "SERVINGS",
//   type: "DISH TYPE",
// };

// export const providerIngredientsKeys = {
//   provider_name: "Provider Name",
//   name: "Name",
//   provider_reference_name: "Provider Reference Name",
//   provider_reference_number: "Provider Reference Number",
//   price_excl_tax: "Price",
//   tax: "Tax",
//   conditioning_name: "Conditioning Name",
//   conditioning_unit: "Conditioning Unit",
//   conditioning_quantity: "Conditioning Quantity",
//   recipe_unit: "Recipe Unit",
//   recipe_unit_quantity: "Recipe Unit Quantity",
//   storage_unit: "Storage Unit",
//   min_stock: "Min Stock",
//   max_stock: "Max Stock",
//   ingredient: "My Ingredient",
// };

// export const requiredProviderIngredientsKeys = {
//   provider_name: "Provider Name",
//   name: "Name",
//   price_excl_tax: "Price",
//   tax: "Tax",
//   conditioning_name: "Conditioning Name",
//   conditioning_unit: "Conditioning Unit",
//   conditioning_quantity: "Conditioning Quantity",
//   recipe_unit: "Recipe Unit",
//   recipe_unit_quantity: "Recipe Unit Quantity",
//   storage_unit: "Storage Unit",
// }

export function getTimeRange(openTime, closeTime, slotInterval) {
  const startTime = moment(openTime, "HH:mm");
  const endTime = moment(closeTime, "HH:mm");
  const allTimes = [];

  // Loop over the times - only pushes time with 30 minutes interval
  while (startTime <= endTime) {
    //Push times
    allTimes.push(startTime.format("HH:mm"));
    //Add interval of 30 minutes
    startTime.add(slotInterval, "minutes");
  }

  return allTimes;
}

export const ingredientsKeys = {
  name: "*NOM",
  unit: "*UNITE",
  category: "CATEGORIE",
  allergens: "ALLERGENES",
  cost_excl_tax: "COUT HT",
};

export const requiredIngredientsKeys = {
  name: "*NOM",
  unit: "*UNITE",
};

export const providersKeys = {
  name: "*NOM FOURNISSEUR",
  siren_number: "SIREN",
  address: "*ADRESSE",
  zip_code: "*CODE POSTAL",
  city: "*VILLE",
  country: "*PAYS",
  contact_name: "*NOM DU CONTACT",
  contact_number: "*TELEPHONE",
  fax: "FAX",
  email_address: "*EMAIL DE COMMANDE",
  credit_email_address: "EMAIL DEMANDE D'AVOIRS",
  min_order_value: "*MINIMUM DE COMMANDE (euros)",
  min_order_quantity: "*MINIMUM DE COMMANDE (colis)",
};

export const requiredProvidersKeys = {
  name: "*NOM FOURNISSEUR",
  address: "*ADRESSE",
  zip_code: "*CODE POSTAL",
  city: "*VILLE",
  country: "*PAYS",
  contact_name: "*NOM DU CONTACT",
  contact_number: "*TELEPHONE",
  email_address: "*EMAIL DE COMMANDE",
  min_order_value: "*MINIMUM DE COMMANDE (euros)",
  min_order_quantity: "*MINIMUM DE COMMANDE (colis)",
};



export const mealsKeys = {
  name: "NOM DU PLAT",
  ingredient: "Ingrédient",
  ingredient_unit: "UNITE",
  ingredient_quantity: "Quantité",
  ingredient_cost: "coût (€)",
  servings: "PORTIONS",
  type: "TYPE DE PLAT",
  total_price: "Prix de vente"
};

export const requiredMealsKeys = {
  name: "NOM DU PLAT",
  servings: "PORTIONS",
  type: "TYPE DE PLAT",
};

export const providerIngredientsKeys = {
  provider: "*Nom du fournisseur",
  name: "*Nom du produit / article",
  provider_reference_name: "Nom de l'article pour le fournisseur (si différent)",
  provider_reference_number: "Code article pour fournisseur",
  price_excl_tax: "*Prix H.T",
  tax: "*Taux de TVA",
  conditioning_name: "*Nom Conditionnement",
  conditioning_unit: "*Unité de Conditionnement (g;kg;cl;ml;L;PIECE;cm;m;)",
  conditioning_quantity: "*Quantité Conditionnement",
  recipe_unit: "*Unité recette (g;kg;cl;ml;L;PIECE;cm;m;)",
  recipe_unit_quantity: "*Quantité unité recette",
  storage_unit: "*Unité de stockage (Recette ou Conditionnement)",
  min_stock: "Min Stock",
  max_stock: "Max Stock",
  ingredient: "Mes Ingrédients Liées",
};

export const requiredProviderIngredientsKeys = {
  provider: "*Nom du fournisseur",
  name: "*Nom du produit / article",
  price_excl_tax: "*Prix H.T",
  tax: "*Taux de TVA",
  conditioning_name: "*Nom Conditionnement",
  conditioning_unit: "*Unité de Conditionnement (g;kg;cl;ml;L;PIECE;cm;m;)",
  conditioning_quantity: "*Quantité Conditionnement",
  recipe_unit: "*Unité recette (g;kg;cl;ml;L;PIECE;cm;m;)",
  recipe_unit_quantity: "*Quantité unité recette",
  storage_unit: "*Unité de stockage (Recette ou Conditionnement)",
}

export const createKey = (row, index) =>
  row.reduce((total, current) => total + current, `${index}`);

export const columnMapping = (mappingObject,data) => {
    const updatedArray = data.map((item) => {
      const updatedItem = {};
      for (const key in mappingObject) {
        updatedItem[key] = item[mappingObject[key]];
      }
      return updatedItem;
    });

    return updatedArray
}

export const CATEGORY_TRANSLATIONS = [
  { fr: "Œufs", en: "eggs"},
  { fr: "Huiles & Beurre", en: "oilsAndButter" },
  { fr: "Boissons", en: "drinks" },
  { fr: "Fruits & Légumes", en: "fruitsAndVegetables" },
  { fr: "Viande & Poisson", en: "meatFish" },
  { fr: "Pâtes, Riz & Légumineuses", en: "pastaAndRiceAndLegumes"},
  { fr: "Pâtes", en: "pasta" },
  { fr: "Riz & Légumineuses", en: "riceAndLegumes" },
  { fr: "Sauces", en: "sauces" },
  { fr: "Fromage", en: "cheese" },
  { fr: "Pains & Boulangerie", en: "breadsAndBakery" },
  { fr: "Emballages", en: "packaging" },
  { fr: "Laits", en: "milks" },
  { fr: "Nettoyage", en: "cleaning" },
  { fr: "Autres", en: "others" },
]

export const MEAL_TYPE_TRANSLATIONS = [
  { fr: "PLAT PRINCIPAL", en: "mainDishes"},
  { fr: "ENTREE", en: "starters" },
  { fr: "DESSERT", en: "desserts" },
  { fr: "BOISSON", en: "drinks" },
  { fr: "OPTION", en: "option" },
  { fr: "RECETTE INTERMEDIAIRE", en: "intermediate-recipe" },
]

export const ALLERGENS_TRANSLATIONS = [
  { fr: "GLUTEN", en: "Gluten"},
  { fr: "ŒUFS", en: "Eggs" },
  { fr: "LAIT", en: "Dairy" },
  { fr: "POISSONS", en: "Fish" },
  { fr: "FRUITS A COQUES", en: "Nuts" },
  { fr: "MOUTARDE", en: "Mustard" },
  { fr: "GRAINES DE SESAME", en: "Sesame" },
  { fr: "LUPIN", en: "Lupin" },
  { fr: "MOLLUSQUES", en: "Molluscs" },
]

export const STORAGE_UNIT_TRANSLATIONS = [
  { fr: "Recette", en: "recipe"},
  { fr: "Conditionnement", en: "conditioning"},
]

export function translateFrenchToEnglish(word, translation_data) {
  // Find the translation in the CATEGORY_TRANSLATIONS array
  const translation = translation_data.find(category => category.fr === word);

  // If a translation is found, return the English word; otherwise, return the input word
  return translation ? translation.en?.toString() : word?.toString();
}


export const  getImportedFileFormatData = (data, isMapped, localModalTitle, t) => {

  let dataArray = data;
  if(isMapped) {
    const [properties, ...values] = data;
    dataArray = values.map(valueArray => {
      const resultObj = {};
      properties.forEach((property, index) => {
        resultObj[property] = valueArray[index];
      });
      return resultObj;
    });
  }
  
  let error = []
  let rows = dataArray
  if(localModalTitle.current === 'AddMultipleProviderIngredients'){
    rows.map((i, indx)=> {
      if(!i.provider || i.provider == undefined || i.provider == ""){
      error.push({index: indx + 1,colIndex: 0,property_type: "string", message: (`${t("ProviderName")} ${t("is required")}`)})
      }
      if(!i?.name || i.name == undefined || i.name == ""){
        error.push({index: indx + 1,colIndex: 1,property_type: "string", message: (`${t("ProductName")} ${t("is required")}`)})
      }
      if(!i?.price_excl_tax){
        error.push({index: indx + 1,colIndex: 4,property_type: "number", message: (`${t("Price")} ${t("Can't be empty")}`)})
      }
      if(isNaN(i.price_excl_tax) || i.price_excl_tax < 0) {
        error.push({index: indx + 1,colIndex: 4,property_type: "number", message: (`${t("Price")} ${t("must be a positive number")}`)})
      }
      if(!i?.tax) {
        error.push({index: indx + 1,colIndex: 5,property_type: "number", message: (`${t("Tax")} ${t("Can't be empty")}`)})
      }
      if(isNaN(i.tax) || i.tax < 0){
        error.push({index: indx + 1,colIndex: 5,property_type: "number", message: (`${t("Tax")} ${t("must be a positive number")}`)})
      }
      if(!i?.conditioning_name || i?.conditioning_name == undefined || i?.conditioning_name == ""){
        error.push({index: indx + 1,colIndex: 6,property_type: "string", message: (`${t("ConditioningName")} ${t("is required")}`)})
      }
      if(!i?.conditioning_quantity) {
        error.push({index: indx + 1,colIndex: 8,property_type: "number", message: (`${t("ConditioningQuantity")} ${t("Can't be empty")}`)})
      }
      if(isNaN(i.conditioning_quantity) || i.conditioning_quantity < 0){
          error.push({index: indx + 1,colIndex: 8,property_type: "number", message: (`${t("ConditioningQuantity")} ${t("must be a positive number")}`)})
      }
      if(!i?.recipe_unit_quantity) {
        error.push({index: indx + 1,colIndex: 10,property_type: "number", message: (`${t("RecipeUnitQuantity")} ${t("Can't be empty")}`)})
      }
      if(isNaN(i.recipe_unit_quantity) || i.recipe_unit_quantity < 0){
        error.push({index: indx + 1,colIndex: 10,property_type: "number", message: (`${t("RecipeUnitQuantity")} ${t("must be a positive number")}`)})
      }
      if(!i?.conditioning_unit || i?.conditioning_unit == undefined || ""){
          error.push({index: indx + 1,colIndex: 7, property_type: "string", message: (`${t("ConditioningUnit")} ${t("Can't be empty")}`)})
      }
      if(!i?.storage_unit || i.storage_unit == undefined || i.storage_unit == ""){
        error.push({index: indx + 1,colIndex: 11,property_type: "string", message: (`${t("StorageUnit")} ${t("Can't be empty")}`)})
      }
      if(!i?.recipe_unit || i?.recipe_unit == undefined || ""){
        error.push({index: indx + 1,colIndex: 9,property_type: "string", message: (`${t("RecipeUnit")} ${t("Can't be empty")}`)})
      }
      if(!i?.ingredient || i?.ingredient == undefined || ""){
        error.push({index: indx + 1,colIndex: 14,property_type: "string", message: (`${t("Ingredient")} ${t("Can't be empty")}`)})
      }
    }).filter(index => index !== undefined)
  }
  if(localModalTitle.current === 'AddMultipleMeals'){
    rows.map((i, indx)=> {
      if(!i?.name || i?.name == undefined || i?.name === ""){
          error.push({index: indx + 1,colIndex: 0,property_type: "string", message: (`${t("MealName")} ${t("Can't be empty")}`)})
      }
      if(!i?.ingredient || i?.ingredient == undefined || i?.ingredient === ""){
        error.push({index: indx + 1,colIndex: 1,property_type: "string", message: (`${t("IngredientName")} ${t("Can't be empty")}`)})
    }
      if(!i?.type || i?.type == undefined || i?.type === ""){
        error.push({index: indx + 1,colIndex: 6, property_type: "string", message: (`${t("MealType")} ${t("Can't be empty")}`)})
      }
      if(!i?.ingredient_quantity) {
        error.push({index: indx + 1,colIndex: 3, property_type: "number", message: (`${t("IngredientQuantity")} ${t("Can't be empty")}`)})
      } 
      if(isNaN(i.ingredient_quantity) || i.ingredient_quantity < 0){
        error.push({index: indx + 1,colIndex: 3, property_type: "number", message: (`${t("IngredientQuantity")} ${t("must be a positive number")}`)})
      } 
      if(!i?.ingredient_unit || i?.ingredient_unit == undefined || i?.ingredient_unit === ""){
        error.push({index: indx + 1,colIndex: 2, property_type: "string", message: (`${t("IngredientUnit")} ${t("Can't be empty")}`)})
      }
    }).filter(index => index !== undefined)
  }

  if(localModalTitle.current === 'AddMultipleIngredients') {
    rows.map((i, indx)=> {
      if(!i?.name || i.name == undefined || i.name === ""){
        error.push({index: indx + 1,colIndex: 0, property_type: "string", message: (`${t("IngredientName")} ${t("Can't be empty")}`)})
      }
      if(!i?.unit || i.unit == undefined || i.unit === ""){
        error.push({index: indx + 1,colIndex: 1, property_type: "string", message: (`${t("IngredientUnit")} ${t("Can't be empty")}`)})
      }
      if(!i?.category || i.category == undefined || i.category === ""){
        error.push({index: indx + 1,colIndex: 2, property_type: "string", message: (`${t("IngredientCategory")} ${t("Can't be empty")}`)})
      }
      if(i.cost_excl_tax !== undefined && (isNaN(i.cost_excl_tax) || i.cost_excl_tax < 0)){
        error.push({index: indx + 1,colIndex: 4, property_type: "number", message: (`${t("IngredientCost")} ${t("must be a positive number")}`)})
      }
    }).filter(index => index !== undefined)
  }

if(localModalTitle.current === 'AddMultipleProviders') {
  rows.map((i, indx)=> {
    if(!i?.name || i.name == undefined || i.name === ""){
      error.push({index: indx + 1,colIndex: 0, property_type: "string", message: (`${t("ProviderName")} ${t("Can't be empty")}`)})
    }
    if(!i?.address || i.address == undefined || i.address === ""){
      error.push({index: indx + 1,colIndex: 2, property_type: "string", message: (`${t("ProviderAddress")} ${t("Can't be empty")}`)})
    }
    if(!i?.zip_code || i.zip_code == undefined || i.zip_code === ""){
      error.push({index: indx + 1,colIndex: 3, property_type: "string", message: (`${t("ProviderZipCode")} ${t("Can't be empty")}`)})
    }
    if(!i?.city || i.city == undefined || i.city === ""){
      error.push({index: indx + 1,colIndex: 4, property_type: "string", message: (`${t("ProviderCity")} ${t("Can't be empty")}`)})
    }
    if(!i?.country || i?.country == undefined || i?.country === ""){
      error.push({index: indx + 1,colIndex: 5, property_type: "string", message: (`${t("ProviderCountry")} ${t("Can't be empty")}`)})
    }
    if(!i?.contact_name || i?.contact_name == undefined || i?.contact_name === ""){
      error.push({index: indx + 1,colIndex: 6, property_type: "string", message: (`${t("ProviderContactName")} ${t("Can't be empty")}`)})
   }
   if(!i?.contact_number || i?.contact_number == undefined || i?.contact_number === ""){
      error.push({index: indx + 1,colIndex: 7, property_type: "string", message: (`${t("ProviderContactNumber")} ${t("Can't be empty")}`)})
   }
   if(!i?.email_address || i?.email_address == undefined || i?.email_address === "" || !/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(i?.email_address)){
      error.push({index: indx + 1,colIndex: 9, property_type: "string", message: (`${t("ProviderEmailAddress")} ${t("may be empty or invalid")}`)})
   }
   if(!i?.min_order_value){
      error.push({index: indx + 1,colIndex: 11, property_type: "number", message: (`${t("MinOrderValue")} ${t("Can't be empty")}`)})
   }
   if(isNaN(i.min_order_value) || i.min_order_value < 0) {
      error.push({index: indx + 1,colIndex: 11, property_type: "number", message: (`${t("MinOrderValue")} ${t("must be a positive number")}`)})
   }
   if(!i?.min_order_quantity){
      error.push({index: indx + 1,colIndex: 12, property_type: "number", message: (`${t("MinOrderQuantity")} ${t("Can't be empty")}`)})
   }
   if(isNaN(i.min_order_quantity) || i.min_order_quantity < 0) {
      error.push({index: indx + 1,colIndex: 12, property_type: "number", message: (`${t("MinOrderQuantity")} ${t("must be a positive number")}`)})
   }
}).filter(index => index !== undefined)
}

  if(localModalTitle.current === 'AddMultipleMeals'){
    rows = rows.map(item => {
      if(!item?.servings){
        item.servings = 1
      }
      return item
    })
  }
    rows = rows.map(item => {
      if(item?.recipe_unit){
        item.recipe_unit = item.recipe_unit.toLowerCase()
      }
      if (item?.conditioning_unit){
        item.conditioning_unit = item.conditioning_unit.toLowerCase()
      }
      if (item?.ingredient_unit){
        item.ingredient_unit = item.ingredient_unit.toLowerCase()
      }
      if (item?.unit){
        item.unit = item.unit.toLowerCase()
      }
      return item
    })
  return {rows, error}
}