import React from "react";

import LeftSide from "./leftSide";
import "./index.scss";

function Donation() {

  return (
    <>
      <LeftSide />
    </>
  );
}

export default Donation;
