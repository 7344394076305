import React, { useState, useEffect, useMemo } from "react";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import { useTranslation } from "react-i18next";

import useCurrencySymbol from "customHooks/useCurrencySymbol";
import CustomTable from "components/customTable";
import {
  generatemyResturantTableData,
  myResturants,
} from "views/occupancy/data";

import { useUserData } from "contexts/AuthContextManagement";

import TopFlop from "./topFlop";
import { getTableColumns } from "./utils";

import MyResturantsCarousel from "./customCarousel";

import "react-multi-carousel/lib/styles.css";

const center = {
  lat: 27.32457,
  lng: -80.3339,
};

function MyResturants({
  formData,
  keyFigures,
  topFlopLoading,
  myRestaurantTopFlop,
}) {
  const { t } = useTranslation();
  const { currencySymbol } = useCurrencySymbol();
  const { selectedRestaurantId, isRestaurantLoaded, hasRetaurants } =
    useUserData();

  const [tableData, setTableData] = useState([]);
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [isTop, setIsTop] = useState(true);
  const [zoom, setZoom] = useState(12);

  const tableColumns = useMemo(
    () => getTableColumns(t, currencySymbol, false),
    [t, currencySymbol]
  );

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
  });

  // const [map, setMap] = React.useState(null);

  const onLoad = React.useCallback(function callback(map) {
    // setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    //setMap(null);
  }, []);

  useEffect(() => {
    if (selectedRestaurantId !== "") {
      generateTableData(keyFigures);
    } else {
      generateTableData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formData,
    keyFigures,
    myRestaurantTopFlop,
    selectedRestaurantId,
    isRestaurantLoaded,
  ]);

  const generateTableData = (keyFigures = null) => {
    if (keyFigures) {
      setTableData(() => keyFigures);
      return;
    }
    setTableData(generatemyResturantTableData(formData));
  };

  const onSelectPlace = (place) => {
    setZoom(17);
    setSelectedPlace(place);
  };

  const getCenter = () => {
    if (selectedPlace) {
      const { latitude: lat, longitude: lng } = selectedPlace;
      return { lat, lng };
    }
    if (formData?.myRestaurants?.length) {
      const { latitude: lat, longitude: lng } = formData?.myRestaurants[0];
      return { lat, lng };
    }
    if (myResturants?.length) {
      const { latitude: lat, longitude: lng } = myResturants[0];
      return { lat, lng };
    }
    return center;
  };

  return (
    <>
      <div className="leftcontent leftcontent-market-view">
        <div className="main-container main-container-resturants h-100">
          <div className="card first-card">
            <div className="card-header d-flex align-items-center justify-content-between card-navbtns">
              <h2>{t("My restaurants - key figures")}</h2>
            </div>
            <div className="card-body inherit-height">
              <CustomTable
                columns={tableColumns.map((c) => ({
                  ...c,
                  caption:
                    t(c.caption.split(" ")[0]) +
                    " " +
                    t(c.caption.split(" ")[1]),
                }))}
                data={tableData}
              />
            </div>
          </div>
          <div className="row second-card">
            <div className="h-100 col-lg-3">
              <TopFlop
                heading={t("MyRestaurantsSales")}
                isTop={isTop}
                setIsTop={setIsTop}
                topFlopLoading={topFlopLoading}
                top={myRestaurantTopFlop?.top}
                flop={myRestaurantTopFlop?.flop}
              />
            </div>
            <div className="h-100 col-lg-9">
              <div className="card cardlist mb-0 h-100">
                <div className="card-body inherit-height position-relative">
                  {isLoaded && (
                    <>
                      <GoogleMap
                        mapContainerStyle={{ width: "100%", height: "100%" }}
                        zoom={zoom}
                        center={getCenter()}
                        onLoad={onLoad}
                        onUnmount={onUnmount}
                      >
                        {selectedPlace && (
                          <Marker
                            position={{
                              lat: selectedPlace.latitude,
                              lng: selectedPlace.longitude,
                            }}
                          />
                        )}
                      </GoogleMap>
                      <div className="abc">
                        <div
                          className="position-absolute w-85"
                          style={{ bottom: "20px" }}
                        >
                          <div className="">
                            <MyResturantsCarousel
                              data={
                                formData?.myRestaurants.length
                                  ? formData?.myRestaurants
                                  : myResturants
                              }
                              onSelectPlace={onSelectPlace}
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MyResturants;
