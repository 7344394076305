import { useDropzone } from "react-dropzone";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useState } from "react"; // Import useState

function DragDrop({ onDropAccepted, modalName }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false); // Initialize loading state
  const { getRootProps, getInputProps, isDragActive, isDragAccept } = useDropzone({
    accept: ".xlsx, .csv",
    maxFiles: 1,
    onDropAccepted: (acceptedFiles) => {
      setIsLoading(true); // Set loading state to true when files are being uploaded
      onDropAccepted(acceptedFiles); // Call the provided callback to handle file upload

      // Simulate an upload process (you would replace this with your actual upload logic)
      setTimeout(() => {
        // Once the upload is complete, set the loading state back to false
        setIsLoading(false);
      }, 1000); // Simulating a 2-second upload process
    },
    excludeAcceptAllOption: true,
    multiple: false,
  });

  return (
    <Row className="mt-4 photo-container">
      <Col lg={12}>
        <span className="add-restaurants-input-title ps-0">
          {t(`importYour${modalName}`)}
        </span>
        <div
          style={{display: "flex", alignItems: "center", justifyContent: "center"}}
          {...getRootProps({
            className: `dropzone mb-1 ${isDragActive ? "drag-active" : ""} ${
              isDragAccept ? "drag-accept" : ""
            }`,
          })}
        >
          <input {...getInputProps()} />
          {isLoading ? (
            <h2 style={{margin: "0px", padding: "0px"}}>{t("Uploading....")}</h2>
          ) : (
            <p>{t("Drag&Drop")}</p>
          )}
        </div>
      </Col>
    </Row>
  );
}

export default DragDrop;
