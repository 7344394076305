import React, { useState, useEffect } from "react";
import convertUnits from 'convert-units';

import {
  Modal,
  Button,
  Row,
  Col,
  Container,
  Card,
  Badge,
  Form,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { useLoading } from "contexts/LoadingContextManagement";
import { useUserData } from "contexts/AuthContextManagement";
import { cloneDeep, roundToTwo } from "common/utils.ts";
import request from "services/request";

import UploadedIcon from "assets/images/uploaded_meal.png";

function TextInput({
  type = "text",
  dataField,
  required = true,
  caption,
  ...props
}) {
  return (
    <>
      <span className="add-restaurants-input-title ps-0">{caption}</span>
      <input
        type={type}
        name={dataField}
        className="form-control custom-input"
        required={required}
        {...props}
      />
    </>
  );
}


function Text({caption, style}) {
  let customStyle = {...style}
  return (
    <>
      <span style={customStyle} className="add-restaurants-input-title ps-0 form-control">{caption}</span>
    </>
  );
}

function CustomModal({
  onHide,
  onRowAdded,
  show,
  data: { selectedIngredient },
  isUploadedModal = true,
}) {
  const { t } = useTranslation();
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [isUploaded, setIsUploaded] = useState(false);
  const { setLoading, setError } = useLoading();
  const [files, setFiles] = useState([]);
  const [fd, setFd] = useState({ allergens: [], details: [] });

  const {
    selectedRestaurantId,
    isRestaurantLoaded,
    hasRetaurants,
    setSampleRestaurantModal,
  } = useUserData();

  useEffect(() => {
    setFd({ allergens: [], details: [] });
    setFiles([]);
    setIsSubmitDisabled(true);
    setIsUploaded(false);
  }, [show]);

  useEffect(() => {
    if (
      fd?.name &&
      fd?.cost_excl_tax &&
      fd?.category &&
      fd?.unit
    ) {
      setIsSubmitDisabled(false);
      return;
    }
    setIsSubmitDisabled(true);
  }, [fd]);

  useEffect(() => {
    if (selectedIngredient) {
      setFd({
        ...selectedIngredient,
        cost_excl_tax: (selectedIngredient.cost_excl_tax / selectedIngredient.details?.find(p => p.is_default === true)?.provider_ingredient?.recipe_unit_quantity)?.toFixed(4),
        allergens: selectedIngredient?.allergens?.map((a) => a.id),
      });
    }
  }, [selectedIngredient]);

  const onAllergenClick =
    ({ id }) =>
    () => {
      let newAllergens = cloneDeep(fd.allergens);
      if (newAllergens.includes(id)) {
        newAllergens = newAllergens.filter((allergenId) => allergenId !== id);
      } else {
        newAllergens = [...newAllergens, id];
      }
      setFd({
        ...fd,
        allergens: newAllergens,
        details: []
      });
    };

  const onSelectionChange =
    (type) =>
    ({ id }) => {
      if(type === 'unit'){
        const defaultProvider = fd?.details.find(p => p.is_default)?.provider_ingredient
        if(id === defaultProvider?.recipe_unit){
          setFd({ ...fd, cost_excl_tax: (defaultProvider.price_excl_tax / defaultProvider.recipe_unit_quantity)?.toFixed(4), converted_unit: null, [type]: id });
          return
        }
        if (convertUnits().possibilities().includes(id) && 
            convertUnits().from(id)
            .possibilities().includes(defaultProvider?.recipe_unit)) {
              const convertedValue = (convertUnits(1).from(id).to(defaultProvider?.recipe_unit));
              const convertedCost = (convertedValue * fd?.cost_excl_tax)?.toFixed(4);
              setFd({ ...fd, cost_excl_tax: convertedCost,converted_unit: null, [type]: id });
              return
            }
        setFd({ ...fd, converted_unit: id, [type]: id });
      }
      if(type === "category"){
        setFd({ ...fd, [type]: id });
      }
    };

  const onTextChange = ({ target: { name, value, type } }) => {
    if (["cost_excl_tax"].includes(name)) {
      if (name === "cost_excl_tax") {
        return setFd({
          ...fd,
          [name]: value,
        });
      }else if (name === "format") {
        return setFd({
          ...fd,
          [name]: value,
          unit_price: roundToTwo(fd?.total_price / value),
        });
      }
    }
    setFd({ ...fd, [name]: value });
  };

  const onSubmit = async (ev) => {
    ev.preventDefault();
    try {
      if (isRestaurantLoaded && !hasRetaurants) {
        setSampleRestaurantModal(true);
        onHide();
        return;
      }
      setIsSubmitDisabled(true);
      setLoading(true);
      const payload = {
        restaurant_id: selectedRestaurantId,
        ...fd,
        cost_excl_tax: fd.cost_excl_tax * fd.details?.find(p => p.is_default)?.provider_ingredient.recipe_unit_quantity,
        unit: fd?.converted_unit ?? fd?.unit,
        details: fd?.details.map(item => ({provider_ingredient_id: item.provider_ingredient.id, is_default: item.is_default }))
      };

      // if(fd.details?.some((item => item.provider_ingredient?.recipe_unit !== fd.unit))){
      //   setError("Units for Provider and Fullsoon ingredients should be same")
      //   return
      // }

      if(fd.details.length === 0) {
        setError(t("Please Select Provider ingredient to create ingredient"))
        return
      }

      if(!fd.details?.some((item => item.is_default))){
        setError("Please Select Default value")
        return
      }
      delete payload.converted_unit;

      if (!selectedIngredient) {
        const result = await request.post("/ingredients", payload);
        throw Error(result);
      }
      delete payload.id;
      delete payload.provider_name;
      delete payload.createdAt;
      delete payload.updatedAt;
      delete payload.label;
      delete payload.value;
      delete payload.deletedAt;
      if (payload.image_path !== "" && payload.image_path !== null) {
        delete payload.image_path;
      }
      const resultQuery = await request.patch(
        `/ingredients/${selectedIngredient.id}`,
        payload
      );
      throw Error(resultQuery);
    } catch (error) {
      console.log(error);
      setIsSubmitDisabled(false);
    }
  };

  const handleSwitchChange = (id) => {
    const selectedProviderIngredient = fd.details.find(item => item.provider_ingredient?.id === id)?.provider_ingredient
    fd.details.forEach((item,i) => {
        setFd((prevFd) => {
          return {
          ...prevFd,
          details: prevFd.details.map(d => ({...d, is_default: d.provider_ingredient?.id === id ? true : false})),
          cost_excl_tax: (selectedProviderIngredient?.price_excl_tax / selectedProviderIngredient?.recipe_unit_quantity).toFixed(4),
          unit: selectedProviderIngredient?.recipe_unit,
          converted_unit: null
          }
        })
    })
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      centered
      className="add-ingredient"
      backdropClassName="add-ingredient-backdrop"
    >
      <Modal.Header className="add-restaurants-modal-header" closeButton>
        {!isUploaded && (
          <Modal.Title className="add-restaurants-modal-title">
            {selectedIngredient ? t("Edit") : t("Add")} {t("ingredient(s)")}
          </Modal.Title>
        )}
      </Modal.Header>

      <Modal.Body>
        <Container className="p-0">
          {isUploadedModal && isUploaded && (
            <div className="d-flex justify-content-center flex-column  text-center upload-container">
              <div>
                <img src={UploadedIcon} alt="..." />
              </div>
              <div className="heading mt-5">
                <label>{t("Ingredient uploaded")} !</label>
              </div>
              <div className="subheading mt-2 mb-5">
                <label>
                  {t("Your ingredient has been successfully uploaded")}.
                </label>
              </div>
              <div>
                <Button className="add-restaurant-confirm-btn" onClick={onHide}>
                  {t("OK")}
                </Button>
              </div>
            </div>
          )}
          {!isUploaded && (
            <Form noValidate className="ps-0 pe-0" onSubmit={onSubmit}>
              <Row className="mt-4">
                  <Col lg={12}>
                    <span className="input-title ps-0">{t("ProviderIngredients")}</span>
                    <Card className="mb-0">
                      <div className="p-4 allergens-container">

                        <div className="row custom-table h-100">
                          <div className="col-lg-12 h-100 p-0">
                            <div className="tablescroll">
                              <table className="table">
                                <thead>
                                  <tr>
                                    <td style={{ width: "55%" }}>
                                    {t("Name")}
                                    </td>
                                    <td
                                      className="text-center"
                                      style={{ width: "35%" }}
                                    >
                                      {t("Inventory")}
                                    </td>
                                    <td
                                      className="text-center"
                                      style={{ width: "25%" }}
                                    >
                                      {t("Unit Price")}
                                    </td>
                                    <td
                                      className="text-center"
                                      style={{ width: "30%", textAlign: "end" }}
                                    >
                                      {t("Action")}
                                    </td>
                                  </tr>
                                </thead>
                                <tbody>
                                    <tr
                                      className="flex justify-between"
                                    >
                                      <td style={{ height: "50px" }}>
                                        <Text style={{width: "100%", display: "inline-block", whiteSpace: "initial"}}  caption={selectedIngredient?.name} />
                                      </td>
                                      <td className="text-center" style={{ width: "200px" }}>
                                        <Text style={{overflow: "hidden"}} caption={selectedIngredient?.stock?.unit_stock} />
                                      </td>
                                      <td className="text-center" style={{ width: "100px" }}>
                                        <Text caption={"10"} />
                                      </td>
                                      <td className="text-center">
                                        <Button>Accept</Button>
                                        <Button>Refuse</Button>
                                      </td>
                                      <td style={{ width: "100px" }} className="text-center">
                                      </td>
                                    </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Card>
                  </Col>
                </Row>

              <Modal.Footer className="add-restaurants-modal-footer">
                <Button
                  type="submit"
                  disabled={isSubmitDisabled}
                  className="add-restaurant-confirm-btn"
                >
                  {t("Confirm")}
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Container>
      </Modal.Body>
    </Modal>
  );
}

export default CustomModal;
