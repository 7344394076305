import React, { useState, useEffect, Fragment } from "react";
import moment from "moment";
import { Dropdown, Form, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import useRestaurantTimezone from "customHooks/useRestaurantTimezone";
import CustomTable from "components/customTable/index.tsx";
import LineChart from "components/lineChart";
import useFetch from "customHooks/useFetch";
import { useUserData } from "contexts/AuthContextManagement";
import { useLoading } from "contexts/LoadingContextManagement";
import { MEAL_CATEGORIES } from "common/constants";

import TopAndFlopView from "views/commonViews/topAndFlop";
import FilterIcon from "assets/images/icon/FILTER_ICON1.svg";
import CloseIcon from "assets/images/close.png";
import { timezoneFormat } from "common/utils";
import { DAY_TIMES_TIME } from "./utils";
import { parseData } from "views/occupancy/utils";
import {
  generateServiceTableRawData,
  generateTotalEarnings,
  getTimeRange,
} from "../occupancy/data";
import dummyOccupancyForecast from "../../data/occupancy.json";
import topFlopForecast from "../../data/top_flop_forecast.json";
import dummySalesForecast from "../../data/sales.json";

import ClockIcon from "assets/images/icon/CLOCK.svg";
import PieChart from "components/pieChart";
import request from "services/request";

const start_date = moment().format('YYYY-MM-DD');
const end_date = moment().format('YYYY-MM-DD');

const DAY_TIMES = {
  ALL_DAY: "All Day",
  BREAKFAST: "Breakfast",
  LUNCH: "Lunch",
  AFTERNOON: "Afternoon",
  DINNER: "Dinner",
};

/**
 * Main content of the forecast screen
 * @param {Object} props
 * @returns JSX
 */
function LeftSide() {
  const { setError } = useLoading();

  const {
    selectedRestaurant,
    isRestaurantLoaded,
    hasRetaurants,
    selectedRestaurantId,
    subscription
  } = useUserData();
  const { t } = useTranslation();
  const { convertTimezone } = useRestaurantTimezone();
  const [tableData, setTableData] = useState([]);
  const [tableColumns, setTableColumns] = useState([]);
  const [lineChartData, setLineChartData] = useState([]);
  const [isTop, setIsTop] = useState(true);
  const [isTopFlopFilter, setIsTopFlopFilter] = useState(false);
  const [topFlopMealTypes, setTopFlopMealTypes] = useState([]);
  const [formData, setformData] = useState({
    occupancy: "ALL_DAY",
    availability: "ALL_DAY",
  });
  const [isAppliedClicked, setIsAppliedClick] = useState(0);
  const [totalEarnings, setTotalEarnings] = useState([]);
  const [financeLoading, setFinanceLoading] = useState(false);
  const [occupancyLoading, setOccupancyLoading] = useState(true)
  const [occupancyDataResponse, setOccupancyDataResponse] = useState([])

  let topFlopCount = 0;

  useEffect(async() => {
    if(selectedRestaurantId){
    setOccupancyLoading(true)
    const result = await request.get(
      `occupancy/guests`,
      {
        restaurant_id: selectedRestaurantId,
        ...timezoneFormat(moment().subtract(7, 'days').format('YYYY-MM-DD'), moment().subtract(6, 'days').format('YYYY-MM-DD'), selectedRestaurant?.timezone),
      }
    );
    setOccupancyDataResponse(result)
    setOccupancyLoading(false)
    }
  }, [selectedRestaurantId])

  

  const { loading: topFlopLoading, data: topFlopData } = useFetch(
    "top-and-flop/forecast",
    {
      restaurant_id: selectedRestaurantId,
      ...timezoneFormat(start_date, end_date, selectedRestaurant?.timezone),
      meal_types: topFlopMealTypes ?? [],
      // date:
      //   topFlopCount === 0
      //     ? convertTimezone(new Date())
      //     : convertTimezone(moment().subtract(1, "days")),
      // ...(topFlopMealTypes.length && {
      //   meal_types:
      //     topFlopMealTypes.length === 1
      //       ? [...topFlopMealTypes, ...topFlopMealTypes]
      //       : topFlopMealTypes,
      // }),
    },
    true,
    [isAppliedClicked]
  );

  let topFlop = topFlopData;
  let occupancyData = occupancyDataResponse;
  if (isRestaurantLoaded && !hasRetaurants) {
    if (isAppliedClicked) {
      const generateRandomSales = () => Math.floor(Math.random() * 15) + 1;
      // Apply random sales to the top meals
      topFlopForecast.top.forEach(meal => {
        meal.sales = generateRandomSales();
      });
      topFlop = topFlopForecast;
    }
    occupancyData = { days: dummyOccupancyForecast };
    topFlop = topFlopForecast;
  }

  useEffect(() => {
    if (!occupancyLoading && occupancyDataResponse) {
      onWeatherTimeChange(formData.occupancy.toLowerCase());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [occupancyLoading, occupancyDataResponse]);

  /**
   * Called whenever the user selects the time from the dropdown
   * @param {*} val
   */
  const handleSelect = (type) => (val) => {
    setformData({ ...formData, [type]: val });
    if (type === "availability") {
      // generateTableData(val.toLowerCase());
    }
    if (type === "occupancy") {
      onWeatherTimeChange(val.toLowerCase());
    }
  };

  /**
   * this hook will be called whenever the restaurant changes
   */
  useEffect(() => {
    if (isRestaurantLoaded && !hasRetaurants) {
      onWeatherTimeChange();
    }
    // generateTableData("");
    if (selectedRestaurantId) {
      getFinanceData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRestaurantId, isRestaurantLoaded]);

  const getFinanceData = async () => {
    try {
      setFinanceLoading(true);
      const result = await request.get(
        `tables/finance`,
        {
          restaurant_id: selectedRestaurantId,
          ...timezoneFormat(
            moment().format("YYYY-MM-DD"),
            moment().format("YYYY-MM-DD"),
            selectedRestaurant?.timezone
          ),
          meals: formData.meals ?? [],
          meal_types: formData.mealTypes ?? [],
        },
        true,
        true,
        true
      );
      generateChartData(result?.meals || []);
    } catch (error) {
      if (error?.status !== 499) {
        setError("Unable to load Meal details.");
        setFinanceLoading(false);
      }
    }
  };

  const generateChartData = (financeData = null) => {
    if (financeData) {
      setTotalEarnings(() =>
        financeData?.map((m) => ({ name: m.name, y: m.qty }))
      );
      setFinanceLoading(false);
      return;
    }

    // setTotalEarnings(() => generateTotalEarnings(formData));
  };

  /**
   * Generate Tables availability data
   * @param {*} val
   */
  const generateTableData = (val) => {
    const fd = {
      breakfast: val === "breakfast",
      lunch: val === "lunch",
      dinner: val === "dinner",
      noOfPeople: [
        "2 persons",
        "5 persons",
        "7 persons",
        "11 persons",
        "14 persons",
        "3 persons",
      ],
    };
    const { timesData, data } = generateServiceTableRawData(fd);
    setTableColumns([
      {
        dataField: "noOfPeople",
        caption: t("TableTypes"),
        className: "fw-bold",
        style: { width: "150px" },
        headerStyle: { width: "150px" },
      },
      ...timesData.map((t) => ({
        dataField: t,
        caption: t,
        className: "text-center",
        headerClassName: "text-center",
        isLower: (params) => (params < 10 ? "text-danger" : ""),
        isHigher: (params) => (params > 50 ? "text-success" : ""),
      })),
    ]);

    setTableData(JSON.parse(JSON.stringify(data)));
  };

  /**
   * Called whenever the user selects the time from the occupancy forecast dropdown
   * @param {*} val
   */
  const onWeatherTimeChange = (val = "all_day") => {
    let newLineChartData = [];
    const parsedData = parseData(
      occupancyData.days,
      Intl.DateTimeFormat().resolvedOptions().timeZone
    );

    let timeRange = getTimeRange("00:00", "23:59", 30);
    if (val !== "all_day") {
      const [startTime, endTime] = DAY_TIMES_TIME[val];
      timeRange = getTimeRange(startTime, endTime, 30);
    }
    parsedData.forEach((d) => {
      const getTimeUnix = (t) => moment(`${d.date}T${t}:00.000Z`).unix();

      timeRange?.forEach((t) => {
        const result = d.occupancy?.find((o) => o.interval === `${t}:00`);
        if (result)
          newLineChartData.push([getTimeUnix(t), result?.total_guests ?? 0]);
      });
    });

    if (isRestaurantLoaded && !hasRetaurants) {
    generateChartData(dummySalesForecast.meals)
    setLineChartData(() => newLineChartData);
    setOccupancyLoading(false)
    }else{
    setLineChartData(() => newLineChartData);
    }
  };

  const customCheckbox = ({ name, label, checked }) => (
    <Form.Group className="mb-3">
      <label className="checkbox">
        <input
          type="checkbox"
          onChange={onCheckboxChange}
          name={name}
          checked={checked}
        />
        <span className="me-2" />
        {label}
      </label>
    </Form.Group>
  );

  const onCheckboxChange = ({ target: { checked, name } }) => {
    let newTopFlopMealTypes = [...topFlopMealTypes];
    if (checked) {
      newTopFlopMealTypes = [...newTopFlopMealTypes, name];
    } else {
      newTopFlopMealTypes = newTopFlopMealTypes.filter((m) => m !== name);
    }
    setTopFlopMealTypes(() => newTopFlopMealTypes);
  };

  return (
    <div className="leftcontent forcast_left">
      <div className="for-wraper-50 mb-4">
        <div className="card h-100">
          <div className="card-header d-flex align-items-center justify-content-between">
            <h2>{t("SalesForecast")}</h2>
            <Dropdown onSelect={handleSelect("occupancy")}>
              <Dropdown.Toggle
                variant="link"
                className="btn btn-white dropdown-toggle btn-icon"
              >
                <img src={ClockIcon} alt="" />{" "}
                {t(DAY_TIMES[formData?.occupancy])}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {Object.keys(DAY_TIMES).map((d, i) => (
                  <Dropdown.Item key={i} eventKey={d}>
                    {t(DAY_TIMES[d])}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="card-body h-100">
            {occupancyLoading && (
              <div className="w-100 d-flex justify-content-center card-spinner-container">
                <Spinner animation="border" variant="primary" />
              </div>
            )}
            {!occupancyLoading && <LineChart data={lineChartData} />}
          </div>
        </div>
      </div>
      <div className="for-wraper-50">
        <div className="row d-flex  h-100">
          {/* <div className="col-lg-9  h-100">
            <div className="card h-100 m-0">
              <div className="card-header d-flex align-items-center justify-content-between">
                <h2>{t("TablesAvailability")}</h2>
                <Dropdown onSelect={handleSelect("availability")}>
                  <Dropdown.Toggle
                    variant="link"
                    className="btn btn-white dropdown-toggle btn-icon"
                  >
                    <img src={ClockIcon} alt="" />
                    {t(DAY_TIMES[formData?.availability])}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {Object.keys(DAY_TIMES).map((d, i) => (
                      <Dropdown.Item key={i} eventKey={d}>
                        {t(DAY_TIMES[d])}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="card-body  h-75">
                <CustomTable columns={tableColumns} data={tableData} />
              </div>
            </div>
          </div> */}
          <div className="col-lg-9 h-100">
            <div className="card h-100">
              <div className="card-header">
                <h2>{t("TotalSelling")}</h2>
              </div>
              <div className="card-body h-100">
                {financeLoading && (
                  <div className="w-100 d-flex justify-content-center card-spinner-container">
                    <Spinner animation="border" variant="primary" />
                  </div>
                )}
                {!financeLoading && <PieChart data={totalEarnings} />}
              </div>
            </div>
          </div>

          <div className="col-md-3">
            <div className="card cardlist sales-top-flop">
              {isTopFlopFilter && (
                <div className="meal-types">
                  <div className="row card-body">
                    <div className="d-flex justify-content-between">
                      <h2 className="filters-text">{t("Filters")}</h2>

                      <div
                        className="hide-filter"
                        onClick={() => setIsTopFlopFilter(false)}
                      >
                        {t("HideFilters")}
                      </div>
                    </div>

                    <div className="mt-4 fltrs-container">
                      <div className="fltr-heading">
                        <label>{t("MealTypes")}</label>
                        <button onClick={() => setTopFlopMealTypes([])}>
                          {t("Clear")}
                        </button>
                      </div>

                      {MEAL_CATEGORIES.slice(1,-4).map((check, i) => {
                        const label = t(check.label);
                        return (
                          <Fragment key={i}>
                            {customCheckbox({
                              name: check.id,
                              label,
                              checked: topFlopMealTypes.includes(check.id),
                            })}
                          </Fragment>
                        );
                      })}
                    </div>

                    <div className="action-btns justify-content-center">
                      <button
                        onClick={() => setTopFlopMealTypes([])}
                        className="close-btn me-3"
                      >
                        <img src={CloseIcon} alt="" />
                        {t("Clear")}
                      </button>
                      <button
                        onClick={() => {
                          setIsAppliedClick(isAppliedClicked + 1);
                          setIsTopFlopFilter(false);
                        }}
                        className="apply-btn"
                      >
                        {t("Apply")}
                      </button>
                    </div>
                  </div>
                </div>
              )}

              <div className="card-body">
                <h2 className="mb-3 d-flex justify-content-between align-items-center">
                  {t("Sales")}{" "}
                  <button
                    onClick={() => setIsTopFlopFilter(true)}
                    className="btn btn-primary btn-icon"
                  >
                    <img src={FilterIcon} alt="" className="m-0" />
                  </button>
                </h2>

                <div className="top-flop">
                  <ul className=" navbtns ">
                    <li className={`${isTop ? "active" : ""}`}>
                      <button
                        className={`nav-link ${isTop ? "active" : ""}`}
                        onClick={() => setIsTop(true)}
                      >
                        {t("Top")}
                      </button>
                    </li>
                    <li className={`${!isTop ? "active" : ""}`}>
                      <button
                        className={`nav-link ${!isTop ? "active" : ""}`}
                        onClick={() => setIsTop(false)}
                      >
                        {t("Flop")}
                      </button>
                    </li>
                  </ul>

                  {!topFlopLoading && (
                    <div className="top-flop top-flop-container">
                      {!topFlop?.top?.length && (
                        <span className="text-muted text-muted-msg">
                          {/* Top & Flop are not available for today yet ! Do you
                          want to see the last day available ? */}
                          Data is not available yet !
                        </span>
                      )}
                      {isTop && <TopAndFlopView data={topFlop?.top} />}
                      {!isTop && <TopAndFlopView data={topFlop?.flop} />}
                    </div>
                  )}
                  {topFlopLoading && (
                    <div className="w-100 d-flex justify-content-center card-spinner-container">
                      <Spinner animation="border" variant="primary" />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(LeftSide);
